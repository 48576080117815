import { useStoreProductArticles } from '@gozoki/api';
import { UniqueArticle } from '@gozoki/api-types';
import { TestCartsForm } from '@gozoki/api/src/forms/useTestCartsForm';
import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

const getRelativeDate = (date: Date | string) => {
    const dt = DateTime.fromJSDate(new Date(date));
    return dt.toRelative();
};

const getArticleImage = (article: UniqueArticle) => {
    const u = new URL(import.meta.env.VITE_BACKEND_API_ENDPOINT);
    const backendUrl = u.origin;

    return `${backendUrl}/products/${article.product.id}/preview-image`;
};

export const ShopArticlesSelection = ({
    shopId,
    productId,
    control,
    setValue,
}: {
    shopId: number;
    productId: number;
    control: Control<TestCartsForm>;
    setValue: UseFormSetValue<TestCartsForm>;
}) => {
    const articlesQuery = useStoreProductArticles(shopId, productId);
    const articles = articlesQuery.data ?? [];
    const articleRefs = useWatch({ control, name: 'articleRefs' });

    const removeRef = (ref: string) => {
        setValue(
            'articleRefs',
            articleRefs.filter((articleRef) => articleRef.ref !== ref)
        );
    };

    const addRef = (article: UniqueArticle) => {
        if (articleRefs.some((articleRef) => articleRef.ref === article.checkpointArticleRef)) {
            return;
        }

        setValue('articleRefs', [
            ...articleRefs,
            {
                ref: article.checkpointArticleRef,
                dlc: article.dlc,
                label: article.product.label,
                imageUrl: getArticleImage(article),
            },
        ]);
    };

    return (
        <Box sx={{ maxHeight: '70vh', overflow: 'auto', my: 2 }}>
            <Typography sx={{ mb: 2 }}>Selectionner des articles</Typography>

            {articles.map((article) => (
                <Button key={article.id} onClick={() => addRef(article)}>{`DLC ${getRelativeDate(
                    article.dlc
                )}`}</Button>
            ))}

            <div>
                <Typography sx={{ my: 2 }}>Articles séléctionnés</Typography>

                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {articleRefs.map(({ dlc, label, ref, imageUrl }) => (
                        <Tooltip
                            key={ref}
                            title={`${label} (${ref}) - DLC ${getRelativeDate(dlc)}`}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: 1,
                                    m: 1,
                                    p: 1,
                                    outline: '1px solid black',
                                    borderRadius: 2,
                                }}
                            >
                                <Chip label={`${label}`} onDelete={() => removeRef(ref)} />
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt="product"
                                        style={{ maxWidth: 100, maxHeight: 100 }}
                                    />
                                )}
                                <p>{ref}</p>
                            </Box>
                        </Tooltip>
                    ))}
                </Box>
            </div>
        </Box>
    );
};
