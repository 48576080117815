/* eslint-disable react/no-children-prop */
import { SvgIconProps, Typography } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';

export interface BasicNavLinkProps {
    to: string;
    Icon: React.ComponentType<SvgIconProps>; // Change this line
    text: string;
}

const BasicNavLink = (props: BasicNavLinkProps) => {
    const { to, Icon, text } = props;
    return (
        <NavLink
            to={to}
            style={{ textDecoration: 'none' }}
            children={({ isActive }) => (
                <div
                    style={{
                        // Size
                        height: '48px',

                        // Margins & padding
                        margin: '8px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        paddingLeft: '16px',
                        paddingRight: '16px',

                        // Content align
                        display: 'flex',
                        alignItems: 'center',

                        // Active style
                        borderRadius: '2px',
                        borderStyle: 'solid',
                        borderWidth: 0,
                        borderLeftColor: ThemeColors.GREEN,

                        backgroundColor: isActive ? ThemeColors.GREY : ThemeColors.WHITE,
                        borderLeftWidth: isActive ? '4px' : '0px',
                    }}
                >
                    <Icon color={isActive ? 'primary' : 'info'} height={30} width={30} />
                    <Typography
                        style={isActive ? fonts.navButtonActive : fonts.navButtonInactive}
                        marginLeft="32px"
                    >
                        {text}
                    </Typography>
                </div>
            )}
        />
    );
};

export default BasicNavLink;
