import {
    fetchMinimumStock,
    fetchMultipleArticles,
    fetchMultipleProducts,
    fetchProduct,
    fetchProductStock,
    fetchProducts,
} from '../queries/products.queries';

import { useQuery } from '@tanstack/react-query';

export const useProducts = () => {
    return useQuery(['products'], fetchProducts);
};

export const useProduct = (id: number) => {
    return useQuery(['product', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchProduct(id);
    });
};

export const useMultipleProducts = (ids: number[]) => {
    return useQuery(['multipleProducts', ids], () => {
        if (ids.length === 0) return [];
        return fetchMultipleProducts(ids);
    });
};

export const useMinimumStock = (id: number) => {
    return useQuery(['minimumStock', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchMinimumStock(id);
    });
};

export const useMultipleArticles = (ids: string[] | null | undefined) => {
    return useQuery(['multipleArticles', ids], () => {
        if (!ids) return [];
        if (ids.length === 0) return [];
        return fetchMultipleArticles(ids);
    });
};

export const useProductStock = (reference: string) => {
    return useQuery(['productStock', reference], () => {
        if (!reference) return null;
        return fetchProductStock(reference);
    });
};
