import { gozokiApiWithAuth } from '../gozokiApi';

export const downloadPeriodAccountingKpiCsv = async ({
    dateStart,
    dateEnd,
}: {
    dateStart: string;
    dateEnd: string;
}) => {
    const response = await gozokiApiWithAuth.get(
        `/stores/get-period-accounting-kpis-csv/${dateStart}/${dateEnd}`, // 'AAAA-MM-DD'
        {
            responseType: 'blob',
        }
    );

    return response;
};
