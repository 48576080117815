import { useMinimumStock, useProduct, useProductStock } from '@gozoki/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Chip, Grid, IconButton, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import Text from '../../components/communication/Text';
import { ChipOptions } from '../../components/containers/ChipOptions';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { MenuOption } from '../../components/containers/MenuOptions';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { useRights } from '../../utils/hooks';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { TextOverrides } from '../../utils/theme/overrides.theme';

const DLCOptions: MenuOption[] = [
    { label: 'jour même', value: '0' },
    { label: 'la veille', value: '1' },
    { label: 'J-2', value: '2' },
    { label: 'J-3', value: '3' },
    { label: 'J-4', value: '4' },
    { label: 'J-5', value: '5' },
];

const DLCOValueToLabel = (value: string) => {
    return DLCOptions.find((opt) => opt.value === value)?.label ?? '';
};

const stockColDefs: GridColDef[] = [
    {
        field: 'store',
        headerName: 'Boutique',
        width: 400,
    },
    {
        field: 'count',
        headerName: "Nombre d'articles",
        width: 400,
        renderCell: (field) => (
            <Chip
                label={field.value > 0 ? `${field.value} en stock` : 'Pas de stock'}
                color={field.value > 0 ? 'success' : 'error'}
            />
        ),
    },
];

const ProdutDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { data, isLoading } = useProduct(parseInt(id ?? '', 10));
    const { data: storeProductMinimumStock } = useMinimumStock(parseInt(id ?? '', 10));

    const isUserAdmin = useRights(['ADMIN']);

    const { data: stockData, isLoading: stockLoading } = useProductStock(data?.reference ?? '');

    if (!data && !isLoading) {
        navigate(ROUTES.PRODUCTS);
        return null;
    }

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    onClick={() => {
                        navigate(ROUTES.PRODUCTS);
                    }}
                >
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    Détails du produit
                </Typography>
                {isUserAdmin && (
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                            marginRight: '16px',
                        }}
                        onClick={() => {
                            navigate(ROUTE_BUILDER.updateProductFirstStep(parseInt(id ?? '', 10)));
                        }}
                    >
                        Modifier
                    </Button>
                )}
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isLoading}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Référence (EAN 13)
                </Typography>
                <TextField
                    variant="outlined"
                    placeholder="Référence"
                    fullWidth
                    disabled
                    value={data?.reference ?? ''}
                />
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Libellé
                </Typography>
                <TextField
                    variant="outlined"
                    placeholder="Nom du produit"
                    fullWidth
                    disabled
                    value={data?.label ?? ''}
                />
                <Grid item flex={1} mt="24px">
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Stock
                    </Typography>
                    <SimpleDataGrid
                        columns={stockColDefs}
                        rows={stockData ?? []}
                        loading={stockLoading}
                        getRowId={(row) => row.storeReference}
                        checkboxSelection={false}
                    />
                </Grid>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Catégorie(s)
                            </Typography>
                            <div
                                style={{
                                    maxHeight: '150px',
                                    overflowY: 'auto',
                                    direction: 'rtl',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: 'dark',
                                }}
                            >
                                <div style={{ direction: 'ltr' }}>
                                    <ChipOptions
                                        tags={data?.categories.map((cat) => cat.name) ?? []}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Catégorie(s) interne(s)
                            </Typography>
                            <div
                                style={{
                                    maxHeight: '150px',
                                    overflowY: 'auto',
                                    direction: 'rtl',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: 'dark',
                                }}
                            >
                                <div style={{ direction: 'ltr' }}>
                                    <ChipOptions
                                        tags={data?.internalCategories.map((cat) => cat.name) ?? []}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Marque
                            </Typography>
                            <TextField
                                disabled
                                value={data?.brand.name ?? ''}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Eligible aux menus ?
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                value={data?.menuType ?? ''}
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Supplément menu (€) ?
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                value={data?.menuExtraPrice ?? ''}
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Portions
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.portions ?? ''}
                                placeholder="e.g. 2-3 pers."
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Prix
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Poids (g)
                            </Text>
                            <TextField
                                variant="outlined"
                                value={data?.weight ?? ''}
                                placeholder="0.00 g"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Prix d'achat au kilo
                            </Text>
                            <TextField
                                variant="outlined"
                                name="weightPrice"
                                value={data?.weightPrice ?? ''}
                                placeholder="0.00 €/kg"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Prix d'achat à unité
                            </Text>
                            <TextField
                                variant="outlined"
                                value={data?.price ?? ''}
                                placeholder="0.00"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Prix de vente à l'unité
                            </Text>
                            <TextField
                                variant="outlined"
                                value={data?.sellPrice ?? ''}
                                placeholder="0.00"
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Colisage
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Colisage
                            </Text>
                            <TextField
                                variant="outlined"
                                value={data?.colisage ?? ''}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Poids du colis (grammes)
                            </Text>
                            <TextField
                                variant="outlined"
                                value={data?.colisWeight ?? ''}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Images
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        overflowX: 'auto',
                        gap: '16px',
                        direction: 'ltr',
                        scrollbarWidth: 'thin',
                    }}
                >
                    {(data?.images ?? []).map((img) => (
                        <img
                            key={`product__img__${img.id}`}
                            src={img.image.url}
                            alt="product-img"
                            style={{ maxHeight: 150, maxWidth: 150 }}
                        />
                    ))}
                </div>
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Image de vignette ()
                </Typography>
                {data?.previewImage && (
                    <img
                        src={data.previewImage?.url ?? ''}
                        alt="preview"
                        style={{ maxWidth: 200, maxHeight: 200 }}
                    />
                )}

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Description
                </Typography>
                <TextField
                    variant="outlined"
                    value={data?.description ?? ''}
                    placeholder="Description du produit"
                    fullWidth
                    multiline
                    InputProps={{
                        style: {
                            minHeight: '102px',
                            textAlign: 'end',
                            alignItems: 'flex-start',
                        },
                    }}
                    disabled
                />
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Nutriscore
                </Typography>

                <TextField value={data?.nutriscore ?? ''} variant="outlined" fullWidth disabled />

                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Mode de préparation
                </Typography>
                <TextField
                    variant="outlined"
                    value={data?.preparationMode ?? ''}
                    placeholder="Votre mode de préparation"
                    fullWidth
                    disabled
                />

                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Ingrédients
                </Typography>
                <TextField
                    variant="outlined"
                    value={data?.ingredients ?? ''}
                    placeholder="Vos ingrédients"
                    fullWidth
                    disabled
                />

                <Typography mt="24px" mb="16px" style={fonts.inputHeader}>
                    Valeurs nutritionnelles
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Valeur énergétique
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.energyValue ?? ''}
                                placeholder="kcal"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Glucides
                            </Typography>
                            <TextField
                                variant="outlined"
                                name="carbohydrates"
                                value={data?.nutritionalValues?.carbohydrates ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Sucres
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.sugars ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Polyols
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.polyols ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Protéines
                            </Typography>
                            <TextField
                                variant="outlined"
                                placeholder="g"
                                value={data?.nutritionalValues?.proteins ?? ''}
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Fibres
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.fibers ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Matières grasses
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.fats ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Acides gras saturés
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.saturatedFats ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Sodium
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.sodium ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Sel
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={data?.nutritionalValues?.salt ?? ''}
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Allergènes
                </Typography>
                <TextField
                    variant="outlined"
                    value={data?.allergens ?? ''}
                    placeholder="Allergènes"
                    fullWidth
                    disabled
                />

                <Grid container spacing={4} mt="24px">
                    <Grid item flex={1}>
                        <Typography mb="8px" style={fonts.inputHeader}>
                            DUN 14
                        </Typography>
                        <TextField
                            variant="outlined"
                            value={data?.dun14 ?? ''}
                            placeholder="DUN 14"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mb="8px" style={fonts.inputHeader}>
                            Code copilot
                        </Typography>
                        <TextField
                            variant="outlined"
                            value={data?.copilotCode ?? ''}
                            placeholder="Code copilot"
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item flex={1} mt={2}>
                    <Typography mb="8px" style={fonts.inputHeader}>
                        COMPTABILITÉ (négoce interne ou négoce externe ou sushis)
                    </Typography>
                    <TextField
                        value={data?.negoceType ?? ''}
                        label="Type de négoce"
                        required
                        disabled
                    />
                </Grid>
                <Grid container spacing={4} mt="24px">
                    <Grid item flex={1}>
                        <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                            Afficher comme nouveauté ?
                        </Typography>
                        <TextField
                            value={data?.isNew ? 'Oui' : 'Non'}
                            disabled
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                            Activer ce produit ?
                        </Typography>
                        <TextField
                            value={data?.isActive ? 'Oui' : 'Non'}
                            disabled
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={4} sx={{ alignItems: 'flex-end' }}>
                    <Grid item flex={1}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Règle anti-gaspi
                        </Typography>
                        <Typography mb="8px" style={fonts.informationSubText}>
                            Le produit sera automatiquement basculé dans la catégorie Anti-gaspi
                            lorsque sa DLC arrivera à :
                        </Typography>

                        <TextField
                            value={data ? DLCOValueToLabel(data.antiGaspi.toString()) : ''}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Réduction anti-gaspi
                        </Typography>
                        <Typography mb="8px" style={fonts.informationSubText}>
                            Pourcentage de réduction appliqué sur les produits anti-gaspi (arrondi
                            au 50 cts inférieurs)
                        </Typography>

                        <TextField
                            variant="outlined"
                            value={data?.agPromo ?? ''}
                            placeholder="Réduction anti-gaspi"
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>
                {storeProductMinimumStock &&
                    storeProductMinimumStock.map((spms) => (
                        <React.Fragment key={`${spms.productReference}-${spms.storeReference}`}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                {spms.label}
                            </Typography>
                            <Grid container spacing={4} sx={{ alignItems: 'flex-end' }}>
                                <Grid item flex={1}>
                                    <Typography mb="8px" style={fonts.inputHeader}>
                                        Stock minimum
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        value={spms.minimumStock ?? ''}
                                        placeholder="Stock minimum"
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <Typography mb="8px" style={fonts.inputHeader}>
                                        Stock minimum Week-End
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        value={spms.weekEndMinimumStock ?? ''}
                                        placeholder="Stock minimum"
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
            </LoadingPaper>
        </Page>
    );
};

export default ProdutDetail;
