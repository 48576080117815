import { AppBar, Toolbar, useTheme } from '@mui/material';

import { AuthContext } from '../utils/context/auth.context';
import { ThemeColors } from '../utils/theme/colors.theme';
import UserTag from './UserTag';
import logoSoft from '../assets/logos/logoSoft.svg';
import { useContext } from 'react';

const MainAppBar = () => {
    const theme = useTheme();

    const { authenticated, isLoading } = useContext(AuthContext);

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: ThemeColors.WHITE,
                height: '72px',
                zIndex: theme.zIndex.drawer + 1,
                boxShadow: '0px 0px 8px 0px #0000001A',
            }}
        >
            <Toolbar sx={{ height: '72px', display: 'flex', justifyContent: 'space-between' }}>
                <img src={logoSoft} alt="login logo" height="60px" width="95px" />
                {!isLoading && authenticated && <UserTag />}
            </Toolbar>
        </AppBar>
    );
};

export default MainAppBar;
