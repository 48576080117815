import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import ErrorComponent from './components/communication/ErrorComponent.tsx';
import './index.css';

// Sentry config
Sentry.init({
    dsn: 'https://66aef9475a1149f48d9750a7ef6b234a@o4505583710699520.ingest.sentry.io/4505583821324288',
    tracesSampleRate: 0,
    enabled: process.env.NODE_ENV === 'production',
});

// React router dom v6 : https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter([
    {
        path: '/*', // All routes share this base component layout
        element: <App />,
        children: [],
        errorElement: <ErrorComponent />,
    },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
