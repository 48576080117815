import { PromoCampaignForm } from '../forms/usePromoCampaignForm';
import { gozokiApiWithAuth } from '../gozokiApi';

// Create a promotional campaign
export const createPromoCampaign = async (data: PromoCampaignForm) => {
    const response = await gozokiApiWithAuth.post('/promo-campaigns', data);
    return response;
};

// Destroy a promotional campaign
export const destroyPromoCampaign = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/promo-campaigns/${id}`);
    return response;
};

// Destroy multiple promotional campaigns
export const destroyPromoCampaigns = async (ids: number[]) => {
    const responses = await Promise.all(ids.map((id) => destroyPromoCampaign(id)));
    return responses;
};

// Update a promotional campaign
export const updatePromoCampaign = async ({ id, data }: UpdatePromoCampaignArgs) => {
    const response = await gozokiApiWithAuth.patch(`/promo-campaigns/${id}`, data);
    return response;
};

export const userActivatePromoCode = async (id: number) => {
    const response = await gozokiApiWithAuth.post(`/me/promo-codes/activate/${id}`);
    return response;
};

// Generate unique codes for a promotional campaign
export const generateUniqueCodesPromoCampaign = async ({
    id,
    quantityUniqueCode,
}: GenerateUniqueCodeArgs) => {
    const response = await gozokiApiWithAuth.post<{ message: string }>(
        `/promo-campaigns/generate/${id}?quantityUniqueCode=${quantityUniqueCode}`
    );
    return response.data;
};

// Generate unique codes for multiple promotional campaigns
export const generateUniqueCodesPromoCampaigns = async ({
    ids,
    quantityUniqueCode,
}: {
    ids: number[];
    quantityUniqueCode: number;
}) => {
    const responses = await Promise.all(
        ids.map((id) => generateUniqueCodesPromoCampaign({ id, quantityUniqueCode }))
    );
    return responses;
};

// Export unique codes as XLSX
export const exportUniqueCodesAsXlsx = async ({ id }: { id: number }) => {
    const response = await gozokiApiWithAuth.get(`/promo-campaigns/unique-codes-xlsx/${id}`, {
        responseType: 'blob',
    });
    return response.data;
};

interface UpdatePromoCampaignArgs {
    id: number;
    data: PromoCampaignForm;
}

interface GenerateUniqueCodeArgs {
    id: number;
    quantityUniqueCode: number;
}
