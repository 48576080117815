import { fetchCart, fetchMyCarts } from '../queries/carts.queries';
import { useQuery } from '@tanstack/react-query';

export const useMyCarts = () => {
    return useQuery(['myCarts'], fetchMyCarts);
};

export const useCart = (id: number) => {
    return useQuery(['cart', id], () => {
        if (Number.isNaN(id)) {
            return null;
        }
        return fetchCart(id);
    });
};
