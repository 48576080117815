import { Chip } from '@mui/material';

export interface ChipOptionsProps {
    tags: string[];
}

/** Helper renderer component : renders a series of chips with labels from a list of menu option values */
export const ChipOptions = ({ tags }: ChipOptionsProps) => {
    return (
        <>
            {tags.map((tag, index) => (
                <Chip
                    sx={{ marginRight: '2px', marginLeft: '2px' }}
                    key={`${tag}-${index}`}
                    label={tag}
                />
            ))}
        </>
    );
};
