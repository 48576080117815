import {
    Box,
    Button,
    ButtonProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

import { PromoCampaignBase } from '@gozoki/api-types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { useToast } from '../communication/Toast';

export interface GenerateUniqueUseCodeButtonProps extends ButtonProps {
    setCampaignsSelection: React.Dispatch<React.SetStateAction<number[]>>;
    campaignsSelection: number[];
    promoCampaigns: UseQueryResult<PromoCampaignBase[], unknown>;
    campaignsDisabled: boolean;
    generateUniqueCodeMut: UseMutationResult<
        {
            message: string;
        }[],
        unknown,
        {
            ids: number[];
            quantityUniqueCode: number;
        },
        unknown
    >;
}

const GenerateUniqueUseCodeButton = ({
    sx,
    setCampaignsSelection,
    campaignsSelection,
    promoCampaigns,
    campaignsDisabled,
    generateUniqueCodeMut,
}: GenerateUniqueUseCodeButtonProps) => {
    const [open, setOpen] = useState(false);
    const [Toast, showToast] = useToast();
    const [quantity, setQuantity] = useState(0);

    const handleGenerateUniqueCode = async () => {
        const promise = generateUniqueCodeMut.mutateAsync(
            { ids: campaignsSelection, quantityUniqueCode: quantity },
            {
                onSuccess: async () => {
                    await promoCampaigns.refetch();
                    setQuantity(0);
                    showToast({
                        message:
                            'Les codes ont été générés, vous pouvez les télécharger en XLSX dans la campagne associée !',
                        severity: 'success',
                    });
                },
                onError: (error) => {
                    showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
                    console.error(error);
                },
            }
        );
        setCampaignsSelection([]); // Avoid disabled button flickering
        return promise;
    };

    const handleClose = () => {
        setOpen(false);
        if (quantity === 0) {
            return;
        }

        handleGenerateUniqueCode();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Toast />
            <Button
                variant="contained"
                sx={sx}
                onClick={() => {
                    handleOpen();
                }}
                disabled={generateUniqueCodeMut.isLoading || campaignsDisabled}
            >
                <AutoAwesomeIcon />
                Générer des codes unique
            </Button>
            <Dialog
                open={open}
                onClose={() => {
                    setQuantity(0);
                    handleClose();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Choisissez la quantité de codes uniques à générer
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ marginBlock: '16px' }}>
                        <TextField
                            variant="outlined"
                            label="Quantité"
                            value={quantity}
                            onChange={(e) => setQuantity(Number(e.target.value))}
                            type="number"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary" autoFocus>
                        Générer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GenerateUniqueUseCodeButton;
