import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { useStores } from '@gozoki/api';

export interface StoresPickerProps {
    setCurrentStores?: (value: number[]) => void;
    setUniqueStore?: (value: number) => void;
    onlyBoutiques?: boolean;
    defaultStore?: number;
    onlyPlatforms?: boolean;
}

export const StoresPicker = ({
    setCurrentStores,
    setUniqueStore,
    onlyBoutiques,
    defaultStore,
    onlyPlatforms,
}: StoresPickerProps) => {
    const { data: stores } = useStores();

    const allStoresIds = useMemo(() => {
        return stores
            ?.filter((store) => (onlyBoutiques ? store.storeType === 'BOUTIQUE' : true))
            .filter((store) => (onlyPlatforms ? store.storeType === 'WAREHOUSE' : true))
            .map((store) => store.id);
    }, [onlyBoutiques, onlyPlatforms, stores]);

    const realDefaultStore = useMemo(() => {
        if (defaultStore) return defaultStore;
        if (allStoresIds?.length) return allStoresIds[0];
        return null;
    }, [allStoresIds, defaultStore]);

    useEffect(() => {
        if (setCurrentStores) setCurrentStores(allStoresIds ?? []);
        if (setUniqueStore && realDefaultStore) setUniqueStore(realDefaultStore);
    }, [allStoresIds, defaultStore, realDefaultStore, setCurrentStores, setUniqueStore]);

    const storeSelectOptions = useMemo(() => {
        const options = stores
            ?.filter((store) => (onlyBoutiques ? store.storeType === 'BOUTIQUE' : true))
            .filter((store) => (onlyPlatforms ? store.storeType === 'WAREHOUSE' : true))
            .map((store) => ({
                label: store.label,
                value: store.id,
            }));
        if (!setUniqueStore) options?.push({ label: 'Toutes les boutiques', value: 0 });
        return options;
    }, [onlyBoutiques, onlyPlatforms, setUniqueStore, stores]);

    return (
        <div style={{ display: 'flex', flex: 2, margin: 6 }}>
            <FormControl fullWidth>
                <InputLabel>Boutique</InputLabel>
                <Select
                    label="Boutique"
                    defaultValue={defaultStore ?? storeSelectOptions?.[0].value}
                    onChange={(e) => {
                        if (setUniqueStore) setUniqueStore(e.target.value as number);
                        if (setCurrentStores) {
                            setCurrentStores(
                                e.target.value === 0
                                    ? allStoresIds ?? []
                                    : [e.target.value as number]
                            );
                        }
                    }}
                >
                    {storeSelectOptions?.map((store) => (
                        <MenuItem key={store.value} value={store.value}>
                            {store.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
