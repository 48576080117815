import { createUser, updateUser, useUser, useUserAdminForm } from '@gozoki/api';
import { RIGHTS, RIGHTS_LABELS } from '@gozoki/tools';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '../../components/communication/Toast';
import LoadingPaper from '../../components/containers/LoadingPaper';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import Page from '../../components/Page';
import { ROUTES } from '../../utils/constants/routes.constants';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';

const AddUserPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const updateMode = id !== undefined; // Update mode if there is a promo campaign ID in the URL

    // If this is update mode, use isLoading to show a loading indicator before the data arrives
    const { data: user, isLoading } = useUser(parseInt(id ?? '', 10));

    const { control, trigger, watch, getValues, setValue } = useUserAdminForm();

    useEffect(() => {
        watch('adminRights');
    }, [watch]);

    const [Toast, showToast] = useToast();

    const [modifyPassword, setModifyPassword] = useState(!updateMode);
    const [openDialog, setOpenDialog] = useState(false);

    // Fill the form with initial data once the data is fetched
    useEffect(() => {
        // Set all values
        if (updateMode && user) {
            setValue('firstName', user.firstName);
            setValue('lastName', user.lastName);
            setValue('email', user.email);
            setValue('adminRights', user.adminRights);
        }
    }, [setValue, updateMode, user]);

    const navigateBack = useCallback(() => {
        navigate(ROUTES.CLIENTS);
    }, [navigate]);

    // ******************************************************************** //
    //                              MUTATIONS                               //
    // ******************************************************************** //

    const { mutate: upload, isLoading: isUploading } = useMutation(createUser, {
        onSuccess: navigateBack,
        onError: (error) => {
            showToast({
                severity: 'error',
                message: getErrorMessageWithSentry(error),
            });
        },
    });

    const { mutate: update, isLoading: isUpdating } = useMutation(updateUser, {
        onSuccess: navigateBack,
        onError: (error) => {
            showToast({
                severity: 'error',
                message: getErrorMessageWithSentry(error),
            });
        },
    });

    // ******************************************************************** //
    //                               HANDLERS                               //
    // ******************************************************************** //

    const handleCreateUser = async () => {
        const valid = await trigger(); // Trigger form validation

        if (valid) {
            upload(getValues());
        } else {
            showToast({
                severity: 'error',
                message: 'Veuillez remplir tous les champs obligatoires',
            });
        }
    };

    const handleUpdateUser = async () => {
        const valid = await trigger(); // Trigger form validation

        if (valid) {
            update({ userId: user?.id ?? 0, ...getValues() });
        } else {
            showToast({
                severity: 'error',
                message: 'Veuillez remplir tous les champs obligatoires',
            });
        }
    };

    const rightsOptions = Object.keys(RIGHTS)
        .slice(2)
        .map((key) => ({
            value: RIGHTS[key as keyof typeof RIGHTS],
            label: RIGHTS_LABELS[key as keyof typeof RIGHTS],
        }));

    const toggleRight = (right: number) => {
        if (getValues('adminRights') & RIGHTS.SUPER_ADMIN) return; // Can't remove super admin rights
        const newRights = getValues('adminRights') ^ right;
        setValue('adminRights', newRights);
    };

    return (
        <>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir rendre cet utilisateur super admin ?
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        Les super administrateurs ont tous les droits sur le back-office et sur les
                        autres utilisateurs. Ce rôle doit être limité à un nombre restreint
                        d'utilisateurs. Etre super administrateur ne change rien à l'utilisation du
                        back-office, donner tous les autres droits à un utilisateur suffit pour
                        qu'il puisse tout faire.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="warning"
                        onClick={() => {
                            setValue('adminRights', 1);
                            setOpenDialog(false);
                        }}
                    >
                        Confirmer
                    </Button>
                    <Button
                        autoFocus
                        variant="contained"
                        color="success"
                        onClick={() => setOpenDialog(false)}
                    >
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
            <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
                <Toast />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={navigateBack}>
                        <ArrowBackIcon
                            sx={{
                                color: ThemeColors.BLACK,
                                height: '24px',
                                width: '24px',
                            }}
                        />
                    </IconButton>
                    <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                        {updateMode ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'}
                    </Typography>

                    <Button
                        variant="outlined"
                        sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                        onClick={navigateBack}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        disabled={isUpdating || isUploading}
                        onClick={updateMode ? handleUpdateUser : handleCreateUser}
                    >
                        {updateMode ? 'Modifier' : 'Ajouter'}
                    </Button>
                </div>
                <LoadingPaper
                    sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                    loading={isLoading && updateMode}
                >
                    <Typography mb="8px" style={fonts.inputHeader}>
                        Prénom
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        placeholder="Prénom"
                        color="info"
                        control={control}
                        trigger={trigger}
                        name="firstName"
                        fullWidth
                    />
                    <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                        Nom
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        placeholder="Nom"
                        color="info"
                        control={control}
                        trigger={trigger}
                        name="lastName"
                        fullWidth
                    />

                    <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                        Email
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        placeholder="Email"
                        color="info"
                        control={control}
                        trigger={trigger}
                        name="email"
                        fullWidth
                    />

                    <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                        Mot de passe
                    </Typography>
                    {modifyPassword ? (
                        <TextFieldRhf
                            variant="outlined"
                            placeholder="Mot de passe"
                            color="info"
                            control={control}
                            trigger={trigger}
                            name="password"
                            fullWidth
                        />
                    ) : (
                        <Button onClick={() => setModifyPassword(true)}>
                            Modifier le mot de passe
                        </Button>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography mb="8px" mt="24px" style={{ ...fonts.inputHeader, flex: 1 }}>
                            Droits d'administration
                        </Typography>
                        <Button
                            color="warning"
                            onClick={() => setOpenDialog(true)}
                            disabled={getValues('adminRights') === 1}
                            style={{ flex: 1 }}
                        >
                            {getValues('adminRights') === 1
                                ? 'Utilisateur super administrateur'
                                : 'Passer super administrateur'}
                        </Button>
                    </div>
                    <div>
                        {rightsOptions.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                control={
                                    <Checkbox
                                        checked={
                                            (getValues('adminRights') & option.value) !== 0 ||
                                            getValues('adminRights') === 1
                                        }
                                        onChange={() => toggleRight(option.value)}
                                        value={option.value}
                                    />
                                }
                                label={option.label}
                            />
                        ))}
                    </div>
                </LoadingPaper>
            </Page>
        </>
    );
};

export default AddUserPage;
