import { DailyDashboardKpi } from '@gozoki/api-types';
import { DateTime } from 'luxon';
import { ThemeColors } from '../theme/colors.theme';

const getWeek = (date: Date): number => {
    return DateTime.fromJSDate(date).weekNumber;
};

export const getChartInfos = (kpis: DailyDashboardKpi[], currentTimeRange: number) => {
    let firstName = '';
    let secondName = '';

    let firstData: number[] = [];
    let secondData: number[] = [];

    let labels: string[] = [];

    if (currentTimeRange === 31) {
        // we want to display data on days period
        const lastTwoMonthsKpis = kpis?.filter((kpi) => {
            const kpiDate = new Date(kpi.date);
            const diffTime = Math.abs(new Date().getTime() - kpiDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays <= 62;
        });
        const lastMonthDate = new Date();
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        const lastMonth = lastMonthDate.getMonth();
        const lastYear = lastMonthDate.getFullYear();

        const thisMonthKpis = lastTwoMonthsKpis
            ?.filter((kpi) => new Date(kpi.date).getMonth() === new Date().getMonth())
            .sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate());
        const lastMonthKpis = lastTwoMonthsKpis
            ?.filter(
                (kpi) =>
                    new Date(kpi.date).getMonth() === lastMonth &&
                    new Date(kpi.date).getFullYear() === lastYear
            )
            .sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate());

        const monthDates = new Array(31).fill(0).map((_, index) => index + 1);

        firstName = `${new Date().toLocaleString('fr-FR', {
            month: 'long',
        })}`;
        secondName = `${new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            new Date().getDate()
        ).toLocaleString('fr-FR', { month: 'long' })}`;

        labels = monthDates.map((date) => `${date}`);

        secondData = [...monthDates].map((date) =>
            lastMonthKpis
                ?.filter((kpi) => new Date(kpi.date).getDate() === date)
                .reduce((acc, kpi) => acc + (kpi.ca ?? 0), 0)
        );

        firstData = [...monthDates].map((date) =>
            thisMonthKpis
                ?.filter((kpi) => new Date(kpi.date).getDate() === date)
                .reduce((acc, kpi) => acc + (kpi.ca ?? 0), 0)
        );
    } else if (currentTimeRange === 7 || currentTimeRange === 1) {
        // we want to display data on days period
        const lastTwoWeekKpis = kpis?.filter((kpi) => {
            const kpiDate = new Date(kpi.date);
            const diffTime = Math.abs(new Date().getTime() - kpiDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays <= 14;
        });
        const thisWeekKpis = lastTwoWeekKpis
            ?.filter((kpi) => getWeek(new Date(kpi.date)) === getWeek(new Date()))
            .sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate());
        const lastWeekKpis = lastTwoWeekKpis
            ?.filter((kpi) => getWeek(new Date(kpi.date)) === getWeek(new Date()) - 1)
            .sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate());

        const weekDates = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

        firstName = `cette semaine`;
        secondName = `la semaine dernière`;

        labels = weekDates.map((date) => `${date}`);

        firstData = weekDates.map((_date, index) =>
            thisWeekKpis
                ?.filter((kpi) => new Date(kpi.date).getDay() === (index + 1) % 7)
                .reduce((acc, kpi) => acc + kpi.ca, 0)
        );
        secondData = weekDates.map((_date, index) =>
            lastWeekKpis
                ?.filter((kpi) => new Date(kpi.date).getDay() === (index + 1) % 7)
                .reduce((acc, kpi) => acc + kpi.ca, 0)
        );
    } else {
        // we want to display data on years period
        const lastTwoYearsKpis = kpis?.filter((kpi) => {
            const kpiDate = new Date(kpi.date);
            const diffTime = Math.abs(new Date().getTime() - kpiDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays <= 730;
        });

        const thisYearKpis = lastTwoYearsKpis
            ?.filter((kpi) => new Date(kpi.date).getFullYear() === new Date().getFullYear())
            .sort((a, b) => new Date(a.date).getMonth() - new Date(b.date).getMonth());
        const lastYearKpis = lastTwoYearsKpis
            ?.filter((kpi) => new Date(kpi.date).getFullYear() === new Date().getFullYear() - 1)
            .sort((a, b) => new Date(a.date).getMonth() - new Date(b.date).getMonth());
        const yearDates = [
            'janvier',
            'février',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre',
        ];

        firstName = `${new Date().getFullYear()}`;
        secondName = `${new Date().getFullYear() - 1}`;

        labels = yearDates.map((date) => `${date}`);

        firstData = yearDates.map((_date, index) =>
            thisYearKpis
                ?.filter((kpi) => new Date(kpi.date).getMonth() === index)
                .reduce((acc, kpi) => acc + kpi.ca, 0)
        );
        secondData = yearDates.map((_date, index) =>
            lastYearKpis
                ?.filter((kpi) => new Date(kpi.date).getMonth() === index)
                .reduce((acc, kpi) => acc + kpi.ca, 0)
        );
    }

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `Evolution du chiffre d'affaire entre ${firstName} et ${secondName} (€)`,
                font: {
                    size: 24, // Adjusts the font size
                },
                align: 'start' as const, // Aligns the title to the left
            },
        },
        scales: {
            x: {
                ticks: {},
            },
        },
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: firstName,
                data: firstData,
                borderColor: ThemeColors.ORANGE,
                backgroundColor: 'rgba(243, 146, 0, 0.2)',
                fill: true,
                tension: 0.3,
            },
            {
                label: secondName,
                data: secondData,
                borderColor: ThemeColors.BLACK,
                tension: 0.3,
            },
        ],
    };
    return { chartData, chartOptions };
};
