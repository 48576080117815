import { RIGHTS, RIGHTS_KEYS, RIGHTS_LABELS, Right } from '../types/rights.types';

import { filterUndefinedOrNullValues } from './arrays.tools';

export const hasRights = (rights: number, wantedRights: Right[]) => {
    // Super admin automatically has all rights
    if ((rights & RIGHTS.SUPER_ADMIN) !== 0) {
        return true;
    }

    // Else, validate all asked rights
    // This validation now considers the hierarchical nature of rights
    return wantedRights.every((right) => {
        const requiredRight = RIGHTS[right];
        return (rights & requiredRight) === requiredRight;
    });
};

export const hasOneRight = (rights: number, wantedRights: Right[]) => {
    // Super admin automatically has all rights
    if ((rights & RIGHTS.SUPER_ADMIN) !== 0) {
        return true;
    }

    // Else, validate all asked rights
    // This validation now considers the hierarchical nature of rights
    return wantedRights.some((right) => {
        const requiredRight = RIGHTS[right];
        return (rights & requiredRight) === requiredRight;
    });
};

/** For forms with complex string to number validation
 * In order to be accepted by the form, a number must be converted into a string,
 * but typed as a number in order to trick the form validation
 */
export const asStringNumber = (value: number): number => {
    return value.toString() as unknown as number;
};

export const getUserRoles = (rights: number): Right[] => {
    const roles = RIGHTS_KEYS.filter((r) => hasRights(rights, [r]));

    // This optimization ensures that if SUPER_ADMIN is detected, it returns immediately
    // with only the SUPER_ADMIN role, as it implicitly includes all other rights
    if (roles.includes('SUPER_ADMIN')) return ['SUPER_ADMIN'];

    return filterUndefinedOrNullValues(roles);
};

export const getUserRolesLabels = (rights: number) => {
    const roles = getUserRoles(rights);

    return roles.map((r) => RIGHTS_LABELS[r]);
};
