import { Button, Chip, Paper, TextField, Typography } from '@mui/material';

import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import PlusIcon from '../../components/icons/PlusIcon';
import { NOTIFICATIONS_ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { fonts } from '../../utils/theme/fonts.theme';
import { useMemo, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useNotifications } from '@gozoki/api';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { includesLowerCase } from '@gozoki/tools';
import { ContentCopy } from '@mui/icons-material';

const SMALL_TAB = 120;
const TAB_WIDTH = 160;
const BIGGER_TAB = 260;

function prettifyActionPath(value?: string): string {
    if (value?.startsWith('anti-gaspi/')) {
        return 'Redirige vers un produit';
    }
    if (value === 'anti-gaspi') {
        return 'Redirige vers la page anti-gaspi';
    }
    if (value === 'product') {
        return 'Redirige vers un page produit';
    }
    return 'Aucune action';
}

const notificationsDefs: GridColDef[] = [
    {
        field: 'title',
        headerName: 'Titre',
        width: TAB_WIDTH,
    },
    {
        field: 'subTitle',
        headerName: 'Sous-titre',
        width: TAB_WIDTH,
    },
    {
        field: 'text',
        headerName: 'Contenu',
        width: BIGGER_TAB,
    },
    {
        field: 'onlyUsers',
        headerName: 'Inscrits seulement',
        width: BIGGER_TAB,
        type: 'boolean',
    },
    {
        field: 'data',
        headerName: 'Action',
        width: BIGGER_TAB,
        renderCell: (field) => <Chip label={prettifyActionPath(field.value?.path)} />,
    },
    {
        field: 'recipients',
        headerName: 'Destinataires',
        width: SMALL_TAB,
    },
    {
        field: 'interactionsCount',
        headerName: 'Interactions',
        width: SMALL_TAB,
    },
    {
        field: 'createdAt',
        headerName: 'Créée le',
        width: BIGGER_TAB,
        valueFormatter: (params) => {
            return new Date(params.value as string).toLocaleString();
        },
    },
];

const NotificationsPage = () => {
    const navigate = useNavigate();

    const { data: notifications, isLoading: notificationsLoading } = useNotifications();
    const [notificationSearch, setNotificationSearch] = useState('');
    const [notificationSelection, setNotificationSelection] = useState<number[]>([]);

    const navigateViewNotification = async () => {
        navigate(ROUTE_BUILDER.viewNotification(notificationSelection[0]));
    };

    const navigateCloneNotification = async () => {
        navigate(ROUTE_BUILDER.duplicateNotification(notificationSelection[0]));
    };

    const notificationRows = useMemo(() => {
        if (!notifications) return [];
        return notifications
            .filter((notification) => {
                return includesLowerCase(notification.title, notificationSearch);
            })
            .map((notification) => ({
                ...notification,
            }));
    }, [notifications, notificationSearch]);

    return (
        <Page>
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Notifications
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginRight: '16px' }}
                    onClick={navigateViewNotification}
                    disabled={notificationSelection.length !== 1}
                >
                    Voir
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginRight: '16px' }}
                    onClick={navigateCloneNotification}
                    disabled={notificationSelection.length !== 1}
                >
                    <ContentCopy />
                    Dupliquer
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: '16px' }}
                    onClick={() => {
                        navigate(NOTIFICATIONS_ROUTES.CREATE_NOTIFICATION);
                    }}
                >
                    <PlusIcon />
                    Créer une notification
                </Button>
            </div>
            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <TextField
                        variant="outlined"
                        label="Recherche"
                        value={notificationSearch}
                        onChange={(e) => setNotificationSearch(e.target.value)}
                    />
                </div>
                <div style={{ flexGrow: 1 }} />
                <SimpleDataGrid
                    columns={notificationsDefs}
                    rows={notificationRows}
                    loading={notificationsLoading}
                    onRowSelectionModelChange={(newSelection) =>
                        setNotificationSelection(newSelection as number[])
                    }
                />
            </Paper>
        </Page>
    );
};

export default NotificationsPage;
