/* eslint-disable no-restricted-syntax */
import {
    downloadAdminXlsx,
    downloadWeeklyAuditAdminXlsx,
    rebootCpiKiosk,
    useCategories,
    useMultipleCategories,
    useProducts,
    useStoreKiosks,
    useStorePromoData,
    useStores,
    useStoresKpis,
    useStoresMetadata,
} from '@gozoki/api';
import { Category, Product } from '@gozoki/api-types';
import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { useCallback, useMemo, useState } from 'react';
import { CategoryCard, DashboardCard, ProductCard } from '../../utils/dashboard/dashboardCards';
import { DateStateProps, FourDatePicker } from '../../utils/dashboard/fourDatePicker';

import { frenchFormat } from '@gozoki/tools';
import { useMutation } from '@tanstack/react-query';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import styles from '../../assets/styles/DashboardPage.module.css';
import { ConfirmButton } from '../../components/communication/ConfirmButton';
import { useToast } from '../../components/communication/Toast';
import Page from '../../components/Page';
import { getChartInfos } from '../../utils/dashboard/getChartInfos';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { timeRanges } from '../../utils/dashboard/timeRanges';
import { writeProductsCategoriesSold } from '../../utils/excel/writeProductsCategoriesSold';
import { useRights } from '../../utils/hooks';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import DialogSelectDates from './DialogSelectDates';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export interface ProductWithCount extends Product {
    count: number;
    ca: number;
}

export interface CategoryWithCount extends Category {
    count: number;
    ca: number;
}

const TIME_RANGE_PRESET = {
    KEEP_UNTOUCHED: 0,
    LAST_WEEK: 1,
    CURRENT_WEEK: 2,
} as const;

const dateNow = new Date();

const DashboardPage = () => {
    const isAccountingReader = useRights(['ACCOUNTING_READER']);
    const isStockReader = useRights(['STOCK_READER']);
    const [Toast, showToast] = useToast();

    const navigate = useNavigate();

    const { data: stores } = useStores();

    const [currentTimeRange, setCurrentTimeRange] = useState<number>(-1);

    const [currentDateStart, setCurrentDateStart] = useState<Date>(dateNow);
    const [currentDateEnd, setCurrentDateEnd] = useState<Date>(dateNow);

    const [lastDateStart, setLastDateStart] = useState<Date>(dateNow);
    const [lastDateEnd, setLastDateEnd] = useState<Date>(dateNow);

    const [currentStores, setCurrentStores] = useState<number[]>([]);

    const [seeAllProducts, setSeeAllProducts] = useState<boolean>(false);
    const [seeAllCategories, setSeeAllCategories] = useState<boolean>(false);

    const [productMode, setProductMode] = useState<'count' | 'ca'>('count');
    const [categoryMode, setCategoryMode] = useState<'count' | 'ca'>('count');

    const { data: allKpis } = useStoresKpis();
    const { data: stocks } = useStoresMetadata(currentStores);
    const { data: allProducts } = useProducts();
    const { data: allCategories } = useCategories();

    const [isLoading, setIsLoading] = useState(false);

    const {
        a: currentStoreName,
        b: currentStoreReference,
        c: currentStoreId,
    } = useMemo(() => {
        if (currentStores.length === 1) {
            const s = stores?.filter((store) => store.id === currentStores[0])[0];
            if (!s) return { a: null, b: null };
            return {
                a: s.label,
                b: s.checkpointStoreRef,
                c: s.id,
            };
        }
        return { a: null, b: null, c: null };
    }, [currentStores, stores]);

    // ********** FILTER KPIS WITH STORES AND DATES ********** //

    const currentStoresKpis = useMemo(() => {
        return allKpis?.filter((kpi) => currentStores.includes(kpi.storeId));
    }, [allKpis, currentStores]);

    const currentKpis = useMemo(() => {
        return currentStoresKpis?.filter((kpi) => {
            const kpiDate = new Date(kpi.date);
            return (
                kpiDate.getTime() >= currentDateStart.getTime() &&
                kpiDate.getTime() <= currentDateEnd.getTime()
            );
        });
    }, [currentDateEnd, currentDateStart, currentStoresKpis]);

    const lastKpis = useMemo(() => {
        return currentStoresKpis?.filter((kpi) => {
            const kpiDate = new Date(kpi.date);
            return (
                kpiDate.getTime() >= lastDateStart.getTime() &&
                kpiDate.getTime() <= lastDateEnd.getTime()
            );
        });
    }, [currentStoresKpis, lastDateEnd, lastDateStart]);

    // ********************* GENERATE CA DATA ******************** //

    const caTtc = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.ca, 0);
    }, [currentKpis]);

    const lastCa = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.ca, 0);
    }, [lastKpis]);

    const caHt = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.caHt, 0);
    }, [currentKpis]);

    const caProgression = useMemo(() => {
        if (!caTtc || !lastCa) return 0;
        return (caTtc - lastCa) / (lastCa ?? 1);
    }, [caTtc, lastCa]);

    // ***************** GENERATE CA ANTI-GASPI DATA **************** //

    const caAntiGaspi = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.caAntiGaspi, 0);
    }, [currentKpis]);

    const lastCaAntiGaspi = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.caAntiGaspi, 0);
    }, [lastKpis]);

    const caAntiGaspiProgression = useMemo(() => {
        if (!caAntiGaspi || !lastCaAntiGaspi) return 0;
        return (caAntiGaspi - lastCaAntiGaspi) / (lastCaAntiGaspi ?? 1);
    }, [caAntiGaspi, lastCaAntiGaspi]);

    // ********************* GENERATE MARGIN DATA ********************* //

    const allBuyingPrices = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.buyingPrice, 0);
    }, [currentKpis]);

    const lastAllBuyingPrices = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.buyingPrice, 0);
    }, [lastKpis]);

    const margeBrute = useMemo(() => {
        if (!caHt) return 0;
        return caHt - (allBuyingPrices ?? 0);
    }, [allBuyingPrices, caHt]);

    const lastMargeBrute = useMemo(() => {
        return lastCa - lastAllBuyingPrices;
    }, [lastAllBuyingPrices, lastCa]);

    const progressionMargeBrute = useMemo(() => {
        return (margeBrute - lastMargeBrute) / lastMargeBrute;
    }, [lastMargeBrute, margeBrute]);

    const tauxMargeBrute = useMemo(() => {
        if (!margeBrute || !caHt) return 0;
        return (margeBrute / caHt) * 100;
    }, [caHt, margeBrute]);

    // ***************** GENERATE PRODUCT COUNT DATA ***************** //

    const produitsVendus = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis?.reduce((acc, kpi) => acc + kpi.productsSoldCount, 0);
    }, [currentKpis]);

    const lastProduitsVendus = useMemo(() => {
        return lastKpis?.reduce((acc, kpi) => acc + kpi.productsSoldCount, 0);
    }, [lastKpis]);

    const progressionProduitsVendus = useMemo(() => {
        return ((produitsVendus ?? 0) - (lastProduitsVendus ?? 0)) / (lastProduitsVendus ?? 1);
    }, [lastProduitsVendus, produitsVendus]);

    // ******************* GENERATE AVERAGE CART DATA ******************* //

    const numberOfCarts = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.cartsCount, 0) ?? 1;
    }, [currentKpis]);

    const lastNumberOfCarts = useMemo(() => {
        return lastKpis?.reduce((acc, kpi) => acc + kpi.cartsCount, 0) ?? 1;
    }, [lastKpis]);

    const panierMoyen = useMemo(() => {
        if (numberOfCarts === 0) return 0;
        return caTtc / numberOfCarts;
    }, [caTtc, numberOfCarts]);

    const lastPanierMoyen = useMemo(() => {
        return (lastCa ?? 0) / lastNumberOfCarts;
    }, [lastCa, lastNumberOfCarts]);

    const progressionPanierMoyen = useMemo(() => {
        return ((panierMoyen ?? 0) - (lastPanierMoyen ?? 0)) / (lastPanierMoyen ?? 1);
    }, [lastPanierMoyen, panierMoyen]);

    const progressionNumberOfCarts = useMemo(() => {
        return ((numberOfCarts ?? 0) - (lastNumberOfCarts ?? 0)) / (lastNumberOfCarts ?? 1);
    }, [lastNumberOfCarts, numberOfCarts]);

    // ******************** GENERATE STOCK VALUE DATA ******************* //

    const valeurStock = useMemo(() => {
        let stockValue = 0;
        let antiGaspiStockValue = 0;
        stocks?.forEach((stock) => {
            stockValue += stock.metadata.totalStockValue;
            antiGaspiStockValue += stock.metadata.totalAntiGaspiStockValue;
        });

        return { value: stockValue, antiGaspiValue: antiGaspiStockValue };
    }, [stocks]);

    // ********************* GENERATE CHART DATA ********************* //

    const { chartData, chartOptions } = useMemo(() => {
        if (!currentStoresKpis) return { chartData: null, chartOptions: null };
        return getChartInfos(currentStoresKpis, currentTimeRange);
    }, [currentStoresKpis, currentTimeRange]);

    // ********************* GENERATE FAMOUS PRODUCTS ********************* //

    const famousProductsCount = useMemo(() => {
        const products: {
            [key: string]: { ref: string; count: number; ca: number; product?: Product };
        } = {};
        currentKpis?.forEach((kpi) => {
            Object.values(kpi.productsSoldMap).forEach(({ ref, count }) => {
                if (products[ref]) {
                    products[ref].count += count;
                } else {
                    products[ref] = { ref, count, product: undefined, ca: 0 };
                }
            });
            Object.values(kpi.productsSoldMapByCa).forEach(({ ref, ca }) => {
                if (products[ref]) {
                    products[ref].ca += ca ?? 0;
                } else {
                    products[ref] = { ref, ca: ca ?? 0, product: undefined, count: 0 };
                }
            });
        });

        const productsArray = Object.values(products);
        productsArray.sort((a, b) => (productMode === 'count' ? b.count - a.count : b.ca - a.ca));

        const nonFamousProducts = allProducts?.filter((p) => !products[p.id]);
        if (nonFamousProducts) {
            nonFamousProducts.forEach((product) => {
                productsArray.push({ ref: product.reference, count: 0, ca: 0, product });
            });
        }

        return productsArray;
    }, [allProducts, currentKpis, productMode]);

    const famousProducts = useMemo(() => {
        return allProducts?.filter((p) =>
            famousProductsCount.map((f) => f.ref).includes(p.reference)
        );
    }, [allProducts, famousProductsCount]);

    const famousProductsData: ProductWithCount[] | undefined = useMemo(() => {
        return famousProducts
            ?.map((product) => {
                const productCount = famousProductsCount.filter(
                    (p) => p.ref === product.reference
                )[0].count;
                const productCa = famousProductsCount.filter((p) => p.ref === product.reference)[0]
                    .ca;
                return { ...product, count: productCount, ca: productCa } as ProductWithCount;
            })
            .sort((a, b) => (productMode === 'count' ? b.count - a.count : b.ca - a.ca));
    }, [famousProducts, famousProductsCount, productMode]);

    // ******************* GENERATE FAMOUS CATEGORIES ******************* //

    const categoriesWithSub = useMemo(() => {
        const categoriesMap: { [key: number]: { id: number; subCats: number[] } } = {};
        const categoriesList: number[] = [];
        allCategories?.forEach((cat) => {
            if (cat.categoryId) {
                if (categoriesMap[cat.categoryId]) {
                    categoriesMap[cat.categoryId].subCats.push(cat.id);
                    categoriesList.push(cat.id);
                } else {
                    categoriesMap[cat.categoryId] = { id: cat.categoryId, subCats: [cat.id] };
                    categoriesList.push(cat.categoryId);
                    categoriesList.push(cat.id);
                }
            }
        });
        allCategories?.forEach((cat) => {
            if (!categoriesList.includes(cat.id)) {
                categoriesMap[cat.id] = { id: cat.id, subCats: [] };
            }
        });
        return categoriesMap;
    }, [allCategories]);

    const famousCategoriesCount = useMemo(() => {
        if (!currentKpis) return [];
        if (!categoriesWithSub) return [];
        const categoriesCount: {
            id: number;
            count: number;
            ca: number;
            subs: { id: number; count: number; ca: number }[];
        }[] = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(categoriesWithSub).forEach(([_key, value]) => {
            const count = currentKpis?.reduce((acc, kpi) => {
                return acc + (kpi.categoriesSoldMap[value.id]?.count ?? 0);
            }, 0);
            const ca = currentKpis?.reduce((acc, kpi) => {
                return acc + (kpi.categoriesSoldMapByCa[value.id]?.ca ?? 0);
            }, 0);
            const subCategoriesCount: { id: number; count: number; ca: number }[] = [];
            categoriesWithSub[value.id].subCats.forEach((subCat) => {
                const countSub = currentKpis?.reduce((acc, kpi) => {
                    return acc + (kpi.categoriesSoldMap[subCat]?.count ?? 0);
                }, 0);
                const caSub = currentKpis?.reduce((acc, kpi) => {
                    return acc + (kpi.categoriesSoldMapByCa[subCat]?.ca ?? 0);
                }, 0);
                subCategoriesCount.push({ id: subCat, count: countSub ?? 0, ca: caSub ?? 0 });
            });
            subCategoriesCount.sort((a, b) =>
                categoryMode === 'count' ? b.count - a.count : b.ca - a.ca
            );
            categoriesCount.push({
                id: Number(value.id),
                count: count ?? 0,
                ca: ca ?? 0,
                subs: subCategoriesCount,
            });
        });

        const categoriesArray: { id: number; count: number; ca: number }[] = [];
        const sortedCategoriesCount = categoriesCount.sort((a, b) =>
            categoryMode === 'count' ? b.count - a.count : b.ca - a.ca
        );
        for (const cat of sortedCategoriesCount) {
            categoriesArray.push({ id: cat.id, count: cat.count, ca: cat.ca });
            cat.subs.forEach((sub) => {
                categoriesArray.push({ id: sub.id, count: sub.count, ca: sub.ca });
            });
        }

        return categoriesArray;
    }, [currentKpis, categoriesWithSub, categoryMode]);

    const { data: famousCategories } = useMultipleCategories(
        famousCategoriesCount.map((c) => c.id)
    );

    const famousCategoriesData: CategoryWithCount[] | undefined = useMemo(() => {
        const allCats = famousCategoriesCount?.map((category) => {
            const fullCategory = famousCategories?.filter((c) => c.id === category.id)[0];
            return { ...fullCategory, count: category.count, ca: category.ca } as CategoryWithCount;
        });
        return allCats;
    }, [famousCategories, famousCategoriesCount]);

    // ********************* SET TIME RANGE ********************* //

    const dateState: DateStateProps = useMemo(() => {
        return {
            timeRanges: {
                all: timeRanges,
                current: currentTimeRange,
                set: setCurrentTimeRange,
            },
            currentDate: {
                start: currentDateStart,
                setStart: setCurrentDateStart,
                end: currentDateEnd,
                setEnd: setCurrentDateEnd,
            },
            lastDate: {
                start: lastDateStart,
                setStart: setLastDateStart,
                end: lastDateEnd,
                setEnd: setLastDateEnd,
            },
        };
    }, [currentTimeRange, currentDateStart, currentDateEnd, lastDateStart, lastDateEnd]);

    // ************************* EXPORT ************************* //

    const handleExport = useCallback(async () => {
        if (isLoading) return;

        const date1 = currentDateStart.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        const date2 = currentDateEnd.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        setIsLoading(true);
        if (famousCategoriesData && famousProductsData) {
            await writeProductsCategoriesSold(
                famousProductsData,
                famousCategoriesData,
                produitsVendus,
                caTtc,
                `export-ventes-${date1}-${date2}`
            );
        }
        setIsLoading(false);
    }, [
        caTtc,
        currentDateEnd,
        currentDateStart,
        famousCategoriesData,
        famousProductsData,
        isLoading,
        produitsVendus,
    ]);

    const navigateToHoursPage = useCallback(() => {
        navigate('/dashboard/hours');
    }, [navigate]);

    // ************************ APP DATAS ************************ //

    const currentAppUsers = useMemo(() => {
        if (!currentKpis || !currentKpis[0]) return 0;
        return currentKpis[0].appUsersCount;
    }, [currentKpis]);

    const lastAppUsers = useMemo(() => {
        if (!lastKpis || !lastKpis[0]) return 0;
        return lastKpis[0].appUsersCount;
    }, [lastKpis]);

    const appUsersProgression = useMemo(() => {
        return ((currentAppUsers ?? 0) - (lastAppUsers ?? 0)) / (lastAppUsers ?? 1);
    }, [currentAppUsers, lastAppUsers]);

    const currentAppActiveUsers = useMemo(() => {
        if (!currentKpis) return 0;
        const activesUsersList = new Set<number>();
        currentKpis.forEach((kpi) => {
            kpi.appUsersList.forEach((user) => {
                activesUsersList.add(user);
            });
        });
        return activesUsersList.size;
    }, [currentKpis]);

    const lastAppActiveUsers = useMemo(() => {
        if (!lastKpis) return 0;
        const activesUsersList = new Set<number>();
        lastKpis.forEach((kpi) => {
            kpi.appUsersList.forEach((user) => {
                activesUsersList.add(user);
            });
        });
        return activesUsersList.size;
    }, [lastKpis]);

    const appActiveUsersProgression = useMemo(() => {
        return (
            ((currentAppActiveUsers ?? 0) - (lastAppActiveUsers ?? 0)) / (lastAppActiveUsers ?? 1)
        );
    }, [currentAppActiveUsers, lastAppActiveUsers]);

    const currentAppCartCount = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.appCartsCount, 0);
    }, [currentKpis]);

    const lastAppCartCount = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.appCartsCount, 0);
    }, [lastKpis]);

    const appCartCountProgression = useMemo(() => {
        return ((currentAppCartCount ?? 0) - (lastAppCartCount ?? 0)) / (lastAppCartCount ?? 1);
    }, [currentAppCartCount, lastAppCartCount]);

    const appCartPercent = useMemo(() => {
        return (currentAppCartCount / numberOfCarts) * 100;
    }, [currentAppCartCount, numberOfCarts]);

    const currentFilteredKpis = useMemo(() => {
        const dateSet = new Set<string>();
        const filteredKpis = currentKpis?.filter((kpi) => {
            if (dateSet.has(kpi.date)) return false;
            dateSet.add(kpi.date);
            return true;
        });
        return filteredKpis;
    }, [currentKpis]);

    const lastFilteredKpis = useMemo(() => {
        const dateSet = new Set<string>();
        const filteredKpis = lastKpis?.filter((kpi) => {
            if (dateSet.has(kpi.date)) return false;
            dateSet.add(kpi.date);
            return true;
        });
        return filteredKpis;
    }, [lastKpis]);

    const currentNewUsers = useMemo(() => {
        if (!currentFilteredKpis) return 0;
        return currentFilteredKpis.reduce((acc, kpi) => acc + kpi.appNewUsersCount, 0);
    }, [currentFilteredKpis]);

    const lastNewUsers = useMemo(() => {
        if (!lastFilteredKpis) return 0;
        return lastFilteredKpis.reduce((acc, kpi) => acc + kpi.appNewUsersCount, 0);
    }, [lastFilteredKpis]);

    const newUsersProgression = useMemo(() => {
        return ((currentNewUsers ?? 0) - (lastNewUsers ?? 0)) / (lastNewUsers ?? 1);
    }, [currentNewUsers, lastNewUsers]);

    const currentDeletedUsers = useMemo(() => {
        if (!currentFilteredKpis) return 0;
        return currentFilteredKpis.reduce((acc, kpi) => acc + kpi.appDeletedUsersCount, 0);
    }, [currentFilteredKpis]);

    const lastDeletedUsers = useMemo(() => {
        if (!lastFilteredKpis) return 0;
        return lastFilteredKpis.reduce((acc, kpi) => acc + kpi.appDeletedUsersCount, 0);
    }, [lastFilteredKpis]);

    const deletedUsersProgression = useMemo(() => {
        return ((currentDeletedUsers ?? 0) - (lastDeletedUsers ?? 0)) / (lastDeletedUsers ?? 1);
    }, [currentDeletedUsers, lastDeletedUsers]);

    const currentActivatedUsers = useMemo(() => {
        if (!currentFilteredKpis) return 0;
        return currentFilteredKpis.reduce((acc, kpi) => acc + kpi.appActivatedUsersCount, 0);
    }, [currentFilteredKpis]);

    const lastActivatedUsers = useMemo(() => {
        if (!lastFilteredKpis) return 0;
        return lastFilteredKpis.reduce((acc, kpi) => acc + kpi.appActivatedUsersCount, 0);
    }, [lastFilteredKpis]);

    const activatedUsersProgression = useMemo(() => {
        return (
            ((currentActivatedUsers ?? 0) - (lastActivatedUsers ?? 0)) / (lastActivatedUsers ?? 1)
        );
    }, [currentActivatedUsers, lastActivatedUsers]);

    // ----------------- Audit file downloader ----------------- //

    const { mutate: downloadAuditFile } = useMutation(downloadAdminXlsx, {
        onSuccess: (response) => {
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `audit.xlsx`;
            link.click();
            URL.revokeObjectURL(url);
        },
        onError: async (error: { message: string; response: { data: Blob } }) => {
            console.error(error);
            if (error?.response?.data?.size) {
                // this is a blob
                const text = await error.response.data.text();
                const json: { message: string } = JSON.parse(text);
                showToast({
                    severity: 'error',
                    message: json.message as string,
                });
            } else {
                showToast({
                    severity: 'error',
                    message: error.message as string,
                });
            }
        },
    });

    const handleDownloadAuditFile = useCallback(
        (startIsoDate: string, endIsoDate: string) => {
            downloadAuditFile({
                dateStart: startIsoDate,
                dateEnd: endIsoDate,
                storeId: currentStores.length === 1 ? currentStores[0] : undefined,
            });
        },
        [currentStores, downloadAuditFile]
    );

    const { mutate: downloadWeeklyAuditFile, isLoading: isLoadingWeeklyAudit } = useMutation(
        downloadWeeklyAuditAdminXlsx,
        {
            onSuccess: (response) => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `weekly-audit.xlsx`;
                link.click();
                URL.revokeObjectURL(url);
            },
            onError: async (error: { message: string; response: { data: Blob } }) => {
                console.error(error);
                if (error?.response?.data?.size) {
                    // this is a blob
                    const text = await error.response.data.text();
                    const json: { message: string } = JSON.parse(text);
                    showToast({
                        severity: 'error',
                        message: json.message as string,
                    });
                } else {
                    showToast({
                        severity: 'error',
                        message: error.message as string,
                    });
                }
            },
        }
    );

    const handleDownloadWeeklyAuditFile = useCallback(
        (startIsoDate: string, endIsoDate: string, storeId?: number) => {
            downloadWeeklyAuditFile({
                dateStart: startIsoDate,
                dateEnd: endIsoDate,
                storeId,
            });
        },
        [downloadWeeklyAuditFile]
    );

    // ----------------- Kiosk reboot ----------------- //

    const { data: kiosks } = useStoreKiosks(currentStoreReference);

    const [isRebooting, setIsRebooting] = useState(false);

    const { mutate: rebootKiosk } = useMutation(rebootCpiKiosk, {
        onSuccess: () => {
            setIsRebooting(false);
        },
        onError: (err) => {
            console.error(err);
            setIsRebooting(false);
        },
    });

    const handleRebootKiosk = useCallback(
        async (kioskId: string) => {
            setIsRebooting(true);
            rebootKiosk(kioskId);
        },
        [rebootKiosk]
    );

    // ----------------- PROMO USE DATA ----------------- //

    const { data: promoUSed } = useStorePromoData(
        currentDateStart.toISOString().split('T')[0],
        currentDateEnd.toISOString().split('T')[0],
        currentStores
    );

    const { data: lastPromoUsed } = useStorePromoData(
        lastDateStart.toISOString().split('T')[0],
        lastDateEnd.toISOString().split('T')[0],
        currentStores
    );

    // ------------------- STUDENT DATA ------------------- //

    const currentStudentUsersCount = useMemo(() => {
        if (!currentKpis || !currentKpis[0]) return 0;
        return currentKpis[0].studentUsersCount;
    }, [currentKpis]);

    const lastStudentUsersCount = useMemo(() => {
        if (!lastKpis || !lastKpis[0]) return 0;
        return lastKpis[0].studentUsersCount;
    }, [lastKpis]);

    const studentUsersProgression = useMemo(() => {
        return (
            ((currentStudentUsersCount ?? 0) - (lastStudentUsersCount ?? 0)) /
            (lastStudentUsersCount ?? 1)
        );
    }, [currentStudentUsersCount, lastStudentUsersCount]);

    const currentStudentCartsCount = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.studentCartsCount, 0);
    }, [currentKpis]);

    const lastStudentCartsCount = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.studentCartsCount, 0);
    }, [lastKpis]);

    const studentCartsProgression = useMemo(() => {
        return (
            ((currentStudentCartsCount ?? 0) - (lastStudentCartsCount ?? 0)) /
            (lastStudentCartsCount ?? 1)
        );
    }, [currentStudentCartsCount, lastStudentCartsCount]);

    const currentStudentCartsCa = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.studentCartsCa, 0);
    }, [currentKpis]);

    const lastStudentCartsCa = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.studentCartsCa, 0);
    }, [lastKpis]);

    const studentCartsCaProgression = useMemo(() => {
        return (
            ((currentStudentCartsCa ?? 0) - (lastStudentCartsCa ?? 0)) / (lastStudentCartsCa ?? 1)
        );
    }, [currentStudentCartsCa, lastStudentCartsCa]);

    const currentStudentAmount = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.studentAmount, 0);
    }, [currentKpis]);

    const lastStudentAmount = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.studentAmount, 0);
    }, [lastKpis]);

    const studentAmountProgression = useMemo(() => {
        return ((currentStudentAmount ?? 0) - (lastStudentAmount ?? 0)) / (lastStudentAmount ?? 1);
    }, [currentStudentAmount, lastStudentAmount]);

    // ------------------- Works Concils DATA ------------------- //

    const currentWorksConcilCa = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.wcCa, 0);
    }, [currentKpis]);

    const lastWorksConcilCa = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.wcCa, 0);
    }, [lastKpis]);

    const worksConcilCaProgression = useMemo(() => {
        return ((currentWorksConcilCa ?? 0) - (lastWorksConcilCa ?? 0)) / (lastWorksConcilCa ?? 1);
    }, [currentWorksConcilCa, lastWorksConcilCa]);

    const currentWorksConcilCartsCount = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.wcCartsCount, 0);
    }, [currentKpis]);

    const lastWorksConcilCartsCount = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.wcCartsCount, 0);
    }, [lastKpis]);

    const worksConcilCartsProgression = useMemo(() => {
        return (
            ((currentWorksConcilCartsCount ?? 0) - (lastWorksConcilCartsCount ?? 0)) /
            (lastWorksConcilCartsCount ?? 1)
        );
    }, [currentWorksConcilCartsCount, lastWorksConcilCartsCount]);

    const currentWorksConcilGozokiAmount = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.wcGozokiAmount, 0);
    }, [currentKpis]);

    const lastWorksConcilGozokiAmount = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.wcGozokiAmount, 0);
    }, [lastKpis]);

    const worksConcilGozokiAmountProgression = useMemo(() => {
        return (
            ((currentWorksConcilGozokiAmount ?? 0) - (lastWorksConcilGozokiAmount ?? 0)) /
            (lastWorksConcilGozokiAmount ?? 1)
        );
    }, [currentWorksConcilGozokiAmount, lastWorksConcilGozokiAmount]);

    const currentWorksConcilCompanyAmount = useMemo(() => {
        if (!currentKpis) return 0;
        return currentKpis.reduce((acc, kpi) => acc + kpi.wcCompanyAmount, 0);
    }, [currentKpis]);

    const lastWorksConcilCompanyAmount = useMemo(() => {
        if (!lastKpis) return 0;
        return lastKpis.reduce((acc, kpi) => acc + kpi.wcCompanyAmount, 0);
    }, [lastKpis]);

    const worksConcilCompanyAmountProgression = useMemo(() => {
        return (
            ((currentWorksConcilCompanyAmount ?? 0) - (lastWorksConcilCompanyAmount ?? 0)) /
            (lastWorksConcilCompanyAmount ?? 1)
        );
    }, [currentWorksConcilCompanyAmount, lastWorksConcilCompanyAmount]);

    // ---------------- HANDLE EXPORT ---------------- //
    const [showModal, setShowModal] = useState(false);

    const handleExportAudit = useCallback(() => {
        setShowModal(true);
    }, []);

    const launchDownload = useCallback(
        (preset: number) => {
            // copy of dates to avoid side effects
            let rangeStart = new Date(currentDateStart);
            let rangeEnd = new Date(currentDateEnd);
            const now = new Date();
            // switch case used to transform start / end dates if needed
            switch (preset) {
                case TIME_RANGE_PRESET.LAST_WEEK: {
                    const dayOfWeek = now.getDay(); // 0 is Sunday, 1 is Monday, etc.
                    const diff = (dayOfWeek < 1 ? -6 : 1) - dayOfWeek; // if it's Sunday we subtract 6 days, otherwise we subtract the current dayOfWeek minus 1

                    const lastMonday = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate() + diff
                    );
                    const lastLastMonday = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate() + diff - 7
                    );

                    rangeStart = lastLastMonday;
                    rangeEnd = lastMonday;
                    break;
                }
                case TIME_RANGE_PRESET.CURRENT_WEEK:
                    // set rangeStart to monday
                    rangeStart = new Date(now.setDate(now.getDate() - now.getDay() + 1));
                    // set rangeEnd to sunday
                    rangeEnd = new Date(now.setDate(now.getDate() + 6));
                    break;
                default: // Untouched / anything else not implemented
                    break;
            }

            handleDownloadAuditFile(
                `${rangeStart.getFullYear()}-${String(rangeStart.getMonth() + 1).padStart(
                    2,
                    '0'
                )}-${String(rangeStart.getDate()).padStart(2, '0')}`,
                rangeEnd.toISOString().split('T')[0]
            );
        },
        [currentDateStart, currentDateEnd, handleDownloadAuditFile]
    );

    const keepDatesAndExport = useCallback(() => {
        setShowModal(false);
        launchDownload(TIME_RANGE_PRESET.KEEP_UNTOUCHED);
    }, [launchDownload]);

    const setLastWeekAndExport = useCallback(() => {
        setShowModal(false);
        launchDownload(TIME_RANGE_PRESET.LAST_WEEK);
    }, [launchDownload]);

    const setCurrentWeekAndExport = useCallback(() => {
        setShowModal(false);
        launchDownload(TIME_RANGE_PRESET.CURRENT_WEEK);
    }, [launchDownload]);

    // ---------------- HANDLE WEEKLY EXPORT ---------------- //
    const [showWeeklyModal, setShowWeeklyModal] = useState(false);

    const handleWeeklyExportAudit = useCallback(() => {
        setShowWeeklyModal(true);
    }, []);

    const launchWeeklyDownload = useCallback(
        (preset: number) => {
            // copy of dates to avoid side effects
            let rangeStart = new Date(currentDateStart);
            let rangeEnd = new Date(currentDateEnd);
            const now = new Date();
            // switch case used to transform start / end dates if needed
            switch (preset) {
                case TIME_RANGE_PRESET.LAST_WEEK: {
                    const dayOfWeek = now.getDay();

                    const lastSunday = new Date(now);
                    lastSunday.setDate(now.getDate() - dayOfWeek - 1);

                    const lastMonday = new Date(now);
                    lastMonday.setDate(now.getDate() - dayOfWeek - 6);

                    rangeStart = lastMonday;
                    rangeEnd = lastSunday;

                    break;
                }
                case TIME_RANGE_PRESET.CURRENT_WEEK: {
                    // set rangeStart to monday
                    rangeStart = new Date(now.setDate(now.getDate() - now.getDay() + 1));
                    // set rangeEnd to sunday
                    rangeEnd = new Date(now.setDate(now.getDate() + 6));

                    break;
                }
                default: // Untouched / anything else not implemented
                    break;
            }
            handleDownloadWeeklyAuditFile(
                rangeStart.toISOString().split('T')[0],
                rangeEnd.toISOString().split('T')[0],
                currentStoreId ?? undefined
            );
        },
        [currentDateEnd, currentDateStart, currentStoreId, handleDownloadWeeklyAuditFile]
    );

    const keepDatesAndExportWeekly = useCallback(() => {
        setShowWeeklyModal(false);
        launchWeeklyDownload(TIME_RANGE_PRESET.KEEP_UNTOUCHED);
    }, [launchWeeklyDownload]);

    const setLastWeekAndExportWeekly = useCallback(() => {
        setShowWeeklyModal(false);
        launchWeeklyDownload(TIME_RANGE_PRESET.LAST_WEEK);
    }, [launchWeeklyDownload]);

    const setCurrentWeekAndExportWeekly = useCallback(() => {
        setShowWeeklyModal(false);
        launchWeeklyDownload(TIME_RANGE_PRESET.CURRENT_WEEK);
    }, [launchWeeklyDownload]);

    // -------------------  RENDER ----------------- //

    return (
        <>
            <Toast />
            <DialogSelectDates
                showModal={showModal}
                setShowModal={setShowModal}
                keepDatesAndExport={keepDatesAndExport}
                setLastWeekAndExport={setLastWeekAndExport}
                setCurrentWeekAndExport={setCurrentWeekAndExport}
            />
            <DialogSelectDates
                showModal={showWeeklyModal}
                setShowModal={setShowWeeklyModal}
                keepDatesAndExport={keepDatesAndExportWeekly}
                setLastWeekAndExport={setLastWeekAndExportWeekly}
                setCurrentWeekAndExport={setCurrentWeekAndExportWeekly}
            />
            <Page sx={fonts.roboto}>
                <div style={{ display: 'flex', paddingLeft: 20 }}>
                    <Typography style={fonts.pageTitle} flexGrow={1}>
                        Dashboard Lucie
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', padding: 20 }}>
                    <FourDatePicker dateState={dateState} />
                    <StoresPicker onlyBoutiques setCurrentStores={setCurrentStores} />
                </div>
                {currentStoreName ? (
                    <div className={styles.smallWhiteCards}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p className={styles.storeLabel}>{currentStoreName}</p>
                            </div>
                            {isStockReader ? (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => {
                                            navigate(`/stores/store_details/${currentStores[0]}`);
                                        }}
                                    >
                                        Visualiser les stocks
                                    </Button>
                                    {kiosks?.map((kiosk) => (
                                        <ConfirmButton
                                            buttonText={`reboot ${kiosk.name}`}
                                            buttonProps={{
                                                variant: 'outlined',
                                                sx: {
                                                    marginRight: '16px',
                                                    height: '42px',
                                                    ...ButtonOverrides.redOutlined,
                                                },
                                                disabled: isRebooting,
                                            }}
                                            description={`Etes-vous sur de vouloir redémarrer cette borne: ${kiosk.name} ? cette opération peut prendre quelques minutes`}
                                            onConfirm={() => {
                                                return handleRebootKiosk(kiosk.id);
                                            }}
                                            title="Redémarer une borne CPI ?"
                                            confirmButton={
                                                isRebooting
                                                    ? {
                                                          loading: true,
                                                          text: 'Redémarage en cours    ',
                                                      }
                                                    : { text: 'Redémarer' }
                                            }
                                        />
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {currentTimeRange !== 1 ? (
                    <Typography
                        sx={{ paddingLeft: 2 }}
                        style={fonts.informationSubText}
                        flexGrow={1}
                    >
                        {`Chiffres du ${currentDateStart.toLocaleDateString(
                            'fr-FR'
                        )} au ${currentDateEnd.toLocaleDateString('fr-FR')} - `}
                        {`Comparaison avec les chiffres du ${lastDateStart.toLocaleDateString(
                            'fr-FR'
                        )} au ${lastDateEnd.toLocaleDateString('fr-FR')}`}
                    </Typography>
                ) : (
                    <Typography
                        sx={{ paddingLeft: 2 }}
                        style={fonts.informationSubText}
                        flexGrow={1}
                    >
                        {`Chiffres du ${currentDateEnd.toLocaleDateString(
                            'fr-FR'
                        )} - Comparaison avec les chiffres du ${lastDateEnd.toLocaleDateString(
                            'fr-FR'
                        )}`}
                    </Typography>
                )}

                <div style={{ display: 'flex' }}>
                    <DashboardCard
                        label="CA total"
                        bigValue={`${frenchFormat(caTtc)}\u00A0€ TTC`}
                        smallValue={`${frenchFormat(caHt)}\u00A0€ HT`}
                        percent={caProgression}
                        orange
                    />

                    <DashboardCard
                        label="CA anti-gaspi"
                        bigValue={`${frenchFormat(caAntiGaspi)}\u00A0€ TTC`}
                        smallValue={`soit ${frenchFormat(
                            ((caAntiGaspi ?? 0) / (caTtc ?? 1)) * 100
                        )} % du CA total`}
                        percent={caAntiGaspiProgression}
                    />

                    <DashboardCard
                        label="Marge brute"
                        bigValue={`${frenchFormat(margeBrute)}\u00A0€`}
                        smallValue={`Soit ${frenchFormat(tauxMargeBrute)} % du CA`}
                        percent={progressionMargeBrute}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    {isStockReader ? (
                        <DashboardCard
                            label="Valeur actuelle du stock"
                            bigValue={`${frenchFormat(valeurStock.value)}\u00A0€`}
                            smallValue={`dont anti-gaspi : ${frenchFormat(
                                valeurStock.antiGaspiValue
                            )} €`}
                        />
                    ) : null}

                    <DashboardCard
                        label="Produits vendus"
                        bigValue={`${frenchFormat(produitsVendus)}`}
                        percent={progressionProduitsVendus}
                    />
                    <DashboardCard
                        label="Panier moyen"
                        bigValue={`${frenchFormat(panierMoyen)}\u00A0€`}
                        percent={progressionPanierMoyen}
                    />
                    <DashboardCard
                        label="Achats uniques"
                        bigValue={`${frenchFormat(numberOfCarts)}`}
                        percent={progressionNumberOfCarts}
                    />
                </div>
                {chartData && chartOptions ? (
                    <div className={styles.smallWhiteCards}>
                        <Line data={chartData} options={chartOptions} />
                    </div>
                ) : null}
                <div style={{ paddingBottom: 20, paddingTop: 10 }}>
                    <Typography sx={{ paddingLeft: 2 }} style={fonts.pageSubtitle}>
                        Utilisation de l'application
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        <DashboardCard
                            label="Nombre d'utilisateurs"
                            bigValue={`${frenchFormat(currentAppUsers)}`}
                            percent={appUsersProgression}
                        />
                        <DashboardCard
                            label="Utilisateurs actifs sur la période"
                            bigValue={`${frenchFormat(currentAppActiveUsers)}`}
                            percent={appActiveUsersProgression}
                        />
                        <DashboardCard
                            label="Nombre d'achats par l'app"
                            bigValue={`${frenchFormat(currentAppCartCount)}`}
                            smallValue={`Soit ${frenchFormat(appCartPercent)} % des achats totaux`}
                            percent={appCartCountProgression}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <DashboardCard
                            label="Nouveaux utilisateurs"
                            bigValue={`${frenchFormat(currentNewUsers)}`}
                            percent={newUsersProgression}
                        />
                        <DashboardCard
                            label="Utilisateurs supprimés"
                            bigValue={`${frenchFormat(currentDeletedUsers)}`}
                            percent={deletedUsersProgression}
                            negativePercent
                        />
                        <DashboardCard
                            label="Utilisateurs activés"
                            bigValue={`${frenchFormat(currentActivatedUsers)}`}
                            percent={activatedUsersProgression}
                        />
                    </div>
                </div>
                <div style={{ paddingBottom: 20, paddingTop: 10 }}>
                    <Typography sx={{ paddingLeft: 2 }} style={fonts.pageSubtitle}>
                        Comptes étudiants
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        <DashboardCard
                            label="Nombre d'étudiants validés"
                            bigValue={`${frenchFormat(currentStudentUsersCount)}`}
                            percent={studentUsersProgression}
                        />
                        <DashboardCard
                            label="Nombre de paniers générés"
                            bigValue={`${frenchFormat(currentStudentCartsCount)}`}
                            percent={studentCartsProgression}
                        />
                        <DashboardCard
                            label="CA généré"
                            bigValue={`${frenchFormat(currentStudentCartsCa)}\u00A0€`}
                            percent={studentCartsCaProgression}
                        />
                        <DashboardCard
                            label="Montant utilisé"
                            bigValue={`${frenchFormat(currentStudentAmount)}\u00A0€`}
                            percent={studentAmountProgression}
                        />
                    </div>
                </div>
                {currentWorksConcilCa > 0 ? (
                    <div style={{ paddingBottom: 20, paddingTop: 10 }}>
                        <Typography sx={{ paddingLeft: 2 }} style={fonts.pageSubtitle}>
                            Comités d'entreprise
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <DashboardCard
                                label="Chiffre d'affaires généré"
                                bigValue={`${frenchFormat(currentWorksConcilCa)}\u00A0€`}
                                percent={worksConcilCaProgression}
                            />
                            <DashboardCard
                                label="Nombre de paniers générés"
                                bigValue={`${frenchFormat(currentWorksConcilCartsCount)}`}
                                percent={worksConcilCartsProgression}
                            />
                            <DashboardCard
                                label="Montant Gozoki"
                                bigValue={`${frenchFormat(currentWorksConcilGozokiAmount)}\u00A0€`}
                                percent={worksConcilGozokiAmountProgression}
                            />
                            <DashboardCard
                                label="Montant entreprise"
                                bigValue={`${frenchFormat(currentWorksConcilCompanyAmount)}\u00A0€`}
                                percent={worksConcilCompanyAmountProgression}
                            />
                        </div>
                    </div>
                ) : null}
                {promoUSed && promoUSed.length > 0 ? (
                    <>
                        {promoUSed.map((promo) => {
                            const lastPromo = lastPromoUsed?.filter(
                                (p) => p.promoCampaignLabel === promo.promoCampaignLabel
                            )[0];
                            return (
                                <div style={{ paddingBottom: 20, paddingTop: 10 }}>
                                    <Typography sx={{ paddingLeft: 2 }} style={fonts.pageSubtitle}>
                                        Utilisation de la campagne : {promo.promoCampaignLabel}
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        <DashboardCard
                                            label="Ajouts sur l'app"
                                            bigValue={`${frenchFormat(promo.addedCount)}`}
                                            percent={
                                                lastPromo
                                                    ? (promo.addedCount - lastPromo.addedCount) /
                                                      (lastPromo.addedCount ?? 1)
                                                    : 1
                                            }
                                        />
                                        <DashboardCard
                                            label="Nombre d'utilisations"
                                            bigValue={`${frenchFormat(promo.usedCount)}`}
                                            percent={
                                                lastPromo
                                                    ? (promo.usedCount - lastPromo.usedCount) /
                                                      (lastPromo.usedCount ?? 1)
                                                    : 1
                                            }
                                        />
                                        <DashboardCard
                                            label="CA généré"
                                            bigValue={`${frenchFormat(promo.ca)} €`}
                                            percent={
                                                lastPromo
                                                    ? (promo.ca - lastPromo.ca) /
                                                      (lastPromo.ca ?? 1)
                                                    : 1
                                            }
                                            smallValue={`Soit ${frenchFormat(
                                                (promo.ca / caTtc) * 100
                                            )}\u00A0% du CA total`}
                                        />
                                        <DashboardCard
                                            label="Montant utilisé"
                                            bigValue={`${frenchFormat(promo.usedAmount)} €`}
                                            percent={
                                                lastPromo
                                                    ? (promo.usedAmount - lastPromo.usedAmount) /
                                                      (lastPromo.usedAmount ?? 1)
                                                    : 1
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </>
                ) : null}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={styles.smallWhiteCards}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <p className={styles.famousThingstitle}>Produits les plus vendus</p>
                            <ToggleButtonGroup
                                color="primary"
                                value={productMode}
                                exclusive
                                onChange={(_, value) => setProductMode(value)}
                            >
                                <ToggleButton style={{ padding: 5 }} value="count">
                                    Par quantité
                                </ToggleButton>
                                <ToggleButton style={{ padding: 5 }} value="ca">
                                    Par CA
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div style={{ maxHeight: 950, overflow: 'auto' }}>
                            {famousProductsData
                                ?.slice(0, seeAllProducts ? undefined : 10)
                                .map((product) => (
                                    <ProductCard
                                        key={product.id}
                                        product={product}
                                        count={product.count}
                                        ca={product.ca}
                                        mode={productMode}
                                        maxCount={famousProductsData[0].count}
                                        maxCa={famousProductsData[0].ca}
                                        totalCount={produitsVendus}
                                        totalCa={caTtc}
                                    />
                                ))}
                            {!seeAllProducts ? (
                                <p style={{ textAlign: 'center' }}>
                                    <Button onClick={() => setSeeAllProducts(true)}>
                                        Charger tout
                                    </Button>
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className={styles.smallWhiteCards}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <p className={styles.famousThingstitle}>Catégories les plus vendues</p>
                            <ToggleButtonGroup
                                color="primary"
                                value={categoryMode}
                                exclusive
                                onChange={(_, value) => setCategoryMode(value)}
                            >
                                <ToggleButton style={{ padding: 5 }} value="count">
                                    Par quantité
                                </ToggleButton>
                                <ToggleButton style={{ padding: 5 }} value="ca">
                                    Par CA
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div style={{ maxHeight: 950, overflow: 'auto' }}>
                            {famousCategoriesData
                                ?.slice(0, seeAllCategories ? undefined : 10)
                                .map((category) => (
                                    <CategoryCard
                                        key={category.id}
                                        category={category}
                                        count={category.count}
                                        ca={category.ca}
                                        mode={categoryMode}
                                        maxCount={famousCategoriesData[0].count}
                                        maxCa={famousCategoriesData[0].ca}
                                        totalCount={produitsVendus}
                                        totalCa={caTtc}
                                    />
                                ))}
                            {!seeAllCategories ? (
                                <p style={{ textAlign: 'center' }}>
                                    <Button onClick={() => setSeeAllCategories(true)}>
                                        Charger tout
                                    </Button>
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 8,
                        gap: 16,
                        flexWrap: 'wrap',
                    }}
                >
                    <Button variant="contained" onClick={handleExport} disabled={isLoading}>
                        Exporter les données sur la période sélectionnée
                    </Button>
                    {isAccountingReader ? (
                        <Button
                            variant="contained"
                            color="info"
                            onClick={handleExportAudit}
                            disabled={isLoading}
                        >
                            Exporter les données approfondies sur la période sélectionnée
                        </Button>
                    ) : null}
                    {isAccountingReader ? (
                        <Button
                            variant="contained"
                            color="info"
                            onClick={() => handleWeeklyExportAudit()}
                            disabled={isLoadingWeeklyAudit}
                        >
                            Exporter les données hebdomadaires
                        </Button>
                    ) : null}
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={navigateToHoursPage}
                        disabled={isLoading}
                    >
                        Voir les données temporelles
                    </Button>
                </div>
            </Page>
        </>
    );
};

export default DashboardPage;
