import { Category } from '@gozoki/api-types';
import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_BUILDER } from '../../utils/constants/routes.constants';

type CellProps = ComponentProps<NonNullable<GridColDef['renderCell']>>;

export const SubCategoriesCell = ({ value }: CellProps) => {
    const subCategories = value as Category[];

    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            {subCategories.map((category) => (
                <Link key={category.id} to={ROUTE_BUILDER.updateSubCategory(category.id)}>
                    <Chip style={{ cursor: 'pointer' }} label={category.name} />
                </Link>
            ))}
        </Box>
    );
};
