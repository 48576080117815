const transformValue = (valueRaw: string): string => {
    let value = valueRaw;

    // Remove the € sign
    if (value.at(-1) === '€') {
        value = value.slice(0, -1);
    }
    // Replace the comma by a dot
    if (/^\d+,\d+$/.test(value)) {
        value = value.replace(',', '.');
    }
    // remove extra 0 at the end of the number
    // 1.0000 => 1
    // 1.1000 => 1.1
    if (/^\d+\.\d+0+$/.test(value)) {
        while (value.at(-1) === '0') {
            value = value.slice(0, -1);
        }
        if (value.at(-1) === '.') {
            value = value.slice(0, -1);
        }
    }

    return value;
};

export const excelParseValue = (valueRaw: string): string | number => {
    // If the value too long, we return it as is (EAN code for example)
    if (valueRaw.length > 10) return valueRaw;

    const value = transformValue(valueRaw);

    try {
        const numberValue = +value;
        const numberValueString = numberValue.toString();

        if (value === numberValueString) {
            return numberValue;
        }

        return value;
    } catch (error) {
        return value;
    }
};
