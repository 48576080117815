import { Right } from '@gozoki/tools';
import { User } from '@gozoki/api-types';
import { createContext } from 'react';

export interface AuthContextType {
    authenticated: boolean;
    user: User | null;
    refetchUser: () => Promise<unknown>;
    isLoading: boolean;
    logout: () => Promise<void>;
    hasRights: (rights: Right[]) => boolean;
}

export const AuthContext = createContext<AuthContextType>({
    authenticated: false,
    user: null,
    isLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    refetchUser: async () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: async () => {},
    hasRights: () => false,
});
