import { Outlet, useParams } from 'react-router-dom';
import {
    WorksConcilForm,
    fromExistingWorksConcil,
    useWorksConcil,
    useWorksConcilForm,
} from '@gozoki/api';
import { useCallback, useEffect } from 'react';

import { User } from '@gozoki/api-types';

export interface EntrepriseOutletContext {
    worksConcilForm: ReturnType<typeof useWorksConcilForm>;

    updateMode: boolean;
    isUpdateModeLoading: boolean;

    worksConcilId: number;
    currentEmployeesEmails: string[];
    currentEmployeesUsers: User[];

    fillForm: (data: WorksConcilForm) => void;
}

const EntrepriseOutletProvider = () => {
    const { id } = useParams(); // Is "undefined" if adding a product, is a number if updating an existing product

    const { data: worksConcil, isLoading: isLoadingWorksConcil } = useWorksConcil(
        parseInt(id ?? '', 10)
    );

    const worksConcilForm = useWorksConcilForm();

    // Soft reset the form. Calling reset() with args will permanently change the default values,
    // which is not what we want when initializing an update form from existing data
    const fillForm = useCallback(
        (data: WorksConcilForm) => {
            Object.entries(data).forEach(([fieldName, value]) => {
                worksConcilForm.setValue(fieldName as keyof WorksConcilForm, value);
            });
        },
        [worksConcilForm]
    );

    // If update mode, prefill the form once existing data arrives
    useEffect(() => {
        // Execute an async function inside useEffect
        const fillFormWithExistingWorksConcil = async () => {
            if (worksConcil) {
                fillForm(await fromExistingWorksConcil(worksConcil));
            }
        };

        fillFormWithExistingWorksConcil();
    }, [id, fillForm, worksConcil]);

    return (
        <Outlet
            context={{
                // Form data
                worksConcilForm,
                fillForm,
                currentEmployeesEmails: worksConcil?.emails,
                currentEmployeesUsers: worksConcil?.employees,

                // Create vs Modify product
                updateMode: id !== undefined,
                worksConcilId: parseInt(id ?? '', 10),
                isUpdateModeLoading: id !== undefined && isLoadingWorksConcil,
            }}
        />
    );
};

export default EntrepriseOutletProvider;
