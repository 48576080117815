import { TokenResponse, UserReponse } from '../types/axios.types';

import { gozokiApiWithAuth } from '../gozokiApi';

// Request an authentication token with credentials
export const login = async ({ email, password }: { email: string; password: string }) => {
    const response = await gozokiApiWithAuth.post<TokenResponse>('/auth/login', {
        email,
        password,
    });
    return response.data;
};

// Fetch current user with authentication
export const me = async () => {
    const response = await gozokiApiWithAuth.get<UserReponse>('/me');
    return response.data;
};
