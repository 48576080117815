import { fetchBrand, fetchBrands } from '../queries/brands.queries';

import { useQuery } from '@tanstack/react-query';

export const useBrands = () => {
    return useQuery(['brands'], fetchBrands);
};

export const useBrand = (id: number) => {
    return useQuery(
        ['brand', id],
        () => {
            if (Number.isNaN(id)) return null;
            return fetchBrand(id);
        },
        {
            staleTime: 24 * 60 * 60 * 1000, // 86400000 millisecondes (a day)
        }
    );
};
