import { MenuItem } from '@mui/material';

export type OptionValue = string | number | readonly string[] | readonly number[] | undefined;
export type IndexOptionValue = string | number;

export const isIndexOptionValue = (value: unknown): value is IndexOptionValue => {
    return typeof value === 'string' || typeof value === 'number' || typeof value === 'symbol';
};
export interface MenuOption {
    label: string;
    value: OptionValue;
    isHelper?: boolean;
}

const menuOptions = (options: MenuOption[]) => {
    return options.map((option, index) => {
        let value: string | number | readonly string[] | undefined;
        if (
            typeof option.value === 'object' &&
            option.value !== null &&
            !Array.isArray(option.value)
        ) {
            value = option.value.map((v) => v.toString());
        } else {
            value = option.value;
        }
        return (
            <MenuItem key={`option-${index}`} value={value}>
                {option.label}
            </MenuItem>
        );
    });
};

export default menuOptions;
