import {
    fetchAppUsers,
    fetchBoUsers,
    fetchPaymentDefaultUsers,
    fetchStudentUsers,
    fetchUser,
    getPushTokenStatus,
} from '../queries/users.queries';

import { useQuery } from '@tanstack/react-query';

export const useAppUsers = () => {
    return useQuery(['app-users'], fetchAppUsers);
};

export const useBoUsers = () => {
    return useQuery(['bo-users'], fetchBoUsers);
};

export const useUser = (userId: number) => {
    return useQuery(['user', userId], () => fetchUser(userId));
};

export const usePaymentDefaultUsers = () => {
    return useQuery(['payment-default-users'], fetchPaymentDefaultUsers);
};

export const useStudentUsers = () => {
    return useQuery(['student-users'], fetchStudentUsers);
};

export const usePushTokenStatus = () => {
    return useQuery<boolean>(['push-token-status'], async () => {
        const data = await getPushTokenStatus();
        return data;
    });
};
