import {
    fetchActivesAndUsedCategories,
    fetchCategories,
    fetchCategory,
    fetchMultipleCategories,
} from '../queries/categories.queries';

import { useQuery } from '@tanstack/react-query';

export const useCategories = () => {
    return useQuery(['categories'], fetchCategories);
};

export const useActivesAndUsedCategories = (storeId: number) => {
    return useQuery(['activesAndUsedCategories', storeId], () => {
        if (Number.isNaN(storeId)) return [];
        return fetchActivesAndUsedCategories(storeId);
    });
};

export const useCategory = (id: number) => {
    return useQuery(['category', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchCategory(id);
    });
};

export const useMultipleCategories = (ids: number[]) => {
    return useQuery(['multipleCategories', ids], () => {
        if (ids.length === 0) return [];
        return fetchMultipleCategories(ids);
    });
};
