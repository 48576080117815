import { destroyBrands, useBrands } from '@gozoki/api';
import { includesLowerCase } from '@gozoki/tools';
import { Button, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import Thumbnail from '../../components/icons/Thumbnail';
import { ROUTE_BUILDER } from '../../utils/constants/routes.constants';

const marquesColDef: GridColDef[] = [
    {
        field: 'image',
        headerName: 'Image',
        width: 150,
        renderCell: (url) => <Thumbnail url={url.value} alt="Marque" rounded />,
    },
    { field: 'name', headerName: 'Marque', width: 300 },
];

export const BrandsPanel = () => {
    const navigate = useNavigate();

    const [brandsSelection, setBrandsSelection] = useState<number[]>([]);
    const [brandsSearch, setBrandsSearch] = useState('');

    const brands = useBrands();

    // Transform fetched brands into DataGrid format
    const brandsRows = useMemo(() => {
        if (brands.data === undefined) {
            return [];
        }
        return brands.data
            .filter((brand) => includesLowerCase(brand.name, brandsSearch))
            .map((brand) => ({
                id: brand.id,
                name: brand.name,
                image: brand.image?.url,
            }));
    }, [brands.data, brandsSearch]);

    const deleteBrandMut = useMutation(destroyBrands);

    const handleDeleteBrand = () => {
        const deletionPromise = deleteBrandMut.mutateAsync(brandsSelection, {
            onSuccess: () => {
                brands.refetch();
            },
        });
        setBrandsSelection([]); // Avoid disabled button flickering
        return deletionPromise;
    };

    const navigateAndUpdateBrand = () => {
        navigate(ROUTE_BUILDER.updateBrand(brandsSelection[0]));
    };

    const brandsDisabled = brandsSelection.length === 0 || deleteBrandMut.isLoading;

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                <TextField
                    variant="outlined"
                    label="Recherche"
                    value={brandsSearch}
                    onChange={(e) => setBrandsSearch(e.target.value)}
                />
                <div style={{ flexGrow: 1 }} />
                <ConfirmResourceDeletionButton
                    handleResourceDeletion={handleDeleteBrand}
                    disabled={brandsDisabled}
                />
                <Button
                    variant="contained"
                    sx={{ height: '36px' }}
                    disabled={brandsDisabled}
                    onClick={navigateAndUpdateBrand}
                >
                    Modifier
                </Button>
            </div>
            <SimpleDataGrid
                rowHeight={100} // For image display
                columns={marquesColDef}
                rows={brandsRows}
                checkboxSelection
                loading={brands.isLoading || deleteBrandMut.isLoading || brands.isRefetching}
                onRowSelectionModelChange={(newSelection) =>
                    setBrandsSelection(newSelection as number[])
                }
            />
        </>
    );
};
