import { excludeFields, isNumber, parseFrenchFloat, shallowStringigy } from '@gozoki/tools';

import { DateTime } from 'luxon';
import { WorksConcil } from '@gozoki/api-types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const zWorksConcilForm = z.object({
    // works concil label
    label: z
        .string()
        .min(3, 'Le libellé du partenatiat doit faire au minimum 3 caractères')
        .max(255, 'Le libellé du partenatiat doit faire au maximum 255 caractères'),

    companyLabel: z.string().nonempty({ message: "Le nom de l'entreprise est requis" }),
    companyCity: z.string().nonempty({ message: "La ville de l'entreprise est requise" }),
    companyAddress: z.string().nullable(),
    companySiren: z.string().nonempty({ message: "Le SIREN de l'entreprise est requis" }),

    // works concil financial informations
    maxMonthAmountPerEmployee: z
        .string()
        .refine(isNumber)
        .transform(parseFrenchFloat)
        .default('0')
        .nullable(),
    gozokiPercentage: z
        .string()
        .refine(isNumber)
        .transform(parseFrenchFloat)
        .default('0')
        .nullable(),
    worksConcilPercentage: z
        .string()
        .refine(isNumber)
        .transform(parseFrenchFloat)
        .default('0')
        .nullable(),

    // works concil dates
    startDate: z.string().nonempty({ message: 'La date de début est requise' }),
    endDate: z.string().nonempty({ message: 'La date de fin est requise' }),

    // works concil users
    employees: z.array(z.number()).nullable().default([]),
    emails: z.array(z.string().email()).nullable().default([]),
    emailsToAdd: z.array(z.string().email()).nullable().default([]),

    // works concil status
    isActive: z.boolean().default(false),
});

export type WorksConcilForm = z.infer<typeof zWorksConcilForm>;

// Form hook
export const useWorksConcilForm = (defaultValues?: Partial<WorksConcilForm>) => {
    const form = useForm<WorksConcilForm>({
        // Validation function
        resolver: zodResolver(zWorksConcilForm),
        defaultValues: {
            companyLabel: '',
            companyCity: '',
            companyAddress: '',
            companySiren: '',
            startDate: DateTime.now().toISODate() as string,
            employees: [],
            isActive: false,
            ...defaultValues,
        },
    });

    return form;
};

// Helper : generate precompleted form from existing product data for modification
export const fromExistingWorksConcil = async (
    worksConcil: WorksConcil
): Promise<WorksConcilForm> => {
    return shallowStringigy({
        ...excludeFields(worksConcil, ['createdAt', 'updatedAt', 'id']), // Exclude autogenerated fields

        // Unpack works concil informations
        label: worksConcil.label,

        // Unpack company informations
        companyLabel: worksConcil.companyLabel,
        companyCity: worksConcil.companyCity,
        companyAddress: worksConcil.companyAddress,
        companySiren: worksConcil.companySiren,

        // Unpack financial informations
        maxMonthAmountPerEmployee: worksConcil.maxMonthAmountPerEmployee,
        gozokiPercentage: worksConcil.gozokiPercentage,
        worksConcilPercentage: worksConcil.worksConcilPercentage,

        // Unpack dates
        startDate: worksConcil.startDate,
        endDate: worksConcil.endDate,

        // Unpack users
        employees: worksConcil.employees.map((employee) => employee.id),
        emails: worksConcil.emails,
        emailsToAdd: null,
    });
};
