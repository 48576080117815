export const excludeFields = <T extends object, K extends keyof T>(
    obj: T,
    fields: K[]
): Omit<T, K> => {
    const result = { ...obj };

    fields.forEach((field) => {
        delete result[field];
    });
    return result;
};

export const pickFields = <T extends object, K extends keyof T>(
    obj: T,
    fields: K[]
): Pick<T, K> => {
    const result = {} as Pick<T, K>;

    fields.forEach((field) => {
        result[field] = obj[field];
    });
    return result;
};

/** Transforms number entries into strings for use in input forms */
export const shallowStringigy = <T extends object>(obj: T): T => {
    const clone = { ...obj };

    Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'number') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            clone[key] = value.toString();
        }
    });
    return clone;
};

/** Returns the intersection of two object lists by matching them against the specified key */
export const intersection = <T extends object>(a: T[], b: T[], key: keyof T): T[] => {
    return a.filter((x) => b.some((y) => x[key] === y[key]));
};

/** Create an index lookup table for an object list, indexed by the given key */
export const indexLookupTable = <T extends object>(
    list: T[],
    key: keyof T
): Record<string, number> => {
    const table: Record<string, number> = {};

    list.forEach((item, index) => {
        table[`${item[key]}`] = index;
    });
    return table;
};

/** Create a lookup table for an object list, indexed by the given key */
export const lookupTable = <T extends object>(list: T[], key: keyof T): Record<string, T> => {
    const table: Record<string, T> = {};

    list.forEach((item) => {
        table[`${item[key]}`] = item;
    });
    return table;
};

/** Create a lookup map for an object list, indexed by the given key */
export const createLookupMap = <
    T extends object,
    U extends keyof T,
    V extends string | number = T[U] extends number ? number : string
>(
    list: T[],
    key: U
): Map<V, T> => {
    const map = new Map(
        list.map((item) => {
            const lookupKey = item[key];

            if (typeof lookupKey === 'number') {
                return [lookupKey, item] as [V, T];
            }

            return [`${lookupKey}`, item] as [V, T];
        })
    );

    return map;
};
