// Internal use of token callback to determine optimized behaviors if the token is not yet available

let tokenCallback: () => Promise<string | null>;

export const setTokenCallback = (callback: () => Promise<string | null>) => {
    tokenCallback = callback;
};

export const getToken = async () => {
    return tokenCallback();
};
