import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

// From backend-register : at least 8 characters AND one special char AND one letter
const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const zNewUpdatePasswordForm = z.object({
    currentPassword: z
        .string()
        .min(8, 'Le mot de passe doit contenir au moins 8 caractères.')
        .regex(
            PASSWORD_REGEX,
            'Le mot de passe doit contenir au moins une lettre et un caractère spécial.'
        ),
    newPassword: z
        .string()
        .min(8, 'Le nouveau mot de passe doit contenir au moins 8 caractères.')
        .regex(
            PASSWORD_REGEX,
            'Le nouveau mot de passe doit contenir au moins une lettre et un caractère spécial.'
        ),
});

export type NewUpdatePasswordForm = z.infer<typeof zNewUpdatePasswordForm>;

// Form hook
export const useNewUpdatePasswordForm = () => {
    const form = useForm<NewUpdatePasswordForm>({
        // Validation function
        resolver: zodResolver(zNewUpdatePasswordForm),
        defaultValues: {
            currentPassword: '',
            newPassword: '',
        },
    });

    return form;
};
