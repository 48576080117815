import { Kiosk } from '@gozoki/api-types';
import { gozokiApiWithAuth } from '../gozokiApi';

export const fetchKiosks = async () => {
    const response = await gozokiApiWithAuth.get<Kiosk[]>('/kiosks');
    return response.data;
};

export const fetchKiosk = async (id: number) => {
    const response = await gozokiApiWithAuth.get<Kiosk>(`/kiosks/${id}`);
    return response.data;
};
