import { PASSWORD_REGEX } from '@gozoki/tools';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const zNewSignupForm = z.object({
    // Page 1 - Name
    firstName: z
        .string()
        .min(2, { message: 'Le prénom doit contenir au moins 2 caractères' })
        .max(30, { message: 'Le prénom doit contenir au maximum 30 caractères' }),
    lastName: z
        .string()
        .min(1, { message: 'Le nom de famille doit contenir au moins 1 caractère' })
        .max(30, { message: 'Le nom de famille doit contenir au maximum 30 caractères' }),

    // Page 2 - Email
    email: z
        .string()
        .email({ message: 'Email invalide.' })
        .max(50, { message: 'Email trop long.' }),

    // Page 3 - Password
    password: z.string().regex(PASSWORD_REGEX, {
        message:
            'Le mot de passe doit contenir au moins 8 caractères, une lettre, un chiffre et un caractère spécial.',
    }),

    isStudent: z.boolean(),
    storeId: z.number(),
});

export type NewSignupForm = z.infer<typeof zNewSignupForm>;

// Form hook
export const useNewSignupForm = () => {
    const form = useForm<NewSignupForm>({
        // Validation function
        resolver: zodResolver(zNewSignupForm),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            isStudent: false,
            storeId: 3,
        },
    });

    return form;
};
