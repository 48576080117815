import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { ConfirmButton } from './ConfirmButton';

interface Props {
    disabled?: boolean;
    handleResourceDeletion: () => Promise<unknown>;
}

export const ConfirmResourceDeletionButton = ({ disabled, handleResourceDeletion }: Props) => {
    return (
        <ConfirmButton
            buttonText="Supprimer"
            buttonProps={{
                variant: 'outlined',
                sx: {
                    marginRight: '16px',
                    height: '40px',
                    ...ButtonOverrides.redOutlined,
                },
                disabled,
            }}
            description="Etes-vous sur de vouloir supprimer cette ressource ?"
            onConfirm={handleResourceDeletion}
            title="Supprimer ?"
            confirmButton={{ text: 'Supprimer' }}
        />
    );
};
