import { BrandForm } from '../forms/useBrandForm';
import { gozokiApiWithAuth } from '../gozokiApi';

// Create a brand
export const createBrand = async (data: BrandForm) => {
    const response = await gozokiApiWithAuth.post('/brands', data, {
        headers: {
            // This request contains a file
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};

// Destroy a brand
export const destroyBrand = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/brands/${id}`);
    return response;
};

// Destroy multiple brands
export const destroyBrands = async (ids: number[]) => {
    const responses = await Promise.all(ids.map((id) => destroyBrand(id)));
    return responses;
};

// Update a brand
export const updateBrand = async ({ id, data }: UpdateBrandArgs) => {
    const response = await gozokiApiWithAuth.patch(`/brands/${id}`, data, {
        headers: {
            // This request contains a file
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};

interface UpdateBrandArgs {
    id: number;
    data: Partial<BrandForm>;
}
