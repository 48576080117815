import { createProduct, updateProduct, uploadProductVideo } from '@gozoki/api';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';

import { _assert } from '@gozoki/tools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useToast } from '../../components/communication/Toast';
import LoadingPaper from '../../components/containers/LoadingPaper';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import Page from '../../components/Page';
import { useRights } from '../../utils/hooks';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { ProductOutletContext } from './ProductsOutletProvider';

const AddProductPage5 = () => {
    const navigate = useNavigate();
    const isUserAdmin = useRights(['ADMIN']);

    const { productForm, updateMode, productId, preparationVideo, isUpdateModeLoading } =
        useOutletContext<ProductOutletContext>();

    const { control, reset, trigger, getValues, watch } = productForm;

    const [Toast, showToast] = useToast();

    // ***************************************************** //
    //                     MUTATIONS                         //
    // ***************************************************** //

    const { mutate: update, isLoading: isUpdating } = useMutation(updateProduct, {
        onSuccess: () => {
            handleUploadVideo();

            reset();
            navigate(ROUTES.PRODUCTS);
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    const { mutate: upload, isLoading: isUploading } = useMutation(createProduct, {
        onSuccess: (data) => {
            // Get the created product ID and upload the video to it

            handleUploadVideo(data.data.value);

            reset();
            navigate(ROUTES.PRODUCTS);
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    const { mutate: uploadVideo } = useMutation(uploadProductVideo);

    // ***************************************************** //
    //                      HANDLERS                         //
    // ***************************************************** //

    const cancel = () => {
        reset();
        navigate(ROUTES.PRODUCTS);
    };
    const navigateBack = () => navigate(ROUTE_BUILDER.updateProductThirdStep(productId));

    const validateAndUpload = async () => {
        // Validate this page
        const valid = await trigger('antiGaspi');

        if (valid) {
            // Send data
            upload(getValues());
        }
    };

    const validateAndModify = async () => {
        // Validate this page
        const valid = await trigger('antiGaspi');

        if (valid) {
            // Send data
            update({ id: productId, data: getValues() });
        }
    };

    const handleUploadVideo = (overrideId?: number) => {
        const toUploadId = overrideId ?? productId;

        if (preparationVideo !== undefined) {
            // File is not undefined : something changed
            _assert(toUploadId, 'Trying to upload video but the product id is undefined');

            uploadVideo({ id: toUploadId, video: preparationVideo });
        }
    };

    const storeProductMinimumStock = watch('storeProductMinimumStock');

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <Toast />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode ? 'Modifier un produit' : 'Ajouter un produit'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={updateMode ? validateAndModify : validateAndUpload}
                    disabled={isUserAdmin ? isUploading || isUpdating : true}
                >
                    {updateMode ? 'Modifier' : 'Ajouter'}
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                {storeProductMinimumStock &&
                    storeProductMinimumStock.map((spms, index) => (
                        <React.Fragment key={`${spms.productReference}-${spms.storeReference}`}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                {spms.label}
                            </Typography>
                            <Grid container spacing={4} sx={{ alignItems: 'flex-end' }}>
                                <Grid item flex={1}>
                                    <Typography mb="8px" style={fonts.inputHeader}>
                                        Stock minimum
                                    </Typography>
                                    <TextFieldRhf
                                        variant="outlined"
                                        control={control}
                                        trigger={trigger}
                                        name={`storeProductMinimumStock.${index}.minimumStock`}
                                        placeholder="Stock minimum"
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <Typography mb="8px" style={fonts.inputHeader}>
                                        Stock minimum Week-End
                                    </Typography>
                                    <TextFieldRhf
                                        variant="outlined"
                                        control={control}
                                        trigger={trigger}
                                        name={`storeProductMinimumStock.${index}.weekEndMinimumStock`}
                                        placeholder="Stock minimum"
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
            </LoadingPaper>
        </Page>
    );
};

export default AddProductPage5;
