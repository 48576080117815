import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NOTIFICATIONS_ROUTES } from '../../utils/constants/routes.constants';
import Page from '../../components/Page';
import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { sendNotification, useNotification, useProducts } from '@gozoki/api';
import { fonts } from '../../utils/theme/fonts.theme';
import { useToast } from '../../components/communication/Toast';
import { useMutation } from '@tanstack/react-query';
import { Product } from '@gozoki/api-types';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { ContentCopy } from '@mui/icons-material';

const actions = [
    { value: 'none', label: 'Aucune action' },
    { value: 'anti-gaspi', label: 'Renvoyer vers la page Anti-gaspi' },
    { value: 'product-anti-gaspi', label: 'Renvoyer vers un produit anti-gaspi' },
    { value: 'product', label: 'Renvoyer vers un produit' },
];

const CreateNotificationPage1 = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { data: products } = useProducts();

    const { id } = useParams();
    const viewMode = location.pathname.includes('view_notification');

    const duplicateMode = id !== undefined && !viewMode; // If an ID is provided, we are in duplicate mode
    const { data: notification, isLoading } = useNotification(parseInt(id ?? '', 10));

    const [Toast, showToast] = useToast();
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState<string | null>(null);
    const [onlyUsers, setOnlyUsers] = useState(false);
    const [shopIds, setShopIds] = useState<number[]>([]);
    const [action, setAction] = useState<(typeof actions)[0]>(actions[0]);
    const [product, setProduct] = useState<Product | null>(null);
    const [sending, setSending] = useState(false);

    const cancel = useCallback(() => {
        // reset();
        navigate(NOTIFICATIONS_ROUTES.NOTIFICATION);
    }, [navigate]);
    const navigateBack = cancel;

    useEffect(() => {
        if ((duplicateMode || viewMode) && notification) {
            setTitle(notification.title);
            setSubTitle(notification.subTitle);
            setMessage(notification.text);
            setOnlyUsers(notification.onlyUsers);
            setShopIds(notification.shopIds);
            // if no action was specified, don't bother setting it
            if (!notification.data?.path) return;

            // find the action that matches the notification path
            let productId: number | null = null;
            if (notification.data.path.startsWith('anti-gaspi/')) {
                setAction(actions[2]);
                productId = parseInt(notification.data.path.split('/')[1], 10);
            } else if (notification.data.path === 'anti-gaspi') {
                setAction(actions[1]);
            } else if (notification.data.path === 'product') {
                setAction(actions[3]);
                productId = parseInt(notification.data.path.split('/')[1], 10);
            }
            if (productId && products) {
                setProduct(products.find((p) => p.id === productId) ?? null);
            }
        }
    }, [notification, isLoading, duplicateMode, products, viewMode]);

    const path = useMemo(() => {
        if (action.value === 'product-anti-gaspi' && product) {
            return `anti-gaspi/${product.id}`;
        }
        if (action.value === 'anti-gaspi') {
            return 'anti-gaspi';
        }
        if (action.value === 'product' && product) {
            return `produit/${product.id}`;
        }
        return null;
    }, [action, product]);

    const { mutate } = useMutation(
        () =>
            sendNotification({
                title,
                subTitle: subTitle || null,
                text: message,
                onlyUsers,
                shopIds,
                data: {
                    path,
                },
            }),
        {
            onSuccess: () => {
                setSending(false);
                showToast({ message: 'Notification envoyée', severity: 'success' });
                navigateBack();
            },
            onError: (error) => {
                setSending(false);
                showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
            },
        }
    );

    return (
        <Page>
            <Toast />
            <div style={{ display: 'flex', paddingLeft: 10 }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    {`${viewMode ? 'Détails de la ' : 'Envoyer une '} notification`}
                </Typography>
                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                    disabled={sending}
                >
                    {viewMode ? 'Retour' : 'Annuler'}
                </Button>
                {!viewMode ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSending(true);
                            mutate();
                        }}
                        disabled={!message || !title || sending}
                    >
                        Envoyer
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            navigate(
                                location.pathname.replace(
                                    'view_notification',
                                    'create_notification'
                                )
                            );
                        }}
                    >
                        <ContentCopy />
                        Dupliquer
                    </Button>
                )}
            </div>
            <Paper
                sx={{
                    marginTop: '16px',
                    padding: '24px',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Type d'utilisateur
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Switch
                                    checked={onlyUsers}
                                    disabled={viewMode}
                                    onChange={() => setOnlyUsers(!onlyUsers)}
                                    name="Uniquement les utilisateurs inscrits"
                                />
                                <Typography>Uniquement les utilisateurs inscrits</Typography>
                            </Box>
                        </Grid>
                        {onlyUsers ? (
                            <Grid item flex={1}>
                                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                    Utilisateurs du magasin
                                </Typography>
                                <StoresPicker onlyBoutiques setCurrentStores={setShopIds} />
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Titre de la notification
                            </Typography>
                            <TextField
                                fullWidth
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                disabled={viewMode}
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Sous-titre (pour IOS uniquement)
                            </Typography>
                            <TextField
                                fullWidth
                                value={subTitle}
                                onChange={(e) => setSubTitle(e.target.value)}
                                disabled={viewMode}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Message
                            </Typography>
                            <TextField
                                fullWidth
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                disabled={viewMode}
                                InputProps={{
                                    style: {
                                        minHeight: '102px',
                                        textAlign: 'end',
                                        alignItems: 'flex-start',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Action
                            </Typography>
                            <Select
                                fullWidth
                                value={action.value}
                                disabled={viewMode}
                                onChange={(e) =>
                                    setAction(
                                        actions.find((a) => a.value === e.target.value) ??
                                            actions[0]
                                    )
                                }
                            >
                                {actions.map((a) => (
                                    <MenuItem key={a.value} value={a.value}>
                                        {a.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {['product-anti-gaspi', 'product'].includes(action.value) ? (
                            <Grid item flex={1}>
                                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                    Sélectionner le produit
                                </Typography>
                                <Select
                                    fullWidth
                                    value={product?.id}
                                    disabled={viewMode}
                                    onChange={(e) =>
                                        setProduct(
                                            products?.find((p) => p.id === e.target.value) ?? null
                                        )
                                    }
                                >
                                    {products?.map((p) => (
                                        <MenuItem key={p.id} value={p.id}>
                                            {p.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>
            </Paper>
        </Page>
    );
};

export default CreateNotificationPage1;
