import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const zTestCartsForm = z.object({
    userId: z.number().nullable(),
    storeId: z.number().nullable(),
    productId: z.number().nullable(),
    articleRefs: z.array(
        z.object({
            ref: z.string(),
            imageUrl: z.string().nullable(),
            label: z.string(),
            dlc: z.string(),
        })
    ),
    promoCode: z.string().nullable(),
});

export type TestCartsForm = z.infer<typeof zTestCartsForm>;

// Form hook
export const useTestCartsForm = () => {
    const form = useForm<TestCartsForm>({
        // Validation function
        resolver: zodResolver(zTestCartsForm),
        defaultValues: {
            userId: null,
            storeId: null,
            productId: null,
            articleRefs: [],
            promoCode: null,
        },
    });

    return form;
};
