import { Button, LinearProgress } from '@mui/material';
import { Category, Product } from '@gozoki/api-types';
import { ROUTES, ROUTE_BUILDER } from '../constants/routes.constants';

import { AuthContext } from '../context/auth.context';
import { Download } from '@mui/icons-material';
import { frenchFormat } from '@gozoki/tools';
import styles from '../../assets/styles/DashboardPage.module.css';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const DashboardCard = ({
    label,
    bigValue,
    smallValue,
    percent,
    orange,
    details,
    negativePercent,
    download,
}: {
    label: string;
    bigValue: string;
    smallValue?: string;
    percent?: number;
    orange?: boolean;
    details?: () => void;
    negativePercent?: boolean;
    download?: () => void;
}) => {
    return (
        <div className={styles.smallWhiteCards}>
            {download ? (
                <div className={styles.smallWhiteCardsDownload}>
                    <Button onClick={download}>
                        <Download />
                    </Button>
                </div>
            ) : null}
            <div className={styles.smallWhiteCardContainer}>
                <div className={styles.smallWhiteCardLeft}>
                    <p className={styles.smallWhiteCardsTitle}>{label}</p>

                    <p
                        className={
                            orange
                                ? styles.smallWhiteCardsMainValueOrange
                                : styles.smallWhiteCardsMainValue
                        }
                    >
                        {bigValue}
                    </p>
                    <p className={styles.smallWhiteCardsSecondValue}>{smallValue ?? ''}</p>
                    <p className={styles.smallWhiteCardsDetails}>
                        {details ? <Button onClick={details}>Voir détails</Button> : null}
                    </p>
                </div>
                <div className={styles.smallWhiteCardRight}>
                    {percent ? (
                        <span
                            className={
                                percent > 0 || (negativePercent && percent < 0)
                                    ? styles.smallWhiteCardsIconGood
                                    : styles.smallWhiteCardsIconBad
                            }
                        >{`${frenchFormat(percent * 100)}\u00A0%`}</span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export const ProductCard = ({
    product,
    count,
    ca,
    mode,
    maxCount,
    maxCa,
    totalCount = 1,
    totalCa = 1,
}: {
    product: Product;
    count: number;
    ca: number;
    mode: 'count' | 'ca';
    maxCount: number;
    maxCa: number;
    totalCount: number;
    totalCa: number;
}) => {
    const navigate = useNavigate();

    if (!product) return null;

    const handleClick = () => {
        navigate(ROUTE_BUILDER.productDetails(product.id));
    };

    return (
        <Button onClick={handleClick} style={{ all: 'unset', width: '100%', cursor: 'pointer' }}>
            <div className={styles.famousThingsContainer}>
                <div className={styles.famousThingsImageContainer}>
                    {product.optimizedPreviewImageUrl ? (
                        <img
                            src={product.optimizedPreviewImageUrl}
                            alt={product?.label}
                            className={styles.famousThingsImage}
                        />
                    ) : (
                        <div className={styles.famousThingsFakeImage} />
                    )}
                </div>
                <div className={styles.famousThingsTextContainer}>
                    <p className={styles.famousThingsLabel}>{product?.label}</p>
                    <p className={styles.famousThingsRef}>{product.reference}</p>
                </div>
                <div className={styles.famousThingsCountContainer}>
                    <p className={styles.famousThingsLabel}>
                        {mode === 'count' ? count : `${ca}\u00A0€`}
                    </p>
                    <p className={styles.famousThingsRef}>
                        {mode === 'count'
                            ? `${frenchFormat((count / totalCount) * 100)}\u00A0%`
                            : `${frenchFormat((ca / totalCa) * 100)}\u00A0%`}
                    </p>
                </div>
            </div>
            <div className={styles.famousThingsLinearContainer}>
                <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={mode === 'count' ? (count / maxCount) * 100 : (ca / maxCa) * 100}
                />
            </div>
        </Button>
    );
};

export const CategoryCard = ({
    category,
    count,
    ca,
    mode,
    maxCount,
    maxCa,
    totalCount = 1,
    totalCa = 1,
}: {
    category: Category;
    count: number;
    ca: number;
    mode: 'count' | 'ca';
    maxCount: number;
    maxCa: number;
    totalCount: number;
    totalCa: number;
}) => {
    const { hasRights } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!category) return null;

    const isAdmin = hasRights(['ADMIN']);

    const handleCategoryClick = () => {
        navigate(`${ROUTES.CATEGORIES}/${ROUTE_BUILDER.updateCategory(category.id)}`);
    };

    if (category.isSubCategory) {
        return (
            <div className={styles.famousSmallThingsContainer}>
                <div className={styles.famousSmallThingsImageContainer}>
                    {category.image?.url ? (
                        <img
                            src={category.image?.url}
                            alt={category.name}
                            className={styles.famousSmallThingsImage}
                        />
                    ) : (
                        <div className={styles.famousSmallThingsFakeImage} />
                    )}
                </div>
                <div className={styles.famousSmallThingsTextContainer}>
                    <p className={styles.famousSmallThingsLabel}>{category.name}</p>
                    <p className={styles.famousSmallThingsRef}>{category.description}</p>
                </div>
                <div className={styles.famousSmallThingsCountContainer}>
                    <p className={styles.famousSmallThingsLabel}>
                        {mode === 'count' ? `${count}` : `${ca}\u00A0€`}
                    </p>
                    <p className={styles.famousSmallThingsRef}>
                        {mode === 'count'
                            ? `(${frenchFormat((count / totalCount) * 100)}\u00A0%)`
                            : `(${frenchFormat((ca / totalCa) * 100)}\u00A0%)`}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <>
            <Button
                onClick={handleCategoryClick}
                style={{
                    all: 'unset',
                    width: '100%',
                    cursor: isAdmin ? 'pointer' : 'auto',
                }}
                disabled={!isAdmin}
            >
                <div className={styles.famousThingsContainer}>
                    <div className={styles.famousThingsImageContainer}>
                        {category.image?.url ? (
                            <img
                                src={category.image?.url}
                                alt={category.name}
                                className={styles.famousThingsImage}
                            />
                        ) : (
                            <div className={styles.famousThingsFakeImage} />
                        )}
                    </div>
                    <div className={styles.famousThingsTextContainer}>
                        <p className={styles.famousThingsLabel}>{category.name}</p>
                        <p className={styles.famousThingsRef}>{category.description}</p>
                    </div>
                    <div className={styles.famousThingsCountContainer}>
                        <p className={styles.famousThingsLabel}>
                            {mode === 'count' ? count : `${ca}\u00A0€`}
                        </p>
                        <p className={styles.famousThingsRef}>
                            {mode === 'count'
                                ? `${frenchFormat((count / totalCount) * 100)}\u00A0%`
                                : `${frenchFormat((ca / totalCa) * 100)}\u00A0%`}
                        </p>
                    </div>
                </div>
            </Button>
            <div className={styles.famousThingsLinearContainer}>
                <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={mode === 'count' ? (count / maxCount) * 100 : (ca / maxCa) * 100}
                />
            </div>
        </>
    );
};
