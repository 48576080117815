import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Page from '../../components/Page';
import LoadingPaper from '../../components/containers/LoadingPaper';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import VideoInputRhf from '../../components/inputs/VideoInputRhf';
import { PRODUCTS_ROUTES, ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides, TextOverrides } from '../../utils/theme/overrides.theme';
import { ProductOutletContext } from './ProductsOutletProvider';

const AddProductPage3 = () => {
    const navigate = useNavigate();

    const { productForm, updateMode, setPreparationVideo, productId, isUpdateModeLoading } =
        useOutletContext<ProductOutletContext>();
    const { control, trigger, reset } = productForm;

    const cancel = () => {
        reset();
        navigate(ROUTES.PRODUCTS);
    };
    const navigateBack = () => navigate(ROUTE_BUILDER.updateProductSecondStep(productId));
    const navigateNext = async () => {
        // Validate this page before going to the next one
        const valid = await trigger([
            'preparationMode',
            'ingredients',
            'energyValue',
            'fats',
            'carbohydrates',
            'proteins',
            'allergens',
        ]);
        if (valid && !updateMode) navigate(PRODUCTS_ROUTES.ADD_PRODUCT_FOURTH_STEP);
        if (valid && updateMode) navigate(ROUTE_BUILDER.updateProductFourthStep(productId));
    };

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode ? 'Modifier un produit' : 'Ajouter un produit'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button variant="contained" color="secondary" onClick={navigateNext}>
                    Suivant
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Mode de préparation
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="preparationMode"
                    placeholder="Votre mode de préparation"
                    fullWidth
                />
                <VideoInputRhf
                    control={control}
                    name="preparationVideo"
                    storeFile={setPreparationVideo}
                />

                <Typography mb="8px" style={fonts.inputHeader}>
                    Ingrédients
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="ingredients"
                    placeholder="Vos ingrédients"
                    fullWidth
                />

                <Typography mt="24px" mb="16px" style={fonts.inputHeader}>
                    Valeurs nutritionnelles
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Valeur énergétique
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="energyValue"
                                placeholder="kcal"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Glucides
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="carbohydrates"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Sucres
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="sugars"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Polyols
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="polyols"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Protéines
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="proteins"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Fibres
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="fibers"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Matières grasses
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="fats"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Acides gras saturés
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="saturatedFats"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Sodium
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="sodium"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="4px" style={fonts.inputSubtitle}>
                                Sel
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="salt"
                                placeholder="g"
                                fullWidth
                                inputProps={TextOverrides.rightPlaceholder}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Allergènes
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="allergens"
                    placeholder="Allergènes"
                    fullWidth
                />
            </LoadingPaper>
        </Page>
    );
};

export default AddProductPage3;
