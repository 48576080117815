import { Box, Button, Modal, SxProps, Theme } from '@mui/material';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { FieldValues, Control, Path, useController, PathValue } from 'react-hook-form';
import { useState } from 'react';
import Video from '../containers/Video';
import { ThemeColors } from '../../utils/theme/colors.theme';

export interface ImageInputRhfProps<T extends FieldValues> {
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form

    storeFile: (file: File | null) => void; // Function to store the file on change
    // This is not handled with form control because videos are usually too large
}

const VideoInputRhf = <T extends FieldValues>({
    control,
    name,
    storeFile,
}: ImageInputRhfProps<T>) => {
    const [videoOpen, setVideoOpen] = useState(false); // Video player modal

    const {
        field: { onChange, onBlur: rhfOnBlur, value },
    } = useController({
        name,
        control,
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '16px',
            }}
        >
            <Button
                onBlur={rhfOnBlur}
                component="label"
                sx={{
                    ...ButtonOverrides.lightOrange,
                    ...fonts.imageButton,
                    height: '37px',
                    marginTop: '8px',
                    marginBottom: '8px',
                }}
            >
                {value ? 'Modifier la vidéo' : '+ Ajouter une vidéo'}
                <input
                    type="file"
                    accept="video/*"
                    hidden
                    onChange={(e) => {
                        const { files } = e.target;
                        // Store the first selected file
                        if (files !== null) {
                            // Temporary url to display the video
                            const url = URL.createObjectURL(files[0]);
                            onChange(url as PathValue<T, Path<T>>); // Change the value url

                            // Store the file for later uploads
                            storeFile(files[0]); // Store the file
                        }
                    }}
                />
            </Button>
            {value && (
                <>
                    <Button
                        sx={{
                            ...ButtonOverrides.lightOrange,
                            ...fonts.imageButton,
                            height: '37px',
                            margin: '8px',
                        }}
                        onClick={() => setVideoOpen(true)}
                    >
                        Visionner
                    </Button>
                    <Button
                        sx={{
                            ...ButtonOverrides.lightRed,
                            ...fonts.imageButton,
                            color: ThemeColors.RED,
                            height: '37px',
                        }}
                        onClick={() => {
                            storeFile(null);
                            onChange(null as PathValue<T, Path<T>>);
                        }}
                    >
                        Supprimer
                    </Button>
                </>
            )}
            <Modal open={videoOpen} onClose={() => setVideoOpen(false)}>
                <Box sx={style}>
                    <Video style={{ borderRadius: '8px' }} url={value} />
                </Box>
            </Modal>
        </div>
    );
};

// Centered modal style
const style: SxProps<Theme> = {
    // Position
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    // Shape
    padding: 0,
    margin: 0,
    minHeight: '50vh',
    minWidth: '50vw',

    borderRadius: '8px',
};

export default VideoInputRhf;
