export interface CenterProps {
    children: React.ReactNode;

    style?: React.CSSProperties;
}

/** Element that centers its content */
const Center = ({ children, style }: CenterProps) => {
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...style,
            }}
        >
            {children}
        </div>
    );
};

export default Center;
