/* eslint-disable jsx-a11y/media-has-caption */
export interface VideoProps {
    url?: string;
    style?: React.CSSProperties;
}

const Video = ({ url, style }: VideoProps) => {
    return <video style={style} height="100%" width="100%" src={url} controls />;
};

export default Video;
