import { NotificationPayload } from '@gozoki/api-types';
import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

export const sendNotification = async (data: {
    title: string;
    subTitle: string | null; // for IOS only
    text: string;
    onlyUsers: boolean;
    shopIds: number[];
    data?: NotificationPayload;
}) => {
    const response = await gozokiApiWithAuth.post(`/notifications/send`, data);
    return response.data;
};

export const recordInteraction = async (
    pushToken?: string,
    notificationId?: number,
    onApp?: boolean
) => {
    // this could happen if the notification was not sent with an ID
    if (!notificationId) return;
    // edge case, but could happen in theory
    if (!pushToken) return;

    await gozokiApi.post(`/notifications/${notificationId}/interact`, {
        pushToken,
        onApp: onApp ?? true,
    });
};
