import { ThemeColors } from '../../utils/theme/colors.theme';

export interface ThumbnailProps {
    url?: string;
    alt: string;
    size?: string;
    padding?: string;
    rounded?: boolean;
}

const Thumbnail = ({ url, alt, size = '100px', padding = '10px', rounded }: ThumbnailProps) => {
    if (url) {
        return (
            <div style={{ padding, height: size, width: size }}>
                <img
                    src={url}
                    alt={alt}
                    style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: rounded ? 30 : undefined,
                        objectFit: 'cover',
                    }}
                />
            </div>
        );
    }

    // Return grey div
    return (
        <div style={{ padding, height: size, width: size }}>
            <div
                style={{
                    backgroundColor: ThemeColors.GREY_20,
                    height: '100%',
                    width: '100%',
                    borderRadius: rounded ? 30 : undefined,
                }}
            />
        </div>
    );
};

export default Thumbnail;
