import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

// From backend-register : at least 8 characters AND one special char AND one letter
const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

// TODO : add the code regex

const zNewResetPasswordForm = z.object({
    newPassword: z
        .string()
        .min(8, { message: 'Le mot de passe doit contenir au minimum 8 caractères' })
        .regex(PASSWORD_REGEX, {
            message: 'Le mot de passe doit contenir au moins un chiffre et un caractère spécial.',
        }),
    email: z.string().email({ message: "L'adresse email est invalide" }),
    code: z
        .string()
        .min(6, { message: 'Le code doit contenir 6 caractères' })
        .max(6, { message: 'Le code doit contenir 6 caractères' }),
});

export type NewResetPasswordForm = z.infer<typeof zNewResetPasswordForm>;

// Form hook
export const useResetPasswordForm = () => {
    const form = useForm<NewResetPasswordForm>({
        // Validation function
        resolver: zodResolver(zNewResetPasswordForm),
        defaultValues: {
            email: '',
            newPassword: '',
            code: '',
        },
    });

    return form;
};
