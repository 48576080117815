import { Box, SxProps, Theme } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../utils/context/auth.context';

export interface PageProps {
    children: React.ReactNode;
    sx?: SxProps<Theme>; // Style override
}

// Base page component
const Page = ({ children, sx }: PageProps) => {
    const { authenticated } = useContext(AuthContext);
    return (
        <Box
            sx={{
                ...sx,
                padding: '24px',
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
            }}
        >
            {authenticated && children}
        </Box>
    );
};

export default Page;
