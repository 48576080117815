import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, Routes, useLocation } from 'react-router-dom';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import GlobalProvider from './components/GlobalProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import LoginPage from './pages/LoginPage';
import MainAppBar from './components/MainAppBar';
import MainDrawer from './components/MainDrawer';
import MainRouter from './components/MainRouter';
import { ROUTES } from './utils/constants/routes.constants';
import RootRedirect from './pages/RootRedirect';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {},
        mutations: {},
    },
});

const App = () => {
    const location = useLocation();

    const hideBarAndDrawer = location.pathname === ROUTES.LOGIN;
    return (
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <GlobalProvider>
                    {!hideBarAndDrawer && ( // Only show these on logged in pages
                        <>
                            <MainAppBar />
                            <MainDrawer />
                        </>
                    )}

                    <div
                        style={{
                            paddingTop: hideBarAndDrawer ? 0 : '72px',
                            paddingLeft: hideBarAndDrawer ? 0 : '256px',
                        }}
                    >
                        <Routes>
                            <Route path={ROUTES.ROOT} element={<RootRedirect />} />
                            <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                        </Routes>
                        <MainRouter />
                    </div>
                </GlobalProvider>
            </LocalizationProvider>
        </QueryClientProvider>
    );
};

export default App;
