import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';

type DialogSelectDatesProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    keepDatesAndExport: () => void;
    setLastWeekAndExport: () => void;
    setCurrentWeekAndExport: () => void;
};
const DialogSelectDates = ({
    showModal,
    setShowModal,
    keepDatesAndExport,
    setLastWeekAndExport,
    setCurrentWeekAndExport,
}: DialogSelectDatesProps) => (
    <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>Sur quelle période exporter les données</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Conserver les dates actuelles ou exporter les données de la semaine dernière ?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={keepDatesAndExport}>Conserver les dates</Button>
            <Button onClick={setLastWeekAndExport}>Semaine dernière</Button>
            <Button onClick={setCurrentWeekAndExport}>Semaine actuelle</Button>
        </DialogActions>
    </Dialog>
);

export default DialogSelectDates;
