/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import axios from 'axios';
import { getErrorMessage } from './getErrorMessage';

export const getErrorMessageFromPossibleBlob = async (
    error: unknown,
    handleUnknownError = (_e: unknown) => {}
) => {
    if (axios.isAxiosError(error) && error.response?.data instanceof Blob) {
        // Function to wrap FileReader in a promise
        const readBlobAsText = (blob: Blob) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsText(blob);
            });
        };
        // Read the error Blob as text
        const errorText = (await readBlobAsText(error.response?.data || new Blob())) as string;
        const errorJson = JSON.parse(errorText);

        return errorJson.error;
    }

    return getErrorMessage(error, handleUnknownError);
};
