import { generateLocalUrl } from '@gozoki/api';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from '@mui/material';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';

export interface ImageInputSquareProps {
    size: string;
    value: Blob | null;
    onChange: (file: Blob | null) => void;
    enableUpload?: boolean;
}
const ImageInputSquare = ({ size, value, onChange, enableUpload }: ImageInputSquareProps) => {
    return (
        <div
            style={{
                height: size,
                width: size,
                borderRadius: '4px',
                borderWidth: value !== null ? 0 : '2px',
                borderColor: ThemeColors.GREY_20,
                borderStyle: 'dashed',

                // Center content
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}
        >
            {!value && enableUpload && (
                <Button
                    variant="contained"
                    component="label"
                    sx={{
                        ...ButtonOverrides.lightOrange,
                        ...fonts.imageButton,
                        height: '26px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                    }}
                >
                    Ajouter
                    <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => {
                            const { files } = e.target;
                            if (files !== null) {
                                // Store the first selected file
                                onChange(files[0]);
                            }
                        }}
                    />
                </Button>
            )}
            {value && (
                <img
                    src={generateLocalUrl(value)}
                    style={{ height: '100%', width: '100%', borderRadius: '4px' }}
                    alt="preview"
                />
            )}
            {value && (
                <IconButton
                    onClick={() => onChange(null)}
                    style={{
                        // Size
                        height: '24px',
                        width: '24px',

                        // Theme
                        backgroundColor: ThemeColors.ORANGE,
                        borderRadius: '2px',

                        // Position
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                    }}
                >
                    <DeleteIcon
                        sx={{
                            color: ThemeColors.WHITE,
                            height: '16px',
                            width: '16px',
                        }}
                    />
                </IconButton>
            )}
        </div>
    );
};

export default ImageInputSquare;
