import { Cart, CartsWithReportUrl } from '@gozoki/api-types';
import { gozokiApiWithAuth } from '../gozokiApi';

/** Fetch my carts */
export const fetchMyCarts = async () => {
    const response = await gozokiApiWithAuth.get<CartsWithReportUrl[]>('/carts/me');
    return response.data;
};

export const fetchCart = async (id: number) => {
    const response = await gozokiApiWithAuth.get<Cart>(`/carts/${id}`);
    return response.data;
};
