import { DailyDashboardKpi, DailyStockKpi } from '@gozoki/api-types';

import { gozokiApiWithAuth } from '../gozokiApi';

// Request an authentication token with credentials
export const fetchAllDailysKpis = async () => {
    const response = await gozokiApiWithAuth.get<DailyDashboardKpi>('/stores/get-all-kpis');
    return response.data;
};

export const fetchAllStockKpis = async () => {
    const response = await gozokiApiWithAuth.get<DailyStockKpi>('/stores/get-all-stock-kpis');
    return response.data;
};

export const fetchAccountingKpis = async (date: string) => {
    const response = await gozokiApiWithAuth.get(`/stores/get-accounting-kpis/${date}`); // 'AAAA-MM-DD'
    return response.data;
};

export const fetchHoursData = async (currentMode: string | null) => {
    const response = currentMode
        ? await gozokiApiWithAuth.get(`/stores/get-hours-data/${currentMode}`)
        : await gozokiApiWithAuth.get(`/stores/get-hours-data`);
    return response.data;
};

export const fetchTimeSpentData = async (currentMode: string | null) => {
    const response = currentMode
        ? await gozokiApiWithAuth.get(`/stores/get-time-spent-data/${currentMode}`)
        : await gozokiApiWithAuth.get(`/stores/get-time-spent-data`);
    return response.data;
};

export const fetchAccountingWarnings = async () => {
    const response = await gozokiApiWithAuth.get(`/stores/get-accounting-warnings`);
    return response.data;
};

export const fetchAverageTimeSpent = async (
    currentMode: string,
    startDate: Date,
    endDate: Date
) => {
    const response = await gozokiApiWithAuth.get(
        `/time-spent/get-average-time-spent?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&mode=${currentMode}`
    );
    return response.data;
};
