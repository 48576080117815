import { Button, Tab, Tabs, Typography } from '@mui/material';
import { CategoryTabState, State } from '../../utils/types/state.types';
import { useLocation, useNavigate } from 'react-router-dom';

import { BrandsPanel } from './BrandsPanel';
import { CATEGORIES_ROUTES } from '../../utils/constants/routes.constants';
import { CategoriesPanel } from './CategoriesPanel';
import Page from '../../components/Page';
import PlusIcon from '../../components/icons/PlusIcon';
import TabPanel from '../../components/containers/TabPanel';
import { fonts } from '../../utils/theme/fonts.theme';
import { useRights } from '../../utils/hooks';
import { useState } from 'react';

const CategoriesPage = () => {
    const { state }: State<CategoryTabState> = useLocation();
    const [currentTab, setCurrentTab] = useState(state?.currentTab ?? 0);
    const navigate = useNavigate();

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const isAdmin = useRights(['ADMIN']);

    return (
        <Page>
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Catégories
                </Typography>

                {currentTab === 0 && (
                    <Button
                        variant="contained"
                        onClick={() => navigate(CATEGORIES_ROUTES.ADD_CATEGORY)}
                        disabled={!isAdmin}
                    >
                        <PlusIcon />
                        Ajouter une catégorie
                    </Button>
                )}
                {currentTab === 1 && (
                    <Button
                        variant="contained"
                        onClick={() => navigate(CATEGORIES_ROUTES.ADD_SUB_CATEGORY)}
                        disabled={!isAdmin}
                    >
                        <PlusIcon />
                        Ajouter une sous-catégorie
                    </Button>
                )}
                {currentTab === 2 && (
                    <Button
                        variant="contained"
                        sx={{ marginRight: '16px' }}
                        onClick={() => navigate(CATEGORIES_ROUTES.ADD_BRAND)}
                        disabled={!isAdmin}
                    >
                        <PlusIcon />
                        Ajouter une marque
                    </Button>
                )}
            </div>

            <Tabs value={currentTab} onChange={handleChange}>
                <Tab label="Catégories" />
                <Tab label="Sous Catégories" />
                <Tab label="Marques" />
            </Tabs>

            <TabPanel value={currentTab} index={0}>
                <CategoriesPanel mode="category" />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
                <CategoriesPanel mode="subcategory" />
            </TabPanel>

            <TabPanel value={currentTab} index={2}>
                <BrandsPanel />
            </TabPanel>
        </Page>
    );
};

export default CategoriesPage;
