import { Button, IconButton, Typography } from '@mui/material';
import { PRODUCTS_ROUTES, ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import FileInputRhf from '../../components/inputs/FileInputRhf';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { MenuOption } from '../../components/containers/MenuOptions';
import Page from '../../components/Page';
import { ProductOutletContext } from './ProductsOutletProvider';
import SelectRhf from '../../components/inputs/SelectRhf';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import TiledImageInputRhf from '../../components/inputs/TiledImageInputRhf';
import { fonts } from '../../utils/theme/fonts.theme';

const nutriscoreOptions: MenuOption[] = [
    { label: 'Non indiqué', value: '' },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
];

const AddProductPage2 = () => {
    const navigate = useNavigate();

    const { productForm, updateMode, productId, isUpdateModeLoading, previewImageName } =
        useOutletContext<ProductOutletContext>();
    const { control, trigger, reset } = productForm;

    const cancel = () => {
        reset();
        navigate(ROUTES.PRODUCTS);
    };
    const navigateBack = () => navigate(ROUTE_BUILDER.updateProductFirstStep(productId));
    const navigateNext = async () => {
        // Validate this page before going to the next one
        const valid = await trigger(['nutriscore', 'description']);
        if (valid && !updateMode) navigate(PRODUCTS_ROUTES.ADD_PRODUCT_THIRD_STEP);
        if (valid && updateMode) navigate(ROUTE_BUILDER.updateProductThirdStep(productId));
    };

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode ? 'Modifier un produit' : 'Ajouter un produit'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button variant="contained" color="secondary" onClick={navigateNext}>
                    Suivant
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Images
                </Typography>
                <TiledImageInputRhf control={control} name="images" />
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Image de vignette ()
                </Typography>
                <FileInputRhf
                    label="Ajouter une image"
                    control={control}
                    name="previewImage"
                    defaultName={previewImageName}
                />

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Description
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="description"
                    placeholder="Description du produit"
                    fullWidth
                    multiline
                    InputProps={{
                        style: {
                            minHeight: '102px',
                            textAlign: 'end',
                            alignItems: 'flex-start',
                        },
                    }}
                />
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Nutriscore
                </Typography>

                <SelectRhf control={control} name="nutriscore" options={nutriscoreOptions} />
            </LoadingPaper>
        </Page>
    );
};

export default AddProductPage2;
