import { getUserRolesLabels } from '@gozoki/tools';
import { Box, Chip, Tooltip } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';

type Props = GridRenderCellParams<{ adminRights: number }, number, unknown, GridTreeNodeWithRender>;

export const UserRolesCell = ({ value }: Props) => {
    const rolesLabels = getUserRolesLabels(value ?? 0);
    const allLabels = rolesLabels.join(', ');

    return (
        <Tooltip title={allLabels}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                {rolesLabels.map((role: string) => (
                    <Chip key={role} label={role} />
                ))}
            </Box>
        </Tooltip>
    );
};
