import {
    LoadedProduct,
    MENU_TYPES,
    NEGOCE_TYPES,
    Store,
    StoreProductMinimumStock,
} from '@gozoki/api-types';
import { excludeFields, isNumber, parseFrenchFloat, shallowStringigy } from '@gozoki/tools';
import { resolveImages, zInputFile } from '../tools/images.tools';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useBoutiques } from '../hooks/stores.hooks';

export const zStoreProductMinimumStockForm = z.object({
    productReference: z.string(),
    storeReference: z.string(),
    minimumStock: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(),
    weekEndMinimumStock: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(),
    label: z.string().optional(),
});

export const zProductForm = z.object({
    // Product ID
    reference: z
        .string()
        .nonempty()
        .regex(/^\d+$/, 'La référence doit contenir uniquement des chiffres')
        .length(13, 'La référence doit contenir exactement 13 chiffres'),
    dun14: z
        .string()
        .regex(/^\d+$/, 'Le code DUN 14 doit contenir uniquement des chiffres')
        .length(14, 'Le code DUN 14 doit contenir exactement 14 chiffres')
        .nullable(),
    label: z.string().nonempty(),

    // Product data
    weight: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(), // grams
    price: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(), // centimes
    weightPrice: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(), // centimes per gram
    sellPrice: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(), // centimes
    sellWeightPrice: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(), // centimes per gram

    // This field is not used in the backend, but it helps control & persist the frontend state
    priceMode: z.string(), // Either 'fixed' or 'ratio' mode

    preparationMode: z.string(),
    ingredients: z.string(),

    // Nutritional values
    energyValue: z.string().refine(isNumber),
    fats: z.string().refine(isNumber),
    saturatedFats: z.string().refine(isNumber),
    carbohydrates: z.string().refine(isNumber),
    sugars: z.string().refine(isNumber),
    polyols: z.string().refine(isNumber),
    proteins: z.string().refine(isNumber),
    fibers: z.string().refine(isNumber),
    sodium: z.string().refine(isNumber),
    salt: z.string().refine(isNumber),

    allergens: z.string(),

    // Product de scription
    description: z.string().nonempty(),
    nutriscore: z.string().nullable(),
    conservation: z.string(),

    // Product status & relations
    isNew: z.string().transform((value) => value === 'true' || !!value), // Transform into boolean before submitting
    isActive: z.string().transform((value) => value === 'true' || !!value), // Transform into boolean before submitting
    brandId: z.number(),

    antiGaspi: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(), // Number before DLC to warn the user

    // Additional fields
    categories: z.array(z.number()),

    // Attachments
    images: z.array(zInputFile), // Images to upload as product attachments

    previewImage: z.instanceof(File).nullable(),

    // Note : this field is used to persist the video url during upload / update process,
    // but it has no effect on the backend.
    preparationVideo: z.string().nullable(),
    colisage: z.string().refine(isNumber).transform(parseFrenchFloat),
    colisWeight: z.string().refine(isNumber).transform(parseFrenchFloat),
    menuType: z.enum(MENU_TYPES).nullable(),
    menuExtraPrice: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(),
    portions: z.string().nullable(),
    copilotCode: z.string().nullable(),
    negoceType: z.enum(NEGOCE_TYPES),
    storeProductMinimumStock: z.array(zStoreProductMinimumStockForm),

    // Anti-gaspi
    agPromo: z.string().refine(isNumber).transform(parseFrenchFloat).nullable(),
});

export type ProductForm = z.infer<typeof zProductForm>;

export const getDefaultStoreProductMinimumStock = (boutiques: Store[]) => {
    return boutiques.map((store) => ({
        productReference: '',
        storeReference: store.checkpointStoreRef,
        minimumStock: 0,
        weekEndMinimumStock: 0,
        label: store.label,
    }));
};

// Form hook
export const useProductForm = (defaultValues?: Partial<ProductForm>) => {
    const boutiques = useBoutiques();

    const form = useForm<ProductForm>({
        // Validation function
        resolver: zodResolver(zProductForm),
        defaultValues: {
            reference: '',
            description: '',
            nutriscore: '',
            conservation: '',
            brandId: 0,
            isNew: false,
            isActive: false,
            categories: [],
            images: [],
            priceMode: 'fixed',
            menuType: null,
            menuExtraPrice: 0,
            portions: '',
            copilotCode: '',
            allergens: '',
            colisWeight: 0,
            preparationMode: '',
            negoceType: 'NEGOCE INTERNE',
            agPromo: 30,
            antiGaspi: 0,
            storeProductMinimumStock:
                defaultValues?.storeProductMinimumStock &&
                defaultValues?.storeProductMinimumStock.length > 0
                    ? defaultValues.storeProductMinimumStock
                    : getDefaultStoreProductMinimumStock(boutiques.data ?? []) ?? [],
            ...defaultValues,
        },
    });

    return form;
};

// Helper : generate precompleted form from existing product data for modification
export const fromExistingProduct = async (
    product: LoadedProduct,
    boutiques: Store[],
    storeProductMinimumStock?: StoreProductMinimumStock[]
): Promise<ProductForm> => {
    return shallowStringigy({
        ...excludeFields(product, [
            'createdAt',
            'updatedAt',
            'id',
            'nutritionalValueId',
            'nutritionalValues',
            'images',
            'preparationVideo',
        ]), // Exclude autogenerated fields

        // Override the required values
        categories: product.categories.map((category) => category.id),

        // Unpack nutritional values
        energyValue: product.nutritionalValues.energyValue,
        fats: product.nutritionalValues.fats,
        carbohydrates: product.nutritionalValues.carbohydrates,
        proteins: product.nutritionalValues.proteins,
        fibers: product.nutritionalValues.fibers,
        sodium: product.nutritionalValues.sodium,
        salt: product.nutritionalValues.salt,
        saturatedFats: product.nutritionalValues.saturatedFats,
        sugars: product.nutritionalValues.sugars,
        polyols: product.nutritionalValues.polyols,

        // Other
        priceMode: product.weightPrice ? 'ratio' : 'fixed',

        images: await resolveImages(product.images),
        preparationVideo: product.preparationVideo?.url ?? null,
        previewImage: product.previewImage?.url ? new File([], product.previewImage.url) : null,
        copilotCode: product.copilotCode ?? '',
        preparationMode: product.preparationMode ?? '',
        colisage: product.colisage ?? '',
        colisWeight: product.colisWeight ?? 0,
        allergens: product.allergens ?? '',
        nutriscore: product.nutriscore ?? '',
        portions: product.portions ?? '',

        storeProductMinimumStock:
            storeProductMinimumStock && storeProductMinimumStock.length > 0
                ? storeProductMinimumStock
                : getDefaultStoreProductMinimumStock(boutiques) ?? [],

        // Anti-gaspi
        agPromo: product.agPromo ?? 30,
        antiGaspi: product.antiGaspi ?? 0,
    });
};
