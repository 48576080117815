import ExcelJS from 'exceljs';
import JSZip from 'jszip';
import { ProductWithCount } from '../../pages/dashboard/DashboardPage';
import { excelParseValue } from './excelParseValue';

type AllowedExcelType = number | string;

// fonction utilisée pour parser les données CSV et créer les fichiers Excel
const parseCSVData = (csvData: string): AllowedExcelType[] => {
    const rows: string[] = csvData.split('\n');
    const parsedData: AllowedExcelType[] = [];

    let currentCell = '';
    let insideQuotes = false;

    rows.forEach((row: string) => {
        for (let i = 0; i < row.length; i++) {
            const char: string = row.charAt(i);

            if (char === '"') {
                insideQuotes = !insideQuotes;
            }

            if (char === ',' && !insideQuotes) {
                parsedData.push(excelParseValue(currentCell));
                currentCell = '';
            } else if (char !== '"') {
                currentCell += char;
            }
        }
        parsedData.push(excelParseValue(currentCell));
        currentCell = '';
    });

    return parsedData;
};

function firstParenthesisElement(str: string) {
    const regex = /\((.*?)\)/;
    const result = regex.exec(str);
    if (result && result.length > 1) {
        return result[1];
    }
    return null;
}

export const writeStoreFiles = async (
    data: string,
    selectedStores: number[] | undefined,
    exportType: string
) => {
    // Création du fichier zip pour mettre tous les fichiers dedans ensuite
    const zip = new JSZip();
    const workbook = new ExcelJS.Workbook();

    // création de la date formatée pour le nom des fichiers
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0 en JavaScript
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    const dateNow = `${day}-${month}-${year}_${hour}h${minute}`;

    // Séparation des données en sections : une section = un store
    const sections = data.split('\n\n'); // Sépare chaque section par une ligne vide

    sections.forEach((section) => {
        // Ignorer les sections vides
        if (section.trim() === '') {
            return;
        }

        const lines = section.split('\n'); // Sépare les lignes de chaque section
        const storeName = lines[0].trim(); // Nom du store (première ligne de la section)
        const storeId = firstParenthesisElement(storeName);
        const sanitizedStoreName = storeName.toLowerCase().replace(/[^a-z0-9]+/g, '-'); // Remplace les espaces et caractères spéciaux par des tirets
        const sectionData = lines.slice(1).join('\n'); // Ignorer la première ligne (titres des colonnes)

        if (
            selectedStores &&
            selectedStores?.length > 0 &&
            !selectedStores.includes(Number(storeId))
        ) {
            return;
        }

        if (exportType === 'multipleCSV') {
            // Exporter en CSV
            const content = `data:text/csv;charset=utf-8,${sectionData}`;
            const encodedUri = encodeURI(content);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `${sanitizedStoreName}_${dateNow}.csv`);
            link.click();
            return;
        }

        if (exportType === 'excel') {
            // Exporter en Excel
            const sheet = workbook.addWorksheet(storeName);
            const rows = sectionData.split('\n');
            rows.forEach((row) => {
                const cells = parseCSVData(row);
                sheet.addRow(cells);
            });
            return;
        }

        zip.file(`${sanitizedStoreName}_${dateNow}.csv`, sectionData); // Ajoute le fichier au zip
    });

    if (exportType === 'multipleCSV') {
        return;
    }

    if (exportType === 'excel') {
        const content = await workbook.xlsx.writeBuffer();
        const url = URL.createObjectURL(new Blob([content]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `donnees-boutiques_${dateNow}.xlsx`; // Nom du fichier Excel
        link.click();
        URL.revokeObjectURL(url);
    }

    if (exportType === 'zip') {
        const content = await zip.generateAsync({ type: 'blob' });

        const url = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = url;
        link.download = `donnees-boutiques_${dateNow}.zip`; // Nom du fichier ZIP
        link.click();
        URL.revokeObjectURL(url);
    }
};

export const writeArticlesDetailsFile = async (
    productsWithCount: ProductWithCount[],
    fileName: string
) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Détails des articles');

    sheet.columns = [
        { header: 'Référence produit', key: 'reference' },
        { header: 'Nom produit', key: 'name' },
        { header: 'Catégories', key: 'categories' },
        { header: 'Marque', key: 'brand' },
        { header: 'Quantité', key: 'count' },
    ];

    productsWithCount.forEach((product) => {
        const categories = product.categories.map((category) => category.name).join(', ');
        sheet.addRow({
            reference: product.reference,
            name: product.label,
            count: product.count,
            brand: product.brand.name,
            categories,
        });
    });

    const content = await workbook.xlsx.writeBuffer();
    const url = URL.createObjectURL(new Blob([content]));
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
};
