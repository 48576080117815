import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { FormControlLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useHoursData, useTimeSpentDatas } from '@gozoki/api';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Line } from 'react-chartjs-2';
import Page from '../../components/Page';
import { fonts } from '../../utils/theme/fonts.theme';
import styles from '../../assets/styles/DashboardPage.module.css';
import { useNavigate } from 'react-router-dom';

// Enregistrement des composants nécessaires pour ChartJS
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const modeOptions = [
    { value: 'all', label: 'Tous les jours' },
    { value: 'week', label: 'Uniquement la semaine' },
    { value: 'week-end', label: 'Uniquement le week end' },
] as const;

type ModeValue = (typeof modeOptions)[number]['value'];

const DashboardHoursPage = () => {
    const navigate = useNavigate();

    const [currentMode, setCurrentMode] = useState<ModeValue>('all');

    const { data: hoursData } = useHoursData(currentMode);
    const { data: timeSpentData } = useTimeSpentDatas(currentMode);

    const hoursDataPlusOne = useMemo(() => {
        if (!hoursData) return [];
        return [
            ...hoursData,
            { time: '24:00', count: hoursData[0].count, amount: hoursData[0].amount },
        ]; // make the chart loop (more intuitive for the user)
    }, [hoursData]);

    const timeSpendDatasPlusOne = useMemo(() => {
        if (!timeSpentData) return [];
        return [
            ...timeSpentData,
            {
                time: '24:00',
                averageTimeInDesk: timeSpentData[0].averageTimeInDesk,
                averageTimeInShop: timeSpentData[0].averageTimeInShop,
            },
        ]; // make the chart loop (more intuitive for the user)
    }, [timeSpentData]);

    const navigateBack = useCallback(() => {
        navigate('/dashboard');
    }, [navigate]);

    if (!hoursData || !timeSpentData) return null;

    // Préparation des données pour le diagramme
    const chartDataCount = {
        labels: hoursDataPlusOne.map((d) => `${d.time}`),
        datasets: [
            {
                label: 'Nombre de paniers vendus',
                data: hoursDataPlusOne.map((d) => d.count),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.5,
            },
        ],
    };

    const chartDataAmount = {
        labels: hoursDataPlusOne.map((d) => `${d.time}`),
        datasets: [
            {
                label: 'Valeur totale des paniers vendus',
                data: hoursDataPlusOne.map((d) => d.amount),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.5,
            },
        ],
    };

    const chartDataTimeSpent = {
        labels: timeSpendDatasPlusOne.map((d) => `${d.time}`),
        datasets: [
            {
                label: 'Temps passé dans le magasin',
                data: timeSpendDatasPlusOne.map((d) => d.averageTimeInShop),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.5,
            },
            {
                label: 'Temps passé à la caisse',
                data: timeSpendDatasPlusOne.map((d) => d.averageTimeInDesk),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.5,
            },
            {
                label: 'temps total',
                data: timeSpendDatasPlusOne.map((d) => d.averageTimeInDesk + d.averageTimeInShop),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.5,
            },
        ],
    };

    // Options pour le diagramme
    const optionsCount = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Nombre de paniers vendus par tranche de 10 minutes',
                font: {
                    size: 24, // Adjusts the font size
                },
                align: 'start' as const, // Aligns the title to the left
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false,
    };

    const optionsAmount = {
        plugins: {
            responsive: true,
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Valeur totale des paniers vendus par tranche de 10 minutes (€)',
                font: {
                    size: 24, // Adjusts the font size
                },
                align: 'start' as const, // Aligns the title to the left
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false,
    };

    const timeSpentDatasOptions = {
        plugins: {
            responsive: true,
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Temps moyen passé en magasin et en caisse',
                font: {
                    size: 24, // Adjusts the font size
                },
                align: 'start' as const, // Aligns the title to the left
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <Page sx={fonts.roboto}>
            <div style={{ display: 'flex', paddingBottom: 20, alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: 'black',
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        flex: 1,
                    }}
                >
                    <Typography style={{ ...fonts.pageTitle, paddingLeft: 20 }} flexGrow={1}>
                        Les chiffres sur les 28 derniers jours (toutes boutiques)
                    </Typography>
                </div>
                <RadioGroup
                    onChange={(e) => {
                        setCurrentMode(e.target.value as ModeValue);
                    }}
                    value={currentMode}
                >
                    {modeOptions.map((option) => (
                        <FormControlLabel
                            key={`${option.value}`}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            </div>
            <div style={{ minHeight: 400 }} className={styles.smallWhiteCards}>
                <Line data={chartDataCount} options={optionsCount} />
            </div>
            <div style={{ minHeight: 400 }} className={styles.smallWhiteCards}>
                <Line data={chartDataAmount} options={optionsAmount} />
            </div>
            <div style={{ minHeight: 400 }} className={styles.smallWhiteCards}>
                <Line data={chartDataTimeSpent} options={timeSpentDatasOptions} />
            </div>
        </Page>
    );
};

export default DashboardHoursPage;
