import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { Product } from '@gozoki/api-types';
import { useMemo } from 'react';

export interface ProductsPickerProps {
    setCurrentProducts: (value: number) => void;
    currentProducts: number[];
    products: Product[];
}

export const ProductPicker = ({
    setCurrentProducts,
    currentProducts,
    products,
}: ProductsPickerProps) => {
    const storeSelectOptions = useMemo(() => {
        const options = products?.map((product) => ({
            label: product.label,
            value: product.id,
        }));
        options?.push({ label: 'Tous les produits', value: 0 });
        return options;
    }, [products]);

    return (
        <div style={{ display: 'flex', flex: 2, margin: 6 }}>
            <FormControl fullWidth>
                <InputLabel>Produit</InputLabel>
                <Select
                    label="Boutique"
                    defaultValue={0}
                    onChange={(e) => setCurrentProducts(e.target.value as number)}
                    value={currentProducts.length === 1 ? currentProducts[0] : 0}
                >
                    {storeSelectOptions?.map((store) => (
                        <MenuItem key={store.value} value={store.value}>
                            {store.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
