import { Autocomplete, Checkbox, ListItemText, MenuItem, TextField } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { IndexOptionValue, MenuOption, OptionValue } from '../containers/MenuOptions';

import { ThemeColors } from '../../utils/theme/colors.theme';
import { ChipOptions } from '../containers/ChipOptions';

export interface SelectMultipleRhfProps<T extends FieldValues> {
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form
    options: MenuOption[];
    disabled?: boolean;
}

const SelectMultipleRhf = <T extends FieldValues>(props: SelectMultipleRhfProps<T>) => {
    const { control, name, options, disabled } = props;

    const {
        field: { onChange, onBlur: rhfOnBlur, value },
    } = useController({
        name,
        control,
    });

    // Value can be undefined / null if loaded from a react-query.
    // If so, the component does not automatically refresh on data resolution.
    // The notNullValue serves as a guard against this, to keep the component responsive
    const notNullValue = value ?? ([] as PathValue<T, Path<T>>);

    const getFakeLabelFromValue = useCallback((option: OptionValue): string => {
        if (Array.isArray(option)) {
            return `${option.map((v) => getFakeLabelFromValue(v)).join('-')}-array`;
        }
        return option?.toString() ?? '';
    }, []);

    // Memoize helper objects to avoid heavy operations on every change
    const { labelFromValue, valueFromLabel, values, isHelperFromValue } = useMemo(() => {
        // eslint-disable-next-line no-shadow
        const labelFromValue = options.reduce((result, option) => {
            result[getFakeLabelFromValue(option.value)] = option.label;
            return result;
        }, {} as Record<IndexOptionValue, string>);

        // eslint-disable-next-line no-shadow
        const valueFromLabel = options.reduce((result, option) => {
            result[option.label] = option.value;
            return result;
        }, {} as Record<string, OptionValue>);

        // eslint-disable-next-line no-shadow
        const values = options.map((option) => option.value);

        // eslint-disable-next-line no-shadow
        const isHelperFromValue = options.reduce((result, option) => {
            result[getFakeLabelFromValue(option.value)] = option.isHelper;
            return result;
        }, {} as Record<string, boolean | undefined>);

        return { labelFromValue, valueFromLabel, values, isHelperFromValue };
    }, [getFakeLabelFromValue, options]);

    const isHelper = (option: OptionValue) => {
        const fakeLabel = getFakeLabelFromValue(option);
        return isHelperFromValue[fakeLabel];
    };

    const getLabel = (option: OptionValue) => {
        const fakeLabel = getFakeLabelFromValue(option);
        return labelFromValue[fakeLabel];
    };

    // Change handler
    const handleCheck = (checked: boolean, option: OptionValue) => {
        if (Array.isArray(option)) {
            let tempValue = [...notNullValue];

            const allOptionsIncluded = option.every((v) =>
                tempValue.includes(v as IndexOptionValue)
            );

            if (allOptionsIncluded) {
                // Retirer toutes les valeurs du tableau 'option'
                tempValue = tempValue.filter((val) => !option.includes(val as IndexOptionValue));
            } else {
                // Ajouter les valeurs manquantes du tableau 'option'
                option.forEach((v) => {
                    const optionValue = v as IndexOptionValue;
                    if (!tempValue.includes(optionValue)) {
                        tempValue.push(optionValue);
                    }
                });
            }

            onChange(tempValue as PathValue<T, Path<T>>);
        } else {
            const optionValue = option as IndexOptionValue;
            if (checked) {
                // Add to selection if not already included
                if (!notNullValue.includes(optionValue)) {
                    onChange([...notNullValue, optionValue] as PathValue<T, Path<T>>);
                } else {
                    // If already checked : remove from selection
                    onChange(notNullValue.filter((v: IndexOptionValue) => v !== optionValue));
                }
            } else {
                // If not checked : remove from selection
                onChange(notNullValue.filter((v: IndexOptionValue) => v !== optionValue));
            }
        }
    };

    const isChecked = (option: OptionValue) => {
        if (Array.isArray(option)) {
            return option.every((v) => {
                const optionValue = v as IndexOptionValue;
                return notNullValue.includes(optionValue);
            });
        }
        return notNullValue.includes(valueFromLabel[labelFromValue[option as IndexOptionValue]]);
    };

    return (
        <Autocomplete
            disabled={disabled}
            noOptionsText="Aucune option"
            multiple
            fullWidth
            autoHighlight
            onBlur={rhfOnBlur}
            renderTags={(e) => {
                const tagsLabels = e.map((tag) => labelFromValue[tag as IndexOptionValue]);
                return (
                    <div
                        style={{
                            maxHeight: '150px',
                            overflowY: 'auto',
                            direction: 'rtl',
                            scrollbarWidth: 'thin',
                            scrollbarColor: 'dark',
                        }}
                    >
                        <div style={{ direction: 'ltr' }}>
                            <ChipOptions tags={tagsLabels} />
                        </div>
                    </div>
                );
            }}
            value={notNullValue.map((v: IndexOptionValue) => v)}
            renderOption={(_, option) => (
                <MenuItem
                    key={option}
                    value={option}
                    onClick={() => handleCheck(!isChecked(option), option)}
                    sx={
                        isHelper(option)
                            ? {
                                  backgroundColor: isChecked(option)
                                      ? ThemeColors.LIGHT_GREEN
                                      : ThemeColors.GREY_20,
                                  textAlign: 'center',
                              }
                            : {}
                    }
                >
                    {!isHelper(option) ? <Checkbox checked={isChecked(option)} /> : null}
                    <ListItemText primary={getLabel(option)} />
                </MenuItem>
            )}
            renderInput={(params) => <TextField {...params} />}
            options={values}
            onChange={(_, v) => {
                if (Array.isArray(v) && v.length === 0) {
                    onChange([] as PathValue<T, Path<T>>);
                }
            }}
        />
    );
};

export default SelectMultipleRhf;
