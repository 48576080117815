import {
    deleteUser,
    sendBanEmail,
    updateStudentPromoPercentage,
    useAppUsers,
    useBoUsers,
    useMe,
    usePaymentDefaultUsers,
    useStudentReduction,
    useStudentUsers,
    validateStudentStatus,
} from '@gozoki/api';
import {
    Box,
    Button,
    InputAdornment,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ROUTE_BUILDER, USERS_ROUTES } from '../../utils/constants/routes.constants';

import { includesLowerCase } from '@gozoki/tools';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import { useToast } from '../../components/communication/Toast';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import PlusIcon from '../../components/icons/PlusIcon';
import { useRights } from '../../utils/hooks';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { fonts } from '../../utils/theme/fonts.theme';
import { UserRolesCell } from './UserRolesCell';

interface ValidateUserStatus {
    userId: number;
    status: boolean;
}

const usersColumnDefs: (
    currentTab: number,
    sendEmail: MutationFunction,
    handleValidateStudentStatus: null | ((u: ValidateUserStatus) => void)
) => GridColDef[] = (
    currentTab: number,
    sendEmail: MutationFunction,
    handleValidateStudentStatus: null | ((u: ValidateUserStatus) => void)
) => {
    if (currentTab === 3) {
        // this is admin mode
        return [
            {
                field: 'firstName',
                headerName: 'Prénom',
                width: 140,
            },
            {
                field: 'lastName',
                headerName: 'Nom',
                width: 140,
            },
            {
                field: 'store',
                headerName: 'Boutique',
                width: 140,
                valueGetter: (params) => params.row.store?.label ?? '',
            },
            { field: 'email', headerName: 'Email', width: 300 },
            {
                field: 'adminRights',
                headerName: 'Roles',
                renderCell: UserRolesCell,
                minWidth: 200,
                flex: 1,
            },
            {
                field: 'id',
                headerName: 'ID',
                type: 'number',
                width: 70,
            },
        ];
    }
    if (currentTab === 1) {
        // this is student mode
        return [
            {
                field: 'firstName',
                headerName: 'Prénom',
                width: 140,
            },
            {
                field: 'lastName',
                headerName: 'Nom',
                width: 140,
            },
            { field: 'email', headerName: 'Email', width: 300 },
            {
                field: 'store',
                headerName: 'Boutique',
                width: 140,
                valueGetter: (params) => params.row.store?.label ?? '',
            },
            {
                field: 'studentDocumentRectoUrl',
                headerName: 'Voir le justificatif (recto)',
                renderCell: (params) => {
                    return (
                        <Box>
                            {params.value ? (
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => {
                                        window.open(params.value as string, '_blank');
                                    }}
                                >
                                    Voir
                                </Button>
                            ) : (
                                <Box>-</Box>
                            )}
                        </Box>
                    );
                },
                minWidth: 200,
                flex: 1,
            },
            {
                field: 'studentDocumentVersoUrl',
                headerName: 'Voir le justificatif (verso)',
                renderCell: (params) => {
                    return (
                        <Box>
                            {params.row.studentDocumentVersoUrl ? (
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => {
                                        window.open(
                                            params.row.studentDocumentVersoUrl as string,
                                            '_blank'
                                        );
                                    }}
                                >
                                    Voir
                                </Button>
                            ) : (
                                <Box>-</Box>
                            )}
                        </Box>
                    );
                },
                minWidth: 200,
                flex: 1,
            },
            {
                field: 'isValidatedStudent',
                headerName: 'Statut',
                renderCell: (params) => {
                    return params.row.isValidatedStudent === null
                        ? 'En attente'
                        : `${params.row.isValidatedStudent ? 'Validé' : 'Non validé'}`;
                },
                minWidth: 200,
            },
            {
                field: '_isStudent1',
                headerName: '',
                renderCell: (params) => {
                    return params.row.isValidatedStudent !== true ? (
                        <Button
                            variant="contained"
                            color="success"
                            disabled={!handleValidateStudentStatus}
                            onClick={() => {
                                handleValidateStudentStatus?.({
                                    userId: params.row.id,
                                    status: true,
                                });
                            }}
                        >
                            Valider
                        </Button>
                    ) : (
                        <p> - </p>
                    );
                },
            },
            {
                field: '_isStudent2',
                headerName: '',
                renderCell: (params) => {
                    return params.row.isValidatedStudent !== false ? (
                        <Button
                            variant="contained"
                            color="error"
                            disabled={!handleValidateStudentStatus}
                            onClick={() => {
                                handleValidateStudentStatus?.({
                                    userId: params.row.id,
                                    status: false,
                                });
                            }}
                        >
                            Invalider
                        </Button>
                    ) : (
                        <p> - </p>
                    );
                },
            },
            {
                field: 'id',
                headerName: 'ID',
                type: 'number',
            },
        ];
    }
    if (currentTab === 2) {
        // this is default payment mode
        return [
            {
                field: 'firstName',
                headerName: 'Prénom',
                width: 140,
            },
            {
                field: 'lastName',
                headerName: 'Nom',
                width: 140,
            },
            { field: 'email', headerName: 'Email', width: 300 },
            {
                field: 'store',
                headerName: 'Boutique',
                width: 140,
                valueGetter: (params) => params.row.store?.label ?? '',
            },
            {
                field: 'unBanAmount',
                headerName: 'Montant',
                type: 'number',
                width: 150,
            },
            {
                field: 'id_2',
                headerName: 'Actions',
                renderCell: (params) => (
                    <Button
                        variant="contained"
                        onClick={() => sendEmail(params.row.id as number)}
                        disabled={params.row.isBanned === false}
                    >
                        Envoyer un email
                    </Button>
                ),
                width: 200,
            },
        ];
    }
    if (currentTab === 0) {
        // this is the default mode : app users
        return [
            {
                field: 'firstName',
                headerName: 'Prénom',
                width: 140,
            },
            {
                field: 'lastName',
                headerName: 'Nom',
                width: 140,
            },
            { field: 'email', headerName: 'Email', width: 300 },
            {
                field: 'store',
                headerName: 'Boutique',
                width: 140,
                valueGetter: (params) => params.row.store?.label ?? '',
            },
            {
                field: 'isBanned',
                headerName: 'Banni',
                type: 'boolean',
            },
            {
                field: 'isReliable',
                headerName: 'Est solvable',
                type: 'boolean',
            },
            {
                field: 'payzenPaymentTokens',
                headerName: 'Cartes de paiments',
                valueFormatter: (params) => params.value.length,
                type: 'number',
                width: 140,
            },
            {
                field: 'payzenConecsPaymentTokens',
                headerName: 'Cartes conecs',
                valueFormatter: (params) => params.value.length,
                type: 'number',
                width: 140,
            },
            {
                field: 'id',
                headerName: 'ID',
                type: 'number',
                width: 70,
            },
        ];
    }
    return [];
};

const UsersPage = () => {
    const navigate = useNavigate();
    const isUserSuperAdmin = useRights(['SUPER_ADMIN']);
    const isAdmin = useRights(['ADMIN']);

    const [userSearch, setUserSearch] = useState('');
    const [userSelected, setUserSelected] = useState<number[]>([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [studentPromoPercentage, setStudentPromoPercentage] = useState(0);

    const me = useMe();
    const [Toast, showToast] = useToast();

    const {
        data: appUsers,
        refetch: refetchAppUsers,
        isLoading: isLoadingAppUsers,
    } = useAppUsers();

    const {
        data: studentUsers,
        refetch: refetchStudentUsers,
        isLoading: isLoadingStudentUsers,
    } = useStudentUsers();

    const { data: boUsers, refetch: refetchBoUsers, isLoading: isLoadingBoUsers } = useBoUsers();

    const {
        data: paymentDefaultUSers,
        refetch: refetchPaymentDefaultUsers,
        isLoading: isLoadingPaymentDefaultUsers,
    } = usePaymentDefaultUsers();

    const {
        data: studentPromoPercentageData,
        refetch: refetchStudentPromoPercentage,
        isLoading: isLoadingStudentPromoPercentage,
    } = useStudentReduction();

    useEffect(() => {
        setStudentPromoPercentage(studentPromoPercentageData ?? 0);
    }, [studentPromoPercentageData]);

    const isLoading = useMemo(() => {
        if (currentTab === 0) return isLoadingAppUsers;
        if (currentTab === 1) return isLoadingStudentUsers || isLoadingStudentPromoPercentage;
        if (currentTab === 2) return isLoadingPaymentDefaultUsers;
        if (currentTab === 3) return isLoadingBoUsers;

        return false;
    }, [
        currentTab,
        isLoadingAppUsers,
        isLoadingBoUsers,
        isLoadingPaymentDefaultUsers,
        isLoadingStudentPromoPercentage,
        isLoadingStudentUsers,
    ]);

    const refreshUsers = useCallback(() => {
        if (currentTab === 0) return refetchAppUsers();
        if (currentTab === 1) return refetchStudentUsers();
        if (currentTab === 2) return refetchPaymentDefaultUsers();
        if (currentTab === 3) return refetchBoUsers();

        return Promise.resolve();
    }, [
        currentTab,
        refetchAppUsers,
        refetchBoUsers,
        refetchPaymentDefaultUsers,
        refetchStudentUsers,
    ]);

    const { mutate: mutateUserStatus } = useMutation(
        ({ userId, status }: { userId: number; status: boolean }) =>
            validateStudentStatus(userId, status),
        {
            onSuccess: () => {
                showToast({
                    message: "Statut de l'étudiant modifié",
                    severity: 'success',
                });
                refreshUsers();
            },
            onError: (error) => {
                showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
            },
        }
    );

    const handleValidatedStudentStatus = isAdmin
        ? (u: ValidateUserStatus) => {
              mutateUserStatus({ userId: u.userId, status: u.status });
          }
        : null;

    // ****************************************************** //
    //           QUERY TO DATAGRID TRANSFORMATION             //
    // ****************************************************** //

    const users = useMemo(() => {
        if (currentTab === 0) return appUsers ?? [];
        if (currentTab === 1) return studentUsers ?? [];
        if (currentTab === 2) return paymentDefaultUSers ?? [];
        if (currentTab === 3) return boUsers ?? [];

        return [];
    }, [appUsers, boUsers, currentTab, paymentDefaultUSers, studentUsers]);

    const userRows = useMemo(() => {
        if (!users) return [];
        return users.filter((user) =>
            includesLowerCase(`${user.firstName} ${user.lastName} ${user.email}`, userSearch)
        );
    }, [userSearch, users]);

    const { mutate: deleteOneUser } = useMutation((id: number) => deleteUser(id), {
        onSuccess: () => {
            showToast({
                message: 'Utilisateur supprimé',
                severity: 'success',
            });
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const { mutate: mutateUpdateStudentPromoPercentage } = useMutation(
        updateStudentPromoPercentage,
        {
            onSuccess: async () => {
                await refetchStudentPromoPercentage();
                showToast({
                    message: 'Promo étudiant mise à jour',
                    severity: 'success',
                });
            },
            onError: (error) => {
                showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
            },
        }
    );

    const handleDelete = async () => {
        return new Promise((resolve, reject) => {
            const usersTodelete = userSelected.map((i) => users.find((user) => user.id === i));
            // avoid deleting the current user, an admin or a CPI user
            if (
                usersTodelete?.some((user) => user?.id === me.data?.id || user?.adminRights !== 0b0)
            ) {
                showToast({
                    message: 'Impossible de supprimer un utilisateur avec droits particuliers',
                    severity: 'error',
                });
                reject(
                    new Error('Impossible de supprimer un utilisateur avec droits particuliers')
                );
            } else {
                Promise.all(usersTodelete?.map((user) => deleteOneUser(user?.id ?? 0)) ?? [])
                    .then(() => {
                        setUserSelected([]);
                        refreshUsers().then(() => resolve(true));
                    })
                    .catch((error) => {
                        showToast({
                            message: getErrorMessageWithSentry(error),
                            severity: 'error',
                        });
                        reject(error);
                    });
            }
        });
    };

    const sendEmailMut = useMutation((id: number) => sendBanEmail(id), {
        onSuccess: () => {
            showToast({
                message: 'Email envoyé',
                severity: 'success',
            });
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    return (
        <Page>
            <Toast />
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Utilisateurs
                </Typography>
                <Button
                    variant="contained"
                    sx={{ marginRight: '16px' }}
                    onClick={() => navigate(USERS_ROUTES.ADD_USER)}
                    disabled={!isUserSuperAdmin}
                >
                    <PlusIcon />
                    Ajouter un utilisateur
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: '16px' }}
                    onClick={() => navigate(ROUTE_BUILDER.updateUser(userSelected[0] ?? 0))}
                    disabled={!isUserSuperAdmin || userSelected.length !== 1}
                >
                    Modifier
                </Button>
                {isAdmin ? (
                    <ConfirmResourceDeletionButton
                        disabled={userSelected.length === 0}
                        handleResourceDeletion={handleDelete}
                    />
                ) : null}
            </div>
            <Tabs value={currentTab} onChange={handleChange}>
                <Tab label="Utilisateurs de l'app" />
                <Tab label="Étudiants" />
                <Tab label="Défauts de paiement" />
                {isUserSuperAdmin ? <Tab label="Utilisateurs du BO" /> : null}
            </Tabs>
            {isUserSuperAdmin && currentTab === 1 ? (
                <Paper
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                        <TextField
                            variant="outlined"
                            label="Remise étudiante"
                            value={studentPromoPercentage}
                            onChange={(e) => setStudentPromoPercentage(Number(e.target.value))}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            variant="contained"
                            onClick={() =>
                                mutateUpdateStudentPromoPercentage(studentPromoPercentage)
                            }
                            disabled={isLoadingStudentPromoPercentage}
                            sx={{ padding: 3 }}
                        >
                            Modifier le pourcentage de réduction
                        </Button>
                    </div>
                </Paper>
            ) : null}
            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <TextField
                        variant="outlined"
                        label="Recherche"
                        value={userSearch}
                        onChange={(e) => setUserSearch(e.target.value)}
                    />
                    <div style={{ flexGrow: 1 }} />
                </div>
                <SimpleDataGrid
                    columns={usersColumnDefs(
                        currentTab,
                        sendEmailMut.mutate as MutationFunction,
                        handleValidatedStudentStatus
                    )}
                    rows={userRows}
                    loading={isLoading}
                    checkboxSelection={currentTab !== 1}
                    onRowSelectionModelChange={(newSelection) =>
                        setUserSelected(newSelection as number[])
                    }
                    disableRowSelectionOnClick={currentTab === 1}
                />
            </Paper>
        </Page>
    );
};

export default UsersPage;
