/* eslint-disable no-restricted-syntax */
import { StockEntry, Store } from '@gozoki/api-types';

import { DateTime } from 'luxon';
import { centRound } from '@gozoki/tools';
import jsPDF from 'jspdf';
import logo from '../../assets/logos/logo.png';

export type Font = {
    name: string;
    style: string;
    size: number;
    color: string;
};

// define fonts
const classicBold = {
    name: 'helvetica',
    style: 'bold',
    size: 10,
    color: 'black',
};

const bigTitle = {
    name: 'helvetica',
    style: 'bold',
    size: 18,
    color: 'black',
};

const bigSubtitle = {
    name: 'helvetica',
    style: 'bold',
    size: 14,
    color: 'grey',
};

const classic = {
    name: 'helvetica',
    style: 'normal',
    size: 10,
    color: 'black',
};

const classicGrey = {
    name: 'helvetica',
    style: 'normal',
    size: 9,
    color: 'grey',
};

const small = {
    name: 'helvetica',
    style: 'normal',
    size: 8,
    color: 'black',
};

const setFont = (doc: jsPDF, font: Font) => {
    doc.setFont(font.name, font.style);
    doc.setFontSize(font.size);
    if (font.color === 'grey') doc.setTextColor(128);
    else doc.setTextColor(0);
};

const productSpaces = {
    label: 15,
    ean13: 70,
    dun14: 100,
    poids: 130,
    articles: 155,
    colis: 180,
};

const writeProductLine = (
    doc: jsPDF,
    y: number,
    product: StockEntry,
    commandType: 'command' | 'delivery'
) => {
    setFont(doc, classicGrey);
    doc.text(product.label.slice(0, 32), productSpaces.label, y);
    doc.text(product.reference.replace(/\s+/g, ''), productSpaces.ean13, y);
    doc.text(product.dun14.replace(/\s+/g, ''), productSpaces.dun14, y);
    if (commandType === 'command') {
        doc.text((product.reaproWeight / 1000).toString(), productSpaces.poids, y);
    } else {
        doc.text((product.totalWeight / 1000).toString(), productSpaces.poids, y);
    }
    if (commandType === 'command') {
        doc.text(product.articlesReaproNeeds.toString(), productSpaces.articles, y);
    } else {
        doc.text(product.stock.toString(), productSpaces.articles, y);
    }
    if (commandType === 'command') {
        doc.text(product.reaproNeeds.toString(), productSpaces.colis, y);
    } else {
        doc.text(centRound(product.stock / product.colisage).toString(), productSpaces.colis, y);
    }

    doc.line(10, y + 3, 200, y + 3);
};

const chunkArray: <T>(array: T[], chunkSize: number) => T[][] = (array, chunkSize) => {
    let index = 0;
    const arrayLength = array.length;
    const tempArray = [];

    for (index = 0; index < arrayLength; index += chunkSize) {
        const chunk = array.slice(index, index + chunkSize);
        tempArray.push(chunk);
    }

    return tempArray;
};

export const generatePage = (
    doc: jsPDF,
    page: number,
    totalPage: number,
    reaproNeeds: StockEntry[],
    receivingStoreInfos: {
        commandNumber: string;
        label: string;
        address1: string;
        address2: string;
        address3: string;
    },
    shippingStoreInfos: {
        label: string;
        address1: string;
        address2: string;
        address3: string;
    },
    dateDepart: DateTime,
    dateLivraison: DateTime,
    commandType: 'delivery' | 'command'
) => {
    // Titre du document
    setFont(doc, bigTitle);
    doc.text(commandType === 'command' ? 'BON DE COMMANDE' : 'BON DE LIVRAISON', 15, 15);
    setFont(doc, classic);
    doc.text(`(Page ${page})`, 80, 15);

    doc.addImage(logo, 'PNG', 170, 8, 27.5, 16);

    // Informations de la boutique
    setFont(doc, bigSubtitle);
    doc.text(receivingStoreInfos.label, 15, 22);

    // En-têtes des sections

    const vp1 = {
        title: 34,
        value: 40,
    }; // vertical places
    const numM = 15; // margin numéro
    const dateDM = 80; // margin date départ
    const dateLM = 145; // margin date livraison

    setFont(doc, classicBold);
    doc.text('Numéro de bon:', numM, vp1.title);
    doc.text(
        commandType === 'command' ? 'Date de génération du bon:' : 'Date de départ:',
        dateDM,
        vp1.title
    );
    doc.text(
        commandType === 'command' ? 'Date de livraison souhaitée:' : 'Date de livraison:',
        dateLM,
        vp1.title
    );

    // Contenu des sections
    setFont(doc, classicGrey);
    doc.text(receivingStoreInfos.commandNumber, numM, vp1.value);
    doc.text(dateDepart.toFormat('dd/MM/yyyy'), dateDM, vp1.value);
    doc.text(dateLivraison.toFormat('dd/MM/yyyy'), dateLM, vp1.value);

    doc.line(10, 48, 200, 48);

    // Expéditeur & Destinataire

    const expM = commandType === 'command' ? -1000 : 15; // margin expéditeur
    const destM = commandType === 'command' ? 15 : 125; // margin destinataire
    const vp2 = {
        title: 55,
        value: 62,
        address1: 69,
        address2: 73,
        address3: 77,
        info1: 84,
        info2: 88,
        info3: 92,
    }; // vertical places

    // libellés
    setFont(doc, classicBold);
    doc.text('Expéditeur', expM, vp2.title);
    doc.text(commandType === 'command' ? 'Adresse' : 'Destinataire', destM, vp2.title);

    setFont(doc, classicGrey);
    doc.text(shippingStoreInfos.label, expM, vp2.value);
    doc.text('LUCIE, LA CABANE AUTONOME', destM, vp2.value);

    // adresses
    setFont(doc, small);

    doc.text(shippingStoreInfos.address1, expM, vp2.address1);
    doc.text(shippingStoreInfos.address2, expM, vp2.address2);
    doc.text(shippingStoreInfos.address3, expM, vp2.address3);

    doc.text(receivingStoreInfos.address1, destM, vp2.address1);
    doc.text(receivingStoreInfos.address2, destM, vp2.address2);
    doc.text(receivingStoreInfos.address3, destM, vp2.address3);

    // Compléments
    setFont(doc, small);

    doc.text('SIRET: 977 672 732 000012', expM, vp2.info1);
    doc.text('NAF : 4799B', expM, vp2.info2);
    doc.text('N° intracommunautaire : FR11 977 672 732', expM, vp2.info3);

    doc.line(10, 97, 200, 97);

    const startTable = 120;

    // Tableau des produits (en tete)
    setFont(doc, classicBold);

    doc.text('Nom du produit', productSpaces.label, startTable);
    doc.text('EAN 13', productSpaces.ean13, startTable);
    doc.text('DUN 14', productSpaces.dun14, startTable);
    doc.text('Poids (kg)', productSpaces.poids, startTable);
    doc.text('Nb Articles', productSpaces.articles, startTable);
    doc.text('Nb Colis', productSpaces.colis, startTable);

    doc.line(10, startTable + 5, 200, startTable + 5, 'F');

    // Tableau des produits (contenu)
    let y = startTable + 10;

    for (const product of reaproNeeds) {
        writeProductLine(doc, y, product, commandType);
        y += 8;
    }

    const totalColis = reaproNeeds.reduce(
        (acc, product) =>
            acc +
            (commandType === 'command' ? product.reaproNeeds : product.stock / product.colisage),
        0
    );
    const totalArticles = reaproNeeds.reduce(
        (acc, product) =>
            acc + (commandType === 'command' ? product.articlesReaproNeeds : product.stock),
        0
    );

    y += 8;

    setFont(doc, classicGrey);
    doc.text('Total articles page : ', 150, y);
    doc.text(centRound(totalArticles).toString(), 186, y);

    y += 6;
    doc.text('Total colis page : ', 150, y);
    doc.text(centRound(totalColis).toString(), 186, y);

    doc.line(10, 272, 200, 272);

    // Pied de page
    setFont(doc, classicGrey);

    doc.text(`Page ${page} sur ${totalPage}`, 15, 284);
    doc.text(' 06 83 24 67 23', 120, 284);
    doc.text('|', 154, 284);
    doc.text('contact@lucie-cabane.fr', 164, 284);
};

export const generatePDF = async (
    reaproNeeds: StockEntry[],
    receivingStore: Store,
    shippingStore: Store | undefined,
    dateDepart: DateTime,
    dateLivraison: DateTime,
    commandType: 'delivery' | 'command',
    commandReference: string
) => {
    // receiving store infos
    const receivingStoreLabel = receivingStore.label ?? '';
    const receivingStoreAddress1 = receivingStore.address.address ?? '';
    const receivingStoreAdress2 = `${receivingStore.address.postalCode ?? ''} ${
        receivingStore.address.city ?? ''
    }`;
    const receivingStoreAdress3 = `${receivingStore.address.addressAdditionnal ?? 'France'}`;

    const shippingStoreLabel = shippingStore?.label ?? '';
    const shippingStoreAddress1 = shippingStore?.address.address ?? '';
    const shippingStoreAddress2 = `${shippingStore?.address.postalCode ?? ''} ${
        shippingStore?.address.city ?? ''
    }`;
    const shippingStoreAddress3 = `${shippingStore?.address.addressAdditionnal ?? 'France'}`;

    const receivingStoreInfos = {
        commandNumber: commandReference,
        label: receivingStoreLabel,
        address1: receivingStoreAddress1,
        address2: receivingStoreAdress2,
        address3: receivingStoreAdress3,
    };

    const shippingStoreInfos = {
        label: shippingStoreLabel,
        address1: shippingStoreAddress1,
        address2: shippingStoreAddress2,
        address3: shippingStoreAddress3,
    };

    // Crée une instance de jsPDF
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc.setDrawColor(128);

    const pages = chunkArray(reaproNeeds, 12);

    for (const [index, page] of pages.entries()) {
        generatePage(
            doc,
            index + 1,
            pages.length,
            page,
            receivingStoreInfos,
            shippingStoreInfos,
            dateDepart,
            dateLivraison,
            commandType
        );
        if (index < pages.length - 1) doc.addPage();
    }

    if (pages.length === 0) {
        generatePage(
            doc,
            1,
            1,
            [],
            receivingStoreInfos,
            shippingStoreInfos,
            dateDepart,
            dateLivraison,
            commandType
        );
    }

    const totalArticles = reaproNeeds.reduce(
        (acc, product) =>
            acc + (commandType === 'command' ? product.articlesReaproNeeds : product.stock),
        0
    );

    const totalColis = reaproNeeds.reduce(
        (acc, product) =>
            acc +
            (commandType === 'command' ? product.reaproNeeds : product.stock / product.colisage),
        0
    );
    const totalWeight = reaproNeeds.reduce(
        (acc, product) =>
            acc + (commandType === 'command' ? product.reaproWeight : product.totalWeight),
        0
    );

    setFont(doc, classicBold);

    doc.text('Total articles : ', 150, 255);
    doc.text(totalArticles.toString(), 186, 255);

    doc.text('Total colis : ', 150, 261);
    doc.text(centRound(totalColis).toString(), 186, 261);

    doc.text('Total poids (kg) : ', 150, 267);
    doc.text(centRound(totalWeight / 1000).toString(), 186, 267);

    // Sauvegarde le document
    doc.save('bdl.pdf');
};
