import { Button, Chip, Paper, TextField, Typography } from '@mui/material';
import { deleteWorksConcil, useWorksConcils } from '@gozoki/api';
import { useCallback, useMemo, useState } from 'react';

import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import { ENTREPRISES_ROUTES } from '../../utils/constants/routes.constants';
import { GridColDef } from '@mui/x-data-grid';
import Page from '../../components/Page';
import PlusIcon from '../../components/icons/PlusIcon';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { fonts } from '../../utils/theme/fonts.theme';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { includesLowerCase } from '@gozoki/tools';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRights } from '../../utils/hooks';
import { useToast } from '../../components/communication/Toast';

const TAB_WIDTH = 160;
const BIGGER_TAB = 220;

const worksConcilColDefs: GridColDef[] = [
    {
        field: 'label',
        headerName: 'Label',
        width: TAB_WIDTH,
    },
    {
        field: 'maxMonthAmountPerEmployee',
        headerName: 'Montant max par mois',
        width: BIGGER_TAB,
    },
    { field: 'gozokiPercentage', headerName: 'Pourcentage gozoki', width: BIGGER_TAB },
    {
        field: 'worksConcilPercentage',
        headerName: 'Pourcentage CE',
        width: BIGGER_TAB,
    },
    { field: 'numberOfEmployees', headerName: "Nombre d'employés", width: TAB_WIDTH },
    {
        field: 'numberOfActivesEmployees',
        headerName: 'dont actifs',
        width: TAB_WIDTH,
    },
    {
        field: 'totalUsedAmount',
        headerName: 'Montant total utilisé',
        width: TAB_WIDTH,
        renderCell: (field) => `${field.value.toLocaleString('fr-FR')} €`,
    },
    {
        field: 'isActive',
        headerName: 'Activé',
        width: TAB_WIDTH,
        renderCell: (field) => (field.value === 'oui' ? <Chip label={field.value} /> : null),
    },
];

const EntreprisesPage = () => {
    const navigate = useNavigate();
    const { data: worksConcils, isLoading: isLoadingWorksConcils, refetch } = useWorksConcils();
    const [Toast, showToast] = useToast();

    const [worksConcilSearch, setWorksConcilSearch] = useState<string>('');
    const [worksConcilsSelection, setWorksConcilsSelection] = useState<number[]>([]);

    const isAdmin = useRights(['ADMIN']);

    const concilsWorksRows = useMemo(() => {
        if (worksConcils === undefined) {
            return [];
        }
        return worksConcils
            .filter((worksConcil) => includesLowerCase(worksConcil.label, worksConcilSearch))
            .map((worksConcil) => ({
                id: worksConcil.id, // Datagrid requires a unique id field
                label: worksConcil.label,
                maxMonthAmountPerEmployee: worksConcil.maxMonthAmountPerEmployee,
                gozokiPercentage: worksConcil.gozokiPercentage,
                worksConcilPercentage: worksConcil.worksConcilPercentage,
                numberOfEmployees: worksConcil.numberOfEmployees,
                totalUsedAmount: worksConcil.totalUsedAmount,
                numberOfActivesEmployees: worksConcil.numberOfActivesEmployees,
                isActive: worksConcil.isActive ? 'oui' : 'non',
            }));
    }, [worksConcils, worksConcilSearch]);

    const { mutate: deleteOne, isLoading: isDeleting } = useMutation(
        (id: number) => deleteWorksConcil(id),
        {
            onSuccess: () => {
                setWorksConcilsSelection([]);
                refetch();
            },
            onError: (error) => {
                showToast({
                    severity: 'error',
                    message: getErrorMessageWithSentry(error),
                });
            },
        }
    );

    const handleDeleteWorksConcil = useCallback(async () => {
        worksConcilsSelection.forEach((id) => {
            deleteOne(id);
        });
    }, [deleteOne, worksConcilsSelection]);

    const navigateAndUpdateWorksConcil = useCallback(() => {
        if (worksConcilsSelection.length === 1) {
            navigate(`/entreprises/update_entreprise/${worksConcilsSelection[0]}/first-step`);
        } else {
            showToast({
                severity: 'warning',
                message: 'Vous devez sélectionner une entreprise',
            });
        }
    }, [worksConcilsSelection, navigate, showToast]);

    const globalLoading = useMemo(() => {
        return isLoadingWorksConcils || isDeleting;
    }, [isDeleting, isLoadingWorksConcils]);

    return (
        <Page>
            <Toast />
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Partenariats CE
                </Typography>
                <Button
                    variant="contained"
                    sx={{ marginRight: '16px' }}
                    onClick={() => {
                        navigate(ENTREPRISES_ROUTES.ADD_ENTREPRISE_FIRST_STEP);
                    }}
                    disabled={!isAdmin}
                >
                    <PlusIcon />
                    Ajouter un partenariat
                </Button>
            </div>
            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <TextField
                        variant="outlined"
                        label="Recherche"
                        value={worksConcilSearch}
                        onChange={(e) => setWorksConcilSearch(e.target.value)}
                    />
                    <div style={{ flexGrow: 1 }} />

                    <ConfirmResourceDeletionButton
                        handleResourceDeletion={handleDeleteWorksConcil}
                        disabled={worksConcilsSelection.length === 0 || !isAdmin}
                    />
                    <Button
                        variant="contained"
                        sx={{ height: '36px' }}
                        disabled={globalLoading}
                        onClick={navigateAndUpdateWorksConcil}
                    >
                        {isAdmin ? 'Modifier' : 'Voir'}
                    </Button>
                </div>
                <SimpleDataGrid
                    columns={worksConcilColDefs}
                    rows={concilsWorksRows}
                    loading={globalLoading}
                    onRowSelectionModelChange={(newSelection) =>
                        setWorksConcilsSelection(newSelection as number[])
                    }
                />
            </Paper>
        </Page>
    );
};

export default EntreprisesPage;
