export const MENUS = {
    MENU_PLAT_BOISSON: {
        defaultPrice: 4.5,
        defaultName: 'Menu Plat Boisson',
    },
    MENU_PLAT_BOISSON_DESSERT: {
        defaultPrice: 7,
        defaultName: 'Menu Plat Boisson Dessert',
    },
} as const;

export type MenuType = keyof typeof MENUS;

export const ALL_MENUS = Object.keys(MENUS) as MenuType[];

export const frenchFormat = (price: number) => {
    if (!price) return '0';
    if (Number.isInteger(price)) return `${price.toLocaleString('fr-FR')}`;
    return `${price.toLocaleString('fr-FR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })}`;
};

export const euroRoundPrice = (price: number) => {
    const newPrice = Math.floor(price * 2) / 2;
    return newPrice;
};

const defaultPromo = process.env.NODE_ENV === 'test' ? 40 : 30;

// default anti gaspi price is 70%
export const getAntiGaspiPrice = (price: number, promo = defaultPromo) => {
    return euroRoundPrice(price * ((100 - promo) / 100));
};

export const tauxPrice = (price: number, taux: number) => {
    return euroRoundPrice(price * (1 - taux / 100 / 100));
};

export const centRound = (price: number) => {
    return Math.round(price * 100) / 100;
};
