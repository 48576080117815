export const RIGHTS = {
    DEFAULT: 0b0, //                    0   : Aucun droit spécifique
    SUPER_ADMIN: 0b1, //                1   : Le droit le plus élevé, inclut tous les autres droits
    CPI_GLOBAL: 0b10, //                2   : Droits spécifiques de CPI_GLOBAL
    CHECKPOINT: 0b100, //               4   : Droits spécifiques de CHECKPOINT
    ADMIN: 0b1000, //                   8   : Accès aux modifications hors finance et stock
    READER: 0b10000, //                 16  : Accès au BO en lecture seule hors finance et stock
    ACCOUNTING_ADMIN: 0b100000, //      32  : Accès aux modifications financières
    ACCOUNTING_READER: 0b1000000, //    64  : Accès aux finances en lecture seule
    STOCK_ADMIN: 0b10000000, //         128 : Accès aux modifications de stock
    STOCK_READER: 0b100000000, //       256 : Accès au stock en lecture seule
    QUALITY: 0b1000000000, //           512 : Accès aux droits qualité
    STORE_OPERATOR: 0b10000000000, //   1024: Accéder au magasin en tant que gestionnaire
    SUPPORT_OPERATOR: 0b100000000000, //         2048: Opérateur sur la plateforme
};

export type Right = keyof typeof RIGHTS;

export const RIGHTS_LABELS: { [K in Right]: string } = {
    DEFAULT: 'default',
    SUPER_ADMIN: 'Super admin',
    CPI_GLOBAL: 'CPI Global',
    CHECKPOINT: 'Checkpoint',
    ADMIN: 'Modification BO',
    READER: 'Lecture BO',
    ACCOUNTING_ADMIN: 'Modification Comptabilité',
    ACCOUNTING_READER: 'Lecture comptabilité',
    STOCK_ADMIN: 'Modification Stock',
    STOCK_READER: 'Lecture stock',
    QUALITY: 'Qualité & blocage',
    STORE_OPERATOR: 'Gestionnaire de magasin',
    SUPPORT_OPERATOR: 'Opérateur de support',
};

export const RIGHTS_KEYS = Object.keys(RIGHTS) as Right[];
