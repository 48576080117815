import { STORE_TYPES } from '@gozoki/api-types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const zStoreForm = z.object({
    checkpointStoreRef: z
        .string()
        .nonempty({ message: 'La référence du magasin est requise.' })
        .max(30, {
            message: 'La référence du magasin doit comporter au maximum 30 caractères.',
        }),
    label: z
        .string()
        .nonempty({ message: 'Le libellé est requis.' })
        .max(30, { message: 'Le libellé doit comporter au maximum 30 caractères.' }),

    // Address
    address: z
        .string()
        .nonempty({ message: "L'adresse est requise." })
        .max(100, { message: "L'adresse doit comporter au maximum 100 caractères." }),
    addressAdditionnal: z
        .string()
        .max(100, { message: "L'adresse additionnelle doit comporter au maximum 100 caractères." })
        .nullable(),
    postalCode: z
        .string()
        .nonempty({ message: 'Le code postal est requis.' })
        .max(20, { message: 'Le code postal doit comporter au maximum 20 caractères.' }),
    city: z
        .string()
        .nonempty({ message: 'La ville est requise.' })
        .max(30, { message: 'La ville doit comporter au maximum 30 caractères.' }),
    storeType: z.enum(STORE_TYPES),
});

export type StoreForm = z.infer<typeof zStoreForm>;

// Form hook
export const useStoreForm = (defaultValues?: Partial<StoreForm>) => {
    const form = useForm<StoreForm>({
        // Validation function
        resolver: zodResolver(zStoreForm),
        defaultValues: {
            checkpointStoreRef: '',
            label: '',
            address: '',
            addressAdditionnal: null,
            postalCode: '',
            city: '',
            storeType: STORE_TYPES[0], // 'BOUTIQUE'
            ...defaultValues,
        },
    });

    return form;
};
