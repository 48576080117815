import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

import { Category } from '@gozoki/api-types';

// Fetch one category
export const fetchCategory = async (id: number) => {
    const response = await gozokiApi.get<Category>(`/categories/${id}`);
    return response.data;
};

export const fetchActivesAndUsedCategories = async (storeId: number) => {
    const response = await gozokiApi.get<Category[]>(
        `stores/${storeId}/categories/actives-and-used`
    );
    return response.data;
};

// Fetch all categories
export const fetchCategories = async () => {
    const response = await gozokiApi.get<Category[]>('/categories');
    return response.data;
};

export const fetchMultipleCategories = async (ids: number[]) => {
    const data = { ids };
    const response = await gozokiApiWithAuth.post<Category[]>('/categories/show-multiple', data);
    return response.data;
};
