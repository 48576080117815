import { LogisticFileForm } from '../forms/useLogisticFileForm';
import { LogisticReportParams } from '../queries/logistic.queries';
import { gozokiApiWithAuth } from '../gozokiApi';

export const createLogisticFile = async (data: LogisticFileForm) => {
    const response = await gozokiApiWithAuth.post('/logistic/generate-file', data);
    return response;
};

export const downloadLogisticReport = async ({
    dateStart,
    dateEnd,
    storeId,
}: LogisticReportParams) => {
    const response = await gozokiApiWithAuth.get(
        `logistic/get-report/${storeId}/${dateStart}/${dateEnd}`,
        {
            responseType: 'blob',
        }
    );
    return response;
};
