export const firstLetter = (str: string) => str.slice(0, 1);

export const includesLowerCase = (str: string, search: string) =>
    str.toLowerCase().includes(search.toLowerCase());

/** Replace comma with point, in order to parse French number notation */
export const toInternationalNumber = (str: string) => str.replace(',', '.');

export const isNumber = (value: string) => {
    const parsedValue = parseFloat(toInternationalNumber(value));
    if (!Number.isNaN(parsedValue)) {
        return true;
    }
    return false;
};

/** Parse French float notation */
export const parseFrenchFloat = (str: string) => {
    return parseFloat(toInternationalNumber(str));
};

/** Create a string from a price */
export const priceToString = (price: number, suffix = '') => {
    let strPrice = price.toFixed(2);
    strPrice = strPrice.replace('.', ','); // use French notation

    return `${strPrice}€${suffix}`;
};

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
