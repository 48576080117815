import { Button, CircularProgress, IconButton, Paper, Typography } from '@mui/material';
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/constants/routes.constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import FileInputRhf from '../../components/inputs/FileInputRhf';
import { useForm } from 'react-hook-form';
import {
    ExcelUploadResponse,
    uploadProductsExcel,
    uploadProductsMinimumStockExcel,
} from '@gozoki/api';
import { useMutation } from '@tanstack/react-query';
import { _assert } from '@gozoki/tools';
import { useToast } from '../../components/communication/Toast';
import { useState } from 'react';
import ProductImportFeedback from '../../components/communication/ProductImportFeedback';
import Center from '../../components/containers/Center';
import { getErrorMessageWithSentry } from '../../utils/sentry';

const ImportProductPage = () => {
    const navigate = useNavigate();

    const { control, getValues, watch } = useForm<{ file: File }>();
    const [Toast, showToast] = useToast();

    const [uploadFeedback, setUploadFeedback] = useState<ExcelUploadResponse | undefined>(
        undefined
    );
    const [isUpdatingProductMinStock, setIsUpdatingProductMinStock] = useState(false);

    const cancel = () => {
        navigate(ROUTES.PRODUCTS);
    };

    const { mutate, isLoading } = useMutation(uploadProductsExcel, {
        onSuccess: (response) => {
            setIsUpdatingProductMinStock(false);
            setUploadFeedback(response.data);
        },
        onError: (error) => {
            showToast({
                severity: 'error',
                message: getErrorMessageWithSentry(error),
            });
        },
    });

    const { mutate: mutateUploadMinimumStock, isLoading: isLoadingMinimumStock } = useMutation(
        uploadProductsMinimumStockExcel,
        {
            onSuccess: (response) => {
                setIsUpdatingProductMinStock(true);
                setUploadFeedback(response.data);
            },
            onError: (error) => {
                showToast({
                    severity: 'error',
                    message: getErrorMessageWithSentry(error),
                });
            },
        }
    );

    const handleUpload = async () => {
        const file = getValues('file');

        _assert(file);

        mutate(file);
    };

    const handleUploadMinimumStock = async () => {
        const file = getValues('file');

        _assert(file);

        mutateUploadMinimumStock(file);
    };

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <Toast />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={cancel}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    Importer un fichier Excel
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleUpload}
                    disabled={isLoading || watch('file') === undefined}
                >
                    Importer
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleUploadMinimumStock}
                    disabled={isLoadingMinimumStock || watch('file') === undefined}
                    style={{ marginLeft: '16px' }}
                >
                    Importer les stocks minimum
                </Button>
            </div>
            <Paper sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}>
                <Typography mb="8px" style={fonts.inputHeader}>
                    Fichier
                </Typography>
                <FileInputRhf control={control} name="file" label="Fichier Excel" accept=".xlsx" />
                {isLoading && (
                    <Center style={{ marginTop: '32px' }}>
                        <CircularProgress />
                    </Center>
                )}
                {!isLoading && uploadFeedback && (
                    <ProductImportFeedback
                        response={uploadFeedback}
                        isCreatedProductErrorsDisabled={isUpdatingProductMinStock}
                    />
                )}
            </Paper>
        </Page>
    );
};

export default ImportProductPage;
