import { createTheme } from '@mui/material';
import { ThemeColors } from './colors.theme';
import { fonts } from './fonts.theme';

export const materialTheme = createTheme({
    palette: {
        primary: {
            main: ThemeColors.GREEN, // Primary main : green
        },
        secondary: {
            main: ThemeColors.ORANGE, // Secondary main : orange
        },
        background: {
            paper: ThemeColors.WHITE, // Background paper : white
        },
        info: {
            main: ThemeColors.GREY_60,
        },
        text: {
            primary: ThemeColors.BLACK,
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif', // Customize the default font family
        button: {
            textTransform: 'none', // Remove the uppercase transformation of the button text
        },
    },

    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        'boxShadow': '0px 1px 5px 0px #0000001F',
                        '&:hover': { boxShadow: '0px 2px 2px 0px #00000024' },
                        '&:active, &.Mui-focusVisible': {
                            boxShadow: '0px 3px 1px -2px #00000033',
                        },
                        'color': ThemeColors.WHITE,
                        'height': '42px',
                        ...fonts.button,
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        'borderRadius': '4px',
                        'border': '1px solid',
                        'height': '42px',
                        '&:hover': {
                            borderWidth: '1px',
                        },
                        '&:active, &.Mui-focusVisible': {},
                        ...fonts.button,
                    },
                },
            ],
        },
        MuiSelect: {
            defaultProps: {
                style: {
                    height: '48px',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-inputRoot': {
                        padding: '5px',
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'medium',
            },
        },
        MuiAvatar: {
            defaultProps: {
                style: {
                    backgroundColor: ThemeColors.AVATAR_BACKGROUND_GRAY,
                    ...fonts.avatar,
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                TabIndicatorProps: {
                    style: {
                        backgroundColor: ThemeColors.LIGHT_GREEN,
                    },
                },
            },
            styleOverrides: {
                root: {
                    '.Mui-selected': {
                        color: ThemeColors.LIGHT_GREEN,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ...fonts.tabTitle,
                    textTransform: 'uppercase',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                color: ThemeColors.BLACK,
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: '24px',
                },
            },
        },
    },
});
