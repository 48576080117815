import {
    destroyPromoCampaigns,
    generateUniqueCodesPromoCampaigns,
    updateStudentPromoPercentage,
    useGlobalParams,
    usePromoCampaigns,
} from '@gozoki/api';
import {
    Button,
    Chip,
    InputAdornment,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CAMPAIGNS_ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';

import { includesLowerCase } from '@gozoki/tools';
import { GridColDef } from '@mui/x-data-grid';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import GenerateUniqueUseCodeButton from '../../components/buttons/GenerateUniqueUseCodeButton';
import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import { useToast } from '../../components/communication/Toast';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import PlusIcon from '../../components/icons/PlusIcon';
import { useRights } from '../../utils/hooks';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { fonts } from '../../utils/theme/fonts.theme';

const promoCampaignColumnDefs: GridColDef[] = [
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
    },
    {
        field: 'name',
        headerName: 'Nom',
        flex: 1,
    },
    {
        field: 'isActive',
        headerName: 'Statut',
        flex: 1,
        renderCell: (field) => <Chip label={field.value ? 'Active' : 'Inactive'} />,
    },
];

const PromoCampaignsPage = () => {
    const navigate = useNavigate();
    const [Toast, showToast] = useToast();

    const isUserSuperAdmin = useRights(['SUPER_ADMIN']);
    const isAdmin = useRights(['ADMIN']);

    const [promoCampaignSearch, setPromoCampaignSearch] = useState('');
    const [campaignsSelection, setCampaignsSelection] = useState<number[]>([]);
    const [currentTab, setCurrentTab] = useState(0);

    const promoCampaigns = usePromoCampaigns();

    const { data: globalParams, refetch: refetchGlobalParams } = useGlobalParams();
    const [studentPromoPercentage, setStudentPromoPercentage] = useState<number>(
        globalParams?.studentPromoPercentage ?? 0
    );

    useEffect(() => {
        if (globalParams) {
            setStudentPromoPercentage(globalParams.studentPromoPercentage);
        }
    }, [globalParams]);

    // ****************************************************** //
    //           QUERY TO DATAGRID TRANSFORMATION             //
    // ****************************************************** //

    const promoCampaignsRows = useMemo(() => {
        if (promoCampaigns.data === undefined) {
            return [];
        }
        return promoCampaigns.data.filter((promoCampaign) =>
            includesLowerCase(promoCampaign.name, promoCampaignSearch)
        );
    }, [promoCampaigns.data, promoCampaignSearch]);

    // ****************************************************** //
    //                         HANDLERS                       //
    // ****************************************************** //

    const deleteCampaignMut = useMutation(destroyPromoCampaigns);

    const handleDeleteCampaign = async () => {
        const deletionPromise = deleteCampaignMut.mutateAsync(campaignsSelection, {
            onSuccess: () => {
                promoCampaigns.refetch();
            },
            onError: (error) => {
                showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
                console.error(error);
            },
        });
        setCampaignsSelection([]); // Avoid disabled button flickering
        return deletionPromise;
    };

    const generateUniqueCodeMut = useMutation(generateUniqueCodesPromoCampaigns);

    const navigateAndUpdateCampaign = async () => {
        navigate(ROUTE_BUILDER.updateCampaign(campaignsSelection[0]));
    };

    const campaignsDisabled =
        campaignsSelection.length === 0 ||
        deleteCampaignMut.isLoading ||
        generateUniqueCodeMut.isLoading;

    const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const { mutate, isLoading } = useMutation(updateStudentPromoPercentage, {
        onSuccess: async () => {
            showToast({ message: 'La remise étudiante a été mise à jour', severity: 'success' });
            await refetchGlobalParams();
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    const handleChangeStudentPromoPercentage = useCallback(() => {
        mutate(studentPromoPercentage);
    }, [mutate, studentPromoPercentage]);

    return (
        <Page>
            <Toast />
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Campagnes promotionnelles
                </Typography>
                <Button
                    variant="contained"
                    sx={{ marginRight: '16px' }}
                    onClick={() => navigate(CAMPAIGNS_ROUTES.ADD_CAMPAIGN)}
                    disabled={!isAdmin}
                >
                    <PlusIcon />
                    Ajouter une campagne
                </Button>
            </div>
            <Tabs value={currentTab} onChange={handleChangeTab}>
                <Tab label="Campagnes temporaires" />
                {isUserSuperAdmin ? <Tab label="Promotion étudiante" /> : null}
            </Tabs>
            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                {currentTab === 0 ? (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                            <TextField
                                variant="outlined"
                                label="Recherche"
                                value={promoCampaignSearch}
                                onChange={(e) => setPromoCampaignSearch(e.target.value)}
                            />
                            <div style={{ flexGrow: 1 }} />
                            <ConfirmResourceDeletionButton
                                handleResourceDeletion={handleDeleteCampaign}
                                disabled={campaignsDisabled || !isAdmin}
                            />
                            <Button
                                variant="contained"
                                sx={{ height: '36px' }}
                                disabled={campaignsDisabled}
                                onClick={navigateAndUpdateCampaign}
                            >
                                {isAdmin ? 'Modifier' : 'Voir'}
                            </Button>
                            {isAdmin && (
                                <GenerateUniqueUseCodeButton
                                    campaignsSelection={campaignsSelection}
                                    campaignsDisabled={campaignsDisabled}
                                    promoCampaigns={promoCampaigns}
                                    setCampaignsSelection={setCampaignsSelection}
                                    generateUniqueCodeMut={generateUniqueCodeMut}
                                    sx={{ height: '36px', marginLeft: '16px' }}
                                />
                            )}
                        </div>
                        <SimpleDataGrid
                            columns={promoCampaignColumnDefs}
                            rows={promoCampaignsRows}
                            loading={promoCampaigns.isLoading}
                            onRowSelectionModelChange={(newSelection) =>
                                setCampaignsSelection(newSelection as number[])
                            }
                        />
                    </>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                        <Typography style={fonts.inputHeader} flexGrow={1}>
                            Modifier la valeur de la remise étudiante
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                            <TextField
                                variant="outlined"
                                label="Remise étudiante"
                                value={studentPromoPercentage}
                                onChange={(e) => setStudentPromoPercentage(Number(e.target.value))}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                            <div style={{ width: 30 }} />
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleChangeStudentPromoPercentage();
                                }}
                                disabled={isLoading}
                                sx={{ padding: 3 }}
                            >
                                Modifier le pourcentage de réduction
                            </Button>
                        </div>
                    </div>
                )}
            </Paper>
        </Page>
    );
};

export default PromoCampaignsPage;
