import { NewUpdateMyInfoForm } from '../forms/useUpdateMyInfoForm';
import { NewUpdatePasswordForm } from '../forms/useUpdatePasswordForm';
import { gozokiApiWithAuth } from '../gozokiApi';

export const updateMyInfo = async (data: { userId: number; data: NewUpdateMyInfoForm }) => {
    const response = await gozokiApiWithAuth.patch(`/users/${data.userId}`, data.data);
    return response.data;
};

export const deleteMyAccount = async (userId: number) => {
    const response = await gozokiApiWithAuth.delete(`/users/${userId}`);
    return response.data;
};

export const updateMyPassword = async (data: { userId: number; data: NewUpdatePasswordForm }) => {
    const response = await gozokiApiWithAuth.patch(`/users/password/${data.userId}`, data.data);
    return response.data;
};

export const payForUnBan = async () => {
    const response = await gozokiApiWithAuth.post(`/me/pay-for-unban`);
    return response.data;
};

export const deleteUser = async (userId: number) => {
    const response = await gozokiApiWithAuth.delete(`/users/${userId}`);
    return response.data;
};

export const createUser = async (data: {
    email: string;
    password: string | null;
    firstName: string;
    lastName: string;
    adminRights: number;
}) => {
    const response = await gozokiApiWithAuth.post(`/users/store`, data);
    return response.data;
};

export const updateUser = async (data: {
    userId: number;
    email: string;
    password: string | null;
    firstName: string;
    lastName: string;
    adminRights: number;
}) => {
    const response = await gozokiApiWithAuth.put(`/users/admin-update/${data.userId}`, data);
    return response.data;
};

export const sendBanEmail = async (userId: number) => {
    const response = await gozokiApiWithAuth.post(`/users/${userId}/send-ban-email`);
    return response.data;
};

/**
 * Cette fonction est destinée à être utilisée dans un environnement React Native.
 * Elle ne fonctionnera pas correctement dans un navigateur.
 *
 * @param imageData Les données de l'image à envoyer
 */
export interface ImageData {
    uri: string;
    type: string;
    name: string;
    side: string;
}

const validSides = new Set(['Recto', 'Verso']);

export const updateStudentDocumentFromApp = async (imageData: ImageData[]) => {
    const formData = new FormData();

    imageData.forEach((image) => {
        if (!validSides.has(image.side)) {
            throw new Error(`Invalid side: ${image.side}`);
        }
        // This is a React Native FormData, not the one from the browser
        // eslint-disable-next-line
        // @ts-ignore
        formData.append(`document${image.side}`, {
            uri: image.uri,
            type: image.type,
            name: image.name,
        });
    });
    gozokiApiWithAuth
        .post('/me/student-document', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .catch((err) => {
            console.error(err);
            return null;
        })
        .then((res) => {
            return res?.data;
        })
        .finally(() => {
            return null;
        });
};

export const updateStudentStatus = async (studentStatus: boolean) => {
    const response = await gozokiApiWithAuth.post(`/me/student-status`, { studentStatus });
    return response.data;
};

export const validateStudentStatus = async (userId: number, status: boolean) => {
    const response = await gozokiApiWithAuth.post(
        `/users/${userId}/validate-student-status/${status ? 'true' : 'false'}`
    );
    return response.data;
};

export const updateUserPushToken = async (expoToken: string, userId?: number) => {
    const response = await gozokiApiWithAuth.post(`/me/update-push-token`, { expoToken, userId });
    return response.data;
};

export const desactivateUserPushToken = async () => {
    const response = await gozokiApiWithAuth.post(`/me/desactivate-push-token`);
    return response.data;
};

export const activateUserPushToken = async () => {
    const response = await gozokiApiWithAuth.post(`/me/activate-push-token`);
    return response.data;
};
