import { createStore, updateStore, useStore, useStoreForm } from '@gozoki/api';
import { Button, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { STORE_TYPES } from '@gozoki/api-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import Page from '../../components/Page';
import LoadingPaper from '../../components/containers/LoadingPaper';
import SelectRhf from '../../components/inputs/SelectRhf';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ROUTES } from '../../utils/constants/routes.constants';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { getStoreTypeLabel } from '../../utils/types/storeTypesNames';

const AddStorePage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const updateMode = id !== undefined; // Update mode if there is a promo campaign ID in the URL

    // If this is update mode, use isLoading to show a loading indicator before the data arrives
    const { data, isLoading } = useStore(parseInt(id ?? '', 10));

    const { control, trigger, getValues, setValue } = useStoreForm();

    // Fill the form with initial data once the data is fetched
    useEffect(() => {
        // Set all values
        if (data) {
            setValue('checkpointStoreRef', data.checkpointStoreRef);
            setValue('label', data.label);
            setValue('address', data.address.address);
            setValue('addressAdditionnal', data.address.addressAdditionnal ?? null);
            setValue('city', data.address.city);
            setValue('postalCode', data.address.postalCode);
            setValue('storeType', data.storeType);
        }
    }, [setValue, data]);

    const navigateBack = () => navigate(ROUTES.STORES);

    // ******************************************************************** //
    //                              MUTATIONS                               //
    // ******************************************************************** //

    const { mutate: upload, isLoading: isUploading } = useMutation(createStore, {
        onSuccess: navigateBack,
    });

    const { mutate: update, isLoading: isUpdating } = useMutation(updateStore, {
        onSuccess: navigateBack,
    });

    // ******************************************************************** //
    //                               HANDLERS                               //
    // ******************************************************************** //

    const uploadStore = async () => {
        const valid = await trigger(); // Trigger form validation

        if (valid) {
            upload(getValues());
        }
    };

    const handleUpdateStore = async () => {
        const valid = await trigger(); // Trigger form validation

        if (valid) {
            update({
                id: parseInt(id!, 10),
                data: getValues(),
            });
        }
    };

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode ? 'Modifier une boutique' : 'Ajouter une boutique'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={navigateBack}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    disabled={isUpdating || isUploading}
                    onClick={updateMode ? handleUpdateStore : uploadStore}
                >
                    {updateMode ? 'Modifier' : 'Ajouter'}
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isLoading && updateMode}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Référence
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    placeholder="Ref"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="checkpointStoreRef"
                    fullWidth
                    disabled={updateMode}
                />
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Nom
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    placeholder="Nom de la boutique"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="label"
                    fullWidth
                />
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Adresse
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    placeholder="Adresse"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="address"
                    fullWidth
                />
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Adresse additionnelle
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    placeholder="Adresse"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="addressAdditionnal"
                    fullWidth
                />
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Ville
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    placeholder="Adresse"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="city"
                    fullWidth
                />
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Code postal
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    placeholder="Adresse"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="postalCode"
                    fullWidth
                />
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Type de boutique
                </Typography>

                <SelectRhf
                    control={control}
                    name="storeType"
                    options={STORE_TYPES.map((type) => ({
                        value: type,
                        label: getStoreTypeLabel(type),
                    }))}
                />
            </LoadingPaper>
        </Page>
    );
};

export default AddStorePage;
