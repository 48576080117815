import { Theme } from '@emotion/react';
import { SxProps, Card, Typography } from '@mui/material';
import { fonts } from '../../utils/theme/fonts.theme';

export interface StockCardProps {
    title: string;
    content: string | number | undefined;

    flex?: boolean;
    mr?: boolean; // Apply right margin for spacing in a row
    // Style override
    sx?: SxProps<Theme>;
}

const StockCard = (props: StockCardProps) => {
    const { title, content, flex, mr, sx } = props;

    return (
        <Card
            sx={{
                flex: flex ? 1 : undefined,
                padding: '16px',
                marginRight: mr ? '24px' : undefined,
                borderRadius: '8px',
                ...sx,
            }}
        >
            <Typography style={fonts.stockCardTitle}>{title}</Typography>
            <div style={{ height: '8px' }} />
            <Typography style={fonts.bigNumber}>{content ?? ''}</Typography>
        </Card>
    );
};

export default StockCard;
