import { Paper, SxProps, Theme } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;

    // Style override
    sx?: SxProps<Theme>;
}

const TabPanel = ({ children, index, value, sx }: TabPanelProps) => {
    if (index !== value) {
        return null; // Better than display: none because the inner DataGrids do not like to be hidden this way
    }

    return (
        <Paper
            sx={{
                marginTop: '16px',
                ...sx,
            }}
        >
            {children}
        </Paper>
    );
};

export default TabPanel;
