interface DivTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;

    // Style override
    style?: React.CSSProperties;
}

/** Tab panel, but without the paper */
const DivTabPanel = ({ children, index, value, style }: DivTabPanelProps) => {
    if (index !== value) {
        return null; // Better than display: none because the inner DataGrids do not like to be hidden this way
    }

    return <div style={style}>{children}</div>;
};

export default DivTabPanel;
