import { downloadTicketsCsv, getErrorMessageFromPossibleBlob, usePaginateCarts } from '@gozoki/api';
import { Button, Chip, Paper, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { CartsWithReportUrl } from '@gozoki/api-types';
import EyeIcon from '@mui/icons-material/Visibility';
import { GridColDef } from '@mui/x-data-grid';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import Page from '../../components/Page';
import { useToast } from '../../components/communication/Toast';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { fonts } from '../../utils/theme/fonts.theme';

const CartStatus = ({ cart }: { cart: CartsWithReportUrl }) => {
    let title = 'En attente de validation';
    if (cart.status === 'validated') title = 'Validé';
    if (cart.status === 'cancelled') title = 'Annulé';
    if (cart.status === 'stolen') title = 'Volé';

    let color: 'default' | 'success' | 'info' | 'warning' | 'error' | 'primary' | 'secondary' =
        'primary';
    if (cart.status === 'validated') color = 'success';
    if (cart.status === 'cancelled') color = 'warning';
    if (cart.status === 'stolen') color = 'error';

    return <Chip style={{ height: 35 }} label={title} color={color} />;
};

const cartsColumnDef = () => {
    const gridColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'N° de panier',
            width: 200,
        },
        {
            field: 'createdAt',
            headerName: 'Date et heure de création',
            valueFormatter: (params) => {
                return new Date(params.value as string).toLocaleString();
            },
            width: 180,
        },
        {
            field: 'articles',
            headerName: "Nb d'articles",
            valueFormatter: (params) => params.value.length,
            width: 180,
        },
        {
            field: 'amountTotal',
            headerName: 'Prix total',
            valueFormatter: (params) => {
                return `${params.value} €`;
            },
            width: 180,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Statut',
            renderCell: (params) => {
                return <CartStatus cart={params.row as CartsWithReportUrl} />;
            },
            width: 200,
        },
        {
            field: 'reportUrl',
            headerName: '',
            renderCell: (params) => {
                return (
                    <a target="_blank" href={`${params.row.reportUrl}`} rel="noreferrer">
                        <Button variant="outlined" color="success" style={{ width: '100%' }}>
                            <EyeIcon />
                            &nbsp;Voir la facture
                        </Button>
                    </a>
                );
            },
            width: 200,
        },
    ];
    return gridColumns;
};
const CartsPage = () => {
    const [currentStore, setCurrentStore] = useState<number | null>(null);

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState(20);
    const [startDate, setStartDate] = useState<DateTime | null>(null);
    const [endDate, setEndDate] = useState<DateTime | null>(null);
    const [Toast, showToast] = useToast();

    const { data: carts, isLoading } = usePaginateCarts(
        currentStore ?? 0,
        page,
        pageSize,
        startDate ? startDate.toISO() : null,
        endDate ? endDate.toISO() : null,
        null
    );

    const { mutate: mutateDownloadAuditFile, isLoading: isLoadingAudit } = useMutation(
        downloadTicketsCsv,
        {
            onSuccess: (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'liens-factures.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            },
            onError: async (error) => {
                const errorMessage = await getErrorMessageFromPossibleBlob(error);

                showToast({ message: errorMessage, severity: 'error' });
            },
        }
    );

    const handleDownloadAllTickets = useCallback(() => {
        const usedStartDate =
            startDate?.toISODate() ?? DateTime.now().minus({ days: 31 }).toISODate();
        const usedEndDate = endDate?.toISODate() ?? DateTime.now().toISODate();
        mutateDownloadAuditFile({
            dateStart: usedStartDate,
            dateEnd: usedEndDate,
        });
    }, [mutateDownloadAuditFile, startDate, endDate]);

    return (
        <Page>
            <Toast />
            <div style={{ display: 'flex', paddingLeft: 10 }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Paniers
                </Typography>
            </div>
            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px', flex: 3 }}>
                    <div style={{ flex: 1 }}>
                        <StoresPicker
                            onlyBoutiques
                            defaultStore={3}
                            setUniqueStore={setCurrentStore}
                        />
                    </div>
                </div>
            </Paper>

            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                <div
                    style={{ display: 'flex', alignItems: 'center', padding: '16px', gap: '12px' }}
                >
                    <Typography>Du</Typography>
                    <DateTimePicker
                        label="Date de départ"
                        value={startDate}
                        onChange={(date: DateTime | null) => setStartDate(date || null)}
                        maxDate={endDate ?? DateTime.now()}
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                    />
                    <Typography>au</Typography>
                    <DateTimePicker
                        label="Date de fin"
                        value={endDate}
                        onChange={(date: DateTime | null) => setEndDate(date || null)}
                        disabled={startDate === null}
                        minDate={startDate ? startDate.plus({ minute: 1 }) : undefined}
                        maxDate={DateTime.now()}
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: 16 }}
                        onClick={() => {
                            setStartDate(null);
                            setEndDate(null);
                        }}
                        disabled={startDate === null && endDate === null}
                    >
                        Réinitialiser le filtre
                    </Button>
                    <div style={{ flexGrow: 1 }}>
                        <Button
                            variant="contained"
                            color="info"
                            onClick={handleDownloadAllTickets}
                            disabled={isLoadingAudit}
                        >
                            Exports des tickets
                        </Button>
                    </div>
                </div>
                <SimpleDataGrid
                    columns={cartsColumnDef()}
                    rows={
                        carts?.data?.map((c) => {
                            return {
                                ...c,
                                articles: c.articles ?? [],
                            };
                        }) ?? []
                    }
                    loading={isLoading}
                    rowCount={carts?.meta.total ?? 0}
                    pageSizeOptions={[20, 50]}
                    paginationMode="server"
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize, page },
                        },
                    }}
                    onPaginationModelChange={(params) => {
                        setPage(params.page);
                        setPageSize(params.pageSize);
                    }}
                    rowSelection={false}
                    disableRowSelectionOnClick
                    checkboxSelection={false}
                />
            </Paper>
        </Page>
    );
};

export default CartsPage;
