import { Box, Button, Grid, Typography } from '@mui/material';
import { testCartQuery, useAppUsers, useProducts, useStores } from '@gozoki/api';
import { useMemo, useState } from 'react';

import { CartReportWithTaxes } from '@gozoki/api-types';
import LoadingPaper from '../../components/containers/LoadingPaper';
import Page from '../../components/Page';
import ReactJson from 'react-json-view';
import SelectRhf from '../../components/inputs/SelectRhf';
import { ShopArticlesSelection } from './ShopArticlesSelection';
import { fonts } from '../../utils/theme/fonts.theme';
import { useMutation } from '@tanstack/react-query';
import { useTestCartsForm } from '@gozoki/api/src/forms/useTestCartsForm';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';

const TestCartsPage = () => {
    const { control, watch, setValue, getValues, trigger } = useTestCartsForm();
    const usersQuery = useAppUsers();
    const shopsQuery = useStores();
    const productsQuery = useProducts();

    const usersOptions = useMemo(
        () =>
            (usersQuery.data ?? []).map((user) => ({
                label: `${user.lastName} ${user.firstName}`,
                value: user.id,
            })),
        [usersQuery.data]
    );

    const shopsOptions = useMemo(
        () =>
            (shopsQuery.data ?? []).map((shop) => ({
                label: shop.label,
                value: shop.id,
            })),
        [shopsQuery.data]
    );

    const productsOptions = useMemo(
        () =>
            (productsQuery.data ?? []).map((product) => ({
                label: `${product.label} (${product.id})`,
                value: product.id,
            })),
        [productsQuery.data]
    );

    const pageIsLoading = shopsQuery.isLoading || usersQuery.isLoading || productsQuery.isLoading;

    const shopId = watch('storeId');
    const productId = watch('productId');

    const [cartData, setCartData] = useState<CartReportWithTaxes | null>(null);

    const { mutate, isLoading } = useMutation(
        () => {
            const values = getValues();

            return testCartQuery(
                values.userId,
                values.articleRefs.map(({ ref }) => ref),
                values.promoCode ? [values.promoCode] : undefined
            );
        },
        {
            onSuccess: (data) => {
                setCartData(data.cartInfos);
            },
        }
    );

    return (
        <Page>
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Test de paniers
                </Typography>
            </div>

            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={pageIsLoading}
            >
                <Grid container spacing={4}>
                    <Grid item flex={1}>
                        <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                            Utilisateur
                        </Typography>
                        <SelectRhf options={usersOptions} control={control} name="userId" />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                            Magasin
                        </Typography>
                        <SelectRhf options={shopsOptions} control={control} name="storeId" />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                            Produit
                        </Typography>
                        <SelectRhf options={productsOptions} control={control} name="productId" />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                            Code promotion à usage unique
                        </Typography>
                        <TextFieldRhf
                            control={control}
                            trigger={trigger}
                            name="promoCode"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                {shopId !== null && productId !== null && (
                    <ShopArticlesSelection
                        control={control}
                        shopId={shopId}
                        productId={productId}
                        setValue={setValue}
                    />
                )}

                <Button disabled={isLoading} onClick={() => mutate()} variant="contained">
                    Valider le panier
                </Button>

                {cartData && (
                    <Box sx={{ my: 2 }}>
                        <Typography sx={{ mb: 2 }}>
                            Prix avant promo: {cartData.subTotal} €
                        </Typography>
                        <Typography sx={{ mb: 2 }}>Prix final: {cartData.total} €</Typography>

                        <Typography sx={{ mb: 2 }}>Détails</Typography>
                        <ReactJson src={cartData} collapsed />
                    </Box>
                )}
            </LoadingPaper>
        </Page>
    );
};

export default TestCartsPage;
