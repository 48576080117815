import { Button, ButtonProps } from '@mui/material';
import { MouseEventHandler, useCallback, useState } from 'react';
import { ConfirmButtonProps, ConfirmModal } from './ConfirmModal';

interface Props {
    buttonText: string;
    buttonProps: ButtonProps;
    onConfirm: () => Promise<unknown>;
    title: string;
    description: string;
    confirmButton?: ConfirmButtonProps;
    cancelButton?: ConfirmButtonProps;
}

export const ConfirmButton = ({
    buttonProps,
    buttonText,
    description,
    onConfirm,
    title,
    cancelButton,
    confirmButton,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            setIsOpen(true);
            buttonProps.onClick?.(e);
        },
        [buttonProps]
    );

    return (
        <>
            <Button {...buttonProps} onClick={handleClick}>
                {buttonText}
            </Button>
            <ConfirmModal
                open={isOpen}
                onClose={handleClose}
                onConfirm={onConfirm}
                description={description}
                title={title}
                cancelButton={cancelButton}
                confirmButton={confirmButton}
            />
        </>
    );
};
