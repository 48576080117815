import { Button, IconButton, Typography } from '@mui/material';
import { ENTREPRISES_ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { EntrepriseOutletContext } from '../entreprises/EntrepriseOutletProvider';
import LoadingPaper from '../../components/containers/LoadingPaper';
import Page from '../../components/Page';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { useCallback } from 'react';
import { useRights } from '../../utils/hooks';

const AddEntreprisePage1 = () => {
    const navigate = useNavigate();

    const { worksConcilForm, updateMode, isUpdateModeLoading, worksConcilId } =
        useOutletContext<EntrepriseOutletContext>();

    const { control, trigger, reset } = worksConcilForm;

    const cancel = useCallback(() => {
        reset();
        navigate(ENTREPRISES_ROUTES.ENTREPRISE_DETAILS);
    }, [navigate, reset]);

    const navigateBack = cancel; // Because this is the first page, navigating back should also cancel
    const isAdmin = useRights(['ADMIN']);

    const navigateNext = async () => {
        // Validate this page before going to the next one
        const valid = await trigger([
            'label',
            'companyLabel',
            'companyCity',
            'companyAddress',
            'companySiren',
        ]);

        if (valid && !updateMode) navigate(ENTREPRISES_ROUTES.ADD_ENTREPRISE_SECOND_STEP);
        if (valid && updateMode) navigate(ROUTE_BUILDER.updateWorksConcilSecondStep(worksConcilId));
    };

    // ****************************************************** //
    //            QUERY TO OPTIONS TRANSFORMATION             //
    // ****************************************************** //

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode
                        ? `${
                              isAdmin
                                  ? 'Modifier un partenariat entreprise'
                                  : 'Détails du partenariat'
                          }` // If the user is not admin, the page is read-only
                        : 'Ajouter un partenariat entreprise'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button variant="contained" color="secondary" onClick={navigateNext}>
                    Suivant
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Libellé du partenariat
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="label"
                    placeholder="Libellé"
                    fullWidth
                />
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Nom de l'entreprise
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="companyLabel"
                    placeholder="Nom de l'entreprise"
                    fullWidth
                />
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Ville de l'entreprise
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="companyCity"
                    placeholder="Ville de l'entreprise"
                    fullWidth
                />

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Adresse de l'entreprise
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="companyAddress"
                    placeholder="Adresse de l'entreprise"
                    fullWidth
                />

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Siren de l'entreprise
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="companySiren"
                    placeholder="Siren de l'entreprise"
                    fullWidth
                />
            </LoadingPaper>
        </Page>
    );
};

export default AddEntreprisePage1;
