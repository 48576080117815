import {
    fetchMyPromoCampaigns,
    fetchPromoCampaign,
    fetchPromoCampaigns,
} from '../queries/promoCampaigns.queries';

import { useQuery } from '@tanstack/react-query';

export const usePromoCampaigns = () => {
    return useQuery(['promoCampaigns'], fetchPromoCampaigns);
};

export const usePromoCampaign = (id: number) => {
    return useQuery(['promoCampaign', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchPromoCampaign(id);
    });
};

export const useMyPromoCampaigns = () => {
    return useQuery(['myPromoCampaigns'], fetchMyPromoCampaigns);
};
