import { Button, IconButton, TextField, Typography } from '@mui/material';
import { ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { WorksConcilForm, createWorksConcil, updateWorksConcil, useAppUsers } from '@gozoki/api';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import DatePickerRhf from '../../components/inputs/DatePickerRhf';
import { EntrepriseOutletContext } from './EntrepriseOutletProvider';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { MenuOption } from '../../components/containers/MenuOptions';
import Page from '../../components/Page';
import SelectMultipleRhf from '../../components/inputs/SelectMultipleRhf';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { useMutation } from '@tanstack/react-query';
import { useRights } from '../../utils/hooks';
import { useToast } from '../../components/communication/Toast';

const createEmailsArray = (text: string) => {
    const emails = text.split(/[;, \n]/); // could be separated by commas, semicolons, spaces or new lines
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailArray = emails
        .map((email) => email.trim())
        .filter((email) => emailRegex.test(email));
    return emailArray;
};

const AddEntreprisePage2 = () => {
    const navigate = useNavigate();
    const users = useAppUsers();

    const [Toast, showToast] = useToast();
    const [usersText, setUsersText] = useState<string>('');

    const isAdmin = useRights(['ADMIN']);

    const {
        worksConcilForm,
        updateMode,
        isUpdateModeLoading,
        worksConcilId,
        currentEmployeesEmails,
    } = useOutletContext<EntrepriseOutletContext>();

    const { control, trigger, reset, watch, getValues, setValue } = worksConcilForm;

    const cancel = () => {
        reset();
        navigate(ROUTES.ENTREPRISES);
    };

    const navigateBack = useCallback(() => {
        navigate(ROUTE_BUILDER.updateWorksConcilFirstStep(worksConcilId));
    }, [navigate, worksConcilId]);

    const { mutate: upload, isLoading: isUploading } = useMutation(createWorksConcil, {
        onSuccess: () => {
            reset();
            navigate(ROUTES.ENTREPRISES);
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    const { mutate: update, isLoading: isUpdating } = useMutation(
        ({ id, fields }: { id: number; fields: WorksConcilForm }) => updateWorksConcil(id, fields),
        {
            onSuccess: () => {
                reset();
                navigate(ROUTES.ENTREPRISES);
            },
        }
    );

    const validate = useCallback(() => {
        trigger().then((isValid) => {
            const usersToAdd = createEmailsArray(usersText);
            setValue('emailsToAdd', usersToAdd.length > 0 ? usersToAdd : null);

            if (isValid) {
                showToast({
                    severity: 'success',
                    message: 'Partenariat entreprise valide',
                });

                const values = getValues();
                if (updateMode) update({ id: worksConcilId, fields: values });
                else upload(values);
            } else {
                showToast({
                    severity: 'error',
                    message: 'Erreur lors de la validation du formulaire',
                });
            }
        });
    }, [
        getValues,
        setValue,
        showToast,
        trigger,
        update,
        updateMode,
        upload,
        usersText,
        worksConcilId,
    ]);

    const userOptions: MenuOption[] = useMemo(() => {
        const baseUserChoices: MenuOption[] = [];
        users.data?.forEach((user) => {
            baseUserChoices.push({
                label: user.email,
                value: user.id,
            });
        });
        return baseUserChoices.sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
    }, [users.data]);

    const emailOptions: MenuOption[] = useMemo(() => {
        const baseUserChoices: MenuOption[] = [];
        currentEmployeesEmails?.forEach((email) => {
            baseUserChoices.push({
                label: email,
                value: email,
            });
        });
        return baseUserChoices.sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
    }, [currentEmployeesEmails]);

    const globalLoading = useMemo(() => {
        return isUploading || isUpdating;
    }, [isUploading, isUpdating]);

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <Toast />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode
                        ? `${
                              isAdmin
                                  ? 'Modifier un partenariat entreprise'
                                  : 'Détails du partenariat'
                          }` // If the user is not admin, the page is read-only
                        : 'Ajouter un partenariat entreprise'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                    disabled={globalLoading}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={validate}
                    disabled={globalLoading || !isAdmin}
                >
                    Ajouter
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Date d'effet
                </Typography>
                <div style={{ display: 'flex' }}>
                    <DatePickerRhf
                        label="du"
                        control={control}
                        name="startDate"
                        before={watch('endDate')}
                    />
                    <div style={{ width: '16px' }} />
                    <DatePickerRhf
                        label="au"
                        control={control}
                        name="endDate"
                        after={watch('startDate')}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginRight: 8 }}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Pourcentage Gozoki
                        </Typography>
                        <TextFieldRhf
                            control={control}
                            trigger={trigger}
                            name="gozokiPercentage"
                            label="Pourcentage Gozoki"
                            variant="outlined"
                            type="number"
                            fullWidth
                        />
                    </div>
                    <div style={{ flex: 1, marginLeft: 8 }}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Pourcentage CE
                        </Typography>
                        <TextFieldRhf
                            control={control}
                            trigger={trigger}
                            name="worksConcilPercentage"
                            label="Pourcentage CE"
                            variant="outlined"
                            type="number"
                            fullWidth
                        />
                    </div>
                </div>
                {(watch('worksConcilPercentage') ?? 0) > 0 ? (
                    <div>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Budget maximum mensuel par salarié
                        </Typography>
                        <TextFieldRhf
                            control={control}
                            trigger={trigger}
                            name="maxMonthAmountPerEmployee"
                            label="Budget maximum mensuel par salarié"
                            variant="outlined"
                            type="number"
                            fullWidth
                        />
                    </div>
                ) : null}
                <div>
                    <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                        Salariés associés (utilisateurs)
                    </Typography>

                    <SelectMultipleRhf options={userOptions} control={control} name="employees" />
                </div>
                <div>
                    <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                        Salariés associés (non utilisateurs)
                    </Typography>

                    <SelectMultipleRhf options={emailOptions} control={control} name="emails" />
                </div>
                <div>
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Importer une liste de salariés
                    </Typography>
                    <Typography mb="8px" style={fonts.inputSubtitle}>
                        Copier-coller une liste d'emails séparés par des virgules, des points
                        virgules ou des retours à la ligne
                    </Typography>
                    <TextField
                        variant="outlined"
                        value={usersText}
                        onChange={(event) => setUsersText(event.target.value)}
                        name="description"
                        placeholder="Liste d'emails"
                        fullWidth
                        multiline
                        InputProps={{
                            style: {
                                minHeight: '102px', // why 102px? nobody knows but its cool
                                textAlign: 'end',
                                alignItems: 'flex-start',
                            },
                        }}
                    />
                </div>
            </LoadingPaper>
        </Page>
    );
};

export default AddEntreprisePage2;
