const CamerasPages = () => {
    return (
        <iframe
            title="camera 1"
            src="http://prestataire:Mobile33@80.15.211.88:25000/stream/video.mjpeg?id=6&width=1368&height=710&profile_mode=auto&events=true%HTTP"
        />
    );
};

export default CamerasPages;
