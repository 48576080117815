import { ExcelUploadResponse, NumberResponse } from '../types/axios.types';

import { ProductForm } from '../forms/useProductForm';
import { gozokiApiWithAuth } from '../gozokiApi';

// Create a product
export const createProduct = async (fields: ProductForm) => {
    const images = fields.images.map(({ blob }) => blob);
    const data = { ...fields, images };

    const response = await gozokiApiWithAuth.post<NumberResponse>('/products', data, {
        headers: {
            // This request contains a list of files
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};

// Destroy a product
export const destroyProduct = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/products/${id}`);
    return response;
};

// Destroy multiple products
export const destroyProducts = async (ids: number[]) => {
    const responses = await Promise.all(ids.map((id) => destroyProduct(id)));
    return responses;
};

// Update a product
export const updateProduct = async ({ id, data: fields }: UpdateProductArgs) => {
    const fieldsImages = fields.images ?? [];
    // specify which images to remove
    const imagesToRemove = fieldsImages
        .filter(({ toDelete }) => toDelete)
        .map((imageField) => imageField.id);

    // upload only the new images
    const images = fieldsImages
        .filter((inputFile) => !inputFile.toDelete && inputFile.id === null)
        .map(({ blob }) => blob);
    const data = { ...fields, images, imagesToRemove };

    const response = await gozokiApiWithAuth.patch(`/products/${id}`, data, {
        headers: {
            // This request contains a list of files
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};

// Upload a video for a product
export const uploadProductVideo = async ({ id, video }: UploadProductVideoArgs) => {
    const response = await gozokiApiWithAuth.put(
        `/products/${id}/upload-video`,
        { video, Headers: { 'Content-Type': 'video/mp4' } },
        {
            headers: {
                // This request contains a file
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response;
};

interface UploadProductVideoArgs {
    id: number;
    video: File | null;
}

interface UpdateProductArgs {
    id: number;
    data: Partial<ProductForm>;
}

/** Load products from an excel file */
export const uploadProductsExcel = async (file: File) => {
    const formData = new FormData();
    formData.append('importFile', file);

    const response = await gozokiApiWithAuth.post<ExcelUploadResponse>(
        '/products/import-products',
        formData,
        {
            headers: {
                // This request contains a file
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response;
};

/** Load products stock minimum from an excel file */
export const uploadProductsMinimumStockExcel = async (file: File) => {
    const formData = new FormData();
    formData.append('importFile', file);

    const response = await gozokiApiWithAuth.post<ExcelUploadResponse>(
        '/products/import-products-stock-minimum',
        formData,
        {
            headers: {
                // This request contains a file
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response;
};

export const exportProductsMinimumStockToXlsx = async () => {
    const response = await gozokiApiWithAuth.get(
        '/products/export-products-stock-minimum-to-xlsx',
        {
            responseType: 'blob',
        }
    );

    return response;
};

export const handleBlockLots = async (ids: string[]) => {
    const data = { ids };
    const response = await gozokiApiWithAuth.post('/products/handle-block-lots', data);
    return response.data;
};

export const commentLots = async ({ ids, comment }: { ids: string[]; comment: string }) => {
    const data = { ids, comment };
    const response = await gozokiApiWithAuth.post('/products/comment-lots', data);
    return response.data;
};

export const cleanMultipleArticles = async (ids: number[]) => {
    const data = { ids };
    const response = await gozokiApiWithAuth.post('/products/clean-multiple-articles', data);
    return response.data;
};
