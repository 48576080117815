import { fetchWorksConcil, fetchWorksConcils } from '../queries/worksConcils.queries';

import { useQuery } from '@tanstack/react-query';

export const useWorksConcils = () => {
    return useQuery(['works-concils'], fetchWorksConcils);
};

export const useWorksConcil = (id: number) => {
    return useQuery(['works-concils', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchWorksConcil(id);
    });
};
