import { CircularProgress, Paper, SxProps, Theme } from '@mui/material';
import Center from './Center';

export interface LoadingPaperProps {
    loading?: boolean;
    children?: React.ReactNode;

    // Style override
    sx?: SxProps<Theme>;
}

/** Paper container with a loading display */
const LoadingPaper = ({ children, loading, sx }: LoadingPaperProps) => {
    return (
        <Paper sx={sx}>
            {loading && (
                <Center>
                    <CircularProgress style={{ margin: '50px' }} />
                </Center>
            )}
            {!loading && children}
        </Paper>
    );
};

export default LoadingPaper;
