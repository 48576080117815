import { Alert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

export interface ToastProps {
    message: string;
    severity?: 'success' | 'info' | 'warning' | 'error';
    duration?: number;
}

export const useToast = () => {
    const [open, setOpen] = useState(false);
    const [innerMessage, setMessage] = useState('');
    const [innerSeverity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error'>(
        'success'
    );
    const [currentTimer, setCurrentTimer] = useState<NodeJS.Timeout | null>(null);

    const showToast = ({ message, severity = 'success', duration = 10000 }: ToastProps) => {
        // Clear previous timer to avoid interferences
        if (currentTimer) clearTimeout(currentTimer);

        setMessage(message);
        setSeverity(severity);
        setOpen(true);

        setCurrentTimer(setTimeout(() => setOpen(false), duration));
    };

    const Toast = () => (
        <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert severity={innerSeverity} style={{ display: 'flex', alignItems: 'center' }}>
                {innerMessage}
                <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={() => setOpen(false)}
                    sx={{ marginLeft: '5px' }}
                >
                    <CloseIcon />
                </IconButton>
            </Alert>
        </Snackbar>
    );

    return [Toast, showToast] as const;
};
