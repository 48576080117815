import { ThemeColors } from './colors.theme';

export const ButtonOverrides = {
    redOutlined: {
        'color': ThemeColors.RED,
        'borderWidth': '2px',
        'borderColor': ThemeColors.RED,
        '&:active, &.Mui-focusVisible': {
            borderWidth: '2px',
            borderColor: ThemeColors.RED,
            color: ThemeColors.RED,
        },
        '&:hover': {
            borderWidth: '2px',
            borderColor: ThemeColors.RED,
            color: ThemeColors.RED,
        },
    },

    blackOutlined: {
        'color': ThemeColors.BLACK,
        'borderColor': ThemeColors.BLACK,
        '&:active, &.Mui-focusVisible': {
            borderColor: ThemeColors.BLACK,
            color: ThemeColors.BLACK,
        },
        '&:hover': {
            borderColor: ThemeColors.BLACK,
            color: ThemeColors.BLACK,
        },
    },
    shadow: {
        'boxShadow': '0px 1px 5px 0px #0000001F',
        '&:hover': { boxShadow: '0px 2px 2px 0px #00000024' },
        '&:active, &.Mui-focusVisible': {
            boxShadow: '0px 3px 1px -2px #00000033',
        },
    },
    lightOrange: {
        'backgroundColor': ThemeColors.LIGHT_ORANGE,
        '&:active, &.Mui-focusVisible': {
            backgroundColor: ThemeColors.LIGHT_ORANGE,
        },
        '&:hover': {
            backgroundColor: ThemeColors.LIGHT_ORANGE,
        },
    },
    lightRed: {
        'backgroundColor': ThemeColors.LIGHT_RED,
        '&:active, &.Mui-focusVisible': {
            backgroundColor: ThemeColors.LIGHT_RED,
        },
        '&:hover': {
            backgroundColor: ThemeColors.LIGHT_RED,
        },
    },
} as const;

export const TextOverrides = {
    // Pass as inputProps
    rightPlaceholder: {
        sx: {
            '&::placeholder': {
                textAlign: 'right',
            },
        },
    },
} as const;

export const DataGridOverrides = {
    wrapHeaderNames: {
        '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
        },
        '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            height: 'unset !important',
        },
        '& .MuiDataGrid-columnHeaders': {
            // Forced to use important since overriding inline styles
            maxHeight: '168px !important',
        },
    },
} as const;
