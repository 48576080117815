import { gozokiApiWithAuth } from '../gozokiApi';

export const enableSupportMode = async (id: number) => {
    const response = await gozokiApiWithAuth.get(`kiosks/${id}/activate-manual-mode`);
    return response.data;
};

export const disableSupportMode = async (id: number) => {
    const response = await gozokiApiWithAuth.get(`kiosks/${id}/cancel-manual-mode`);
    return response.data;
};

export const removeArticleFromKiosk = async ({
    id,
    articleRef,
}: {
    id: number;
    articleRef: string;
}) => {
    const response = await gozokiApiWithAuth.post(`/kiosks/${id}/remove-article`, {
        articleRef,
    });
    return response.data;
};

export const validateArticleFromKiosk = async ({
    id,
    articleId,
}: {
    id: number;
    articleId: number;
}) => {
    const response = await gozokiApiWithAuth.post(`/kiosks/${id}/validate-article/${articleId}`);
    return response.data;
};
