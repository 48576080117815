import { Brand } from '@gozoki/api-types';
import { gozokiApi } from '../gozokiApi';

// Fetch one brand
export const fetchBrand = async (id: number) => {
    const response = await gozokiApi.get<Brand>(`/brands/${id}`);
    return response.data;
};

// Fetch all brands
export const fetchBrands = async () => {
    const response = await gozokiApi.get<Brand[]>('/brands');
    return response.data;
};
