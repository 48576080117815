import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

import { GlobalParams } from '@gozoki/api-types';

// fetch all the global params, admin only

export const fetchGlobalParams = async () => {
    const response = await gozokiApiWithAuth.get<GlobalParams>('/global-params');
    return response.data;
};

export const fetchStudentReduction = async () => {
    const response = await gozokiApi.get<number>('/params/student-reduction');
    return response.data;
};
