import { useRouteError } from 'react-router-dom';
import Center from '../containers/Center';
import { Paper, Typography } from '@mui/material';
import { fonts } from '../../utils/theme/fonts.theme';
import { getErrorMessageWithSentry } from '../../utils/sentry';

const ErrorComponent = () => {
    const error = useRouteError();
    return (
        <Center style={{ height: '100vh', padding: '5%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={fonts.pageTitle}>ERREUR</Typography>
                <Typography style={fonts.inputHeader}>
                    {getErrorMessageWithSentry(error)}
                </Typography>
                {error instanceof Error && error.stack && (
                    <Paper
                        elevation={5}
                        style={{ marginTop: '5%', padding: '30px', borderRadius: '10px' }}
                    >
                        <Typography style={fonts.informationSubText}>{error.stack}</Typography>
                    </Paper>
                )}
            </div>
        </Center>
    );
};

export default ErrorComponent;
