import importExportIcon from '../../assets/icons/import-export.svg';

const ImportExportIcon = () => {
    return (
        <img
            height={24}
            width={24}
            src={importExportIcon}
            alt="plus icon"
            style={{ marginRight: '8px' }}
        />
    );
};

export default ImportExportIcon;
