import { CategoryForm } from '../forms/useCategoryForm';
import { gozokiApiWithAuth } from '../gozokiApi';

// Create a category
export const createCategory = async (data: CategoryForm) => {
    const response = await gozokiApiWithAuth.post('/categories', data, {
        headers: {
            // This request contains a file
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};

// Destroy a category
export const destroyCategory = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/categories/${id}`);
    return response;
};

// Destroy multiple categories
export const destroyCategories = async (ids: number[]) => {
    const responses = await Promise.all(ids.map((id) => destroyCategory(id)));
    return responses;
};

// Update a category
export const updateCategory = async ({ id, data }: UpdateCategoryArgs) => {
    const response = await gozokiApiWithAuth.patch(`/categories/${id}`, data, {
        headers: {
            // This request contains a file
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};

interface UpdateCategoryArgs {
    id: number;
    data: Partial<CategoryForm>;
}
