import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

export interface ConfirmButtonProps {
    text?: string;
    variant?: 'text' | 'contained' | 'outlined';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    loading?: boolean;
}

export interface ConfirmModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => Promise<unknown>;
    title: string;
    description: string;
    confirmButton?: ConfirmButtonProps;
    cancelButton?: ConfirmButtonProps;
}

export const ConfirmModal = ({
    open,
    onClose,
    onConfirm,
    title,
    description,
    confirmButton,
    cancelButton,
}: ConfirmModalProps) => {
    const cancelButtonText = cancelButton?.text ?? 'Annuler';
    const confirmButtonText = confirmButton?.text ?? 'Confirmer';
    const confirmButtonVariant = cancelButton?.variant ?? 'contained';
    const confirmButtonColor = cancelButton?.color ?? 'error';
    const confirmButtonLoading = confirmButton?.loading ?? false;

    const handleConfirm = async () => {
        await onConfirm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant={cancelButton?.variant}
                    color={cancelButton?.color}
                >
                    {cancelButtonText}
                </Button>
                <Button
                    variant={confirmButtonVariant}
                    color={confirmButtonColor}
                    onClick={handleConfirm}
                >
                    {confirmButtonText}
                    {confirmButtonLoading ? <CircularProgress size={24} color="info" /> : null}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
