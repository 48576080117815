import { Category } from '@gozoki/api-types';
import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_BUILDER } from '../../utils/constants/routes.constants';

type CellProps = ComponentProps<NonNullable<GridColDef['renderCell']>>;

export const CategoryCell = ({ value }: CellProps) => {
    const category = value as Category | null;

    if (!category) return null;

    return (
        <Box>
            <Link to={ROUTE_BUILDER.updateCategory(category.id)}>
                <Chip style={{ cursor: 'pointer' }} label={category.name} />
            </Link>
        </Box>
    );
};
