import { PASSWORD_REGEX } from '@gozoki/tools';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const zUserAdminForm = z.object({
    firstName: z
        .string()
        .min(2, { message: 'Le prénom doit contenir au moins 2 caractères' })
        .max(30, { message: 'Le prénom doit contenir au maximum 30 caractères' }),

    lastName: z
        .string()
        .min(1, { message: 'Le nom de famille doit contenir au moins 1 caractère' })
        .max(30, { message: 'Le nom de famille doit contenir au maximum 30 caractères' }),

    email: z
        .string()
        .email({ message: 'Email invalide.' })
        .max(50, { message: 'Email trop long.' }),

    password: z
        .string()
        .regex(PASSWORD_REGEX, {
            message:
                'Le mot de passe doit contenir au moins 8 caractères, une lettre, un chiffre et un caractère spécial.',
        })
        .nullable(),

    adminRights: z.number(),
});

export type UserAdminForm = z.infer<typeof zUserAdminForm>;

// Form hook
export const useUserAdminForm = () => {
    const form = useForm<UserAdminForm>({
        // Validation function
        resolver: zodResolver(zUserAdminForm),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: null,
            adminRights: 0,
        },
    });

    return form;
};
