import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const zNewUpdateMyInfoForm = z.object({
    firstName: z
        .string()
        .max(30, { message: 'Le prénom doit contenir au maximum 30 caractères' })
        .refine((value) => value.trim().length > 0, { message: 'Le prénom est requis' }),
    lastName: z
        .string()
        .max(30, { message: 'Le nom de famille doit contenir au maximum 30 caractères' })
        .refine((value) => value.trim().length > 0, { message: 'Le nom de famille est requis' }),
    email: z
        .string()
        .email({ message: "L'adresse email doit être valide" })
        .max(50, { message: "L'adresse email doit contenir au maximum 50 caractères" }),
    isStudent: z.boolean(),
    storeId: z.number().nullable(),
});

export type NewUpdateMyInfoForm = z.infer<typeof zNewUpdateMyInfoForm>;

// Form hook
export const useNewUpdateMyInfoForm = () => {
    const form = useForm<NewUpdateMyInfoForm>({
        // Validation function
        resolver: zodResolver(zNewUpdateMyInfoForm),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            isStudent: false,
        },
    });

    return form;
};
