import { AuthContext, AuthContextType } from '../utils/context/auth.context';
import { Right, hasOneRight, hasRights } from '@gozoki/tools';
import { useCallback, useMemo } from 'react';

import { ThemeProvider } from '@mui/material';
import { materialTheme } from '../utils/theme/material.theme';
import { setToken } from '../utils/api';
import { useMe } from '@gozoki/api';
import { useNavigate } from 'react-router-dom';

export interface GlobalProviderProps {
    children: React.ReactNode;
}

const GlobalProvider = ({ children }: GlobalProviderProps) => {
    const me = useMe();
    const navigate = useNavigate();

    const logout = useCallback(async () => {
        await setToken(null);
        await me.refetch();
        navigate('/login'); // Navigate back to login page
    }, [me, navigate]);

    const authContextValue: AuthContextType = useMemo(() => {
        return {
            authenticated:
                !!me.data &&
                hasOneRight(me.data.adminRights, ['READER', 'STOCK_READER', 'ACCOUNTING_READER']),
            user: me.data ?? null,
            refetchUser: me.refetch,
            isLoading: me.isLoading,
            logout,
            hasRights: (rights: Right[]) => !!me.data && hasRights(me.data.adminRights, rights),
        };
    }, [me.data, me.refetch, me.isLoading, logout]);

    return (
        <AuthContext.Provider value={authContextValue}>
            <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>
        </AuthContext.Provider>
    );
};

export default GlobalProvider;
