import { ProductImage } from '@gozoki/api-types';
import { z } from 'zod';

export const zInputFile = z.object({
    blob: z.instanceof(Blob),
    toDelete: z.boolean(),
    frontendId: z.string(),
    id: z.number().nullable(),
});

export type InputFile = z.infer<typeof zInputFile>;

// Helper : resolve image array from a list of image attachment
export const resolveImages = async (images: ProductImage[]): Promise<InputFile[]> => {
    const resolvedImages = await Promise.all(
        images
            .filter(({ image }) => image.url !== undefined)
            .map(async ({ image, id }) => {
                const response = await fetch(image.url!);
                const blob = await response.blob();
                return { blob, id, toDelete: false, frontendId: Math.random().toString() };
            })
    );

    return resolvedImages;
};

// Generate a local URL from a File object in order to display it in an <img> tag
export const generateLocalUrl = (imageBlob: Blob) => {
    return URL.createObjectURL(imageBlob);
};

export const parseFileTypeFromName = (name: string) => {
    const extension = name.split('.').pop() ?? 'png';

    if (extension === 'svg') return 'image/svg+xml';

    return `image/${extension}`;
};
