import { z } from 'zod';

// ******************************************************** //
// **************** ERROR RESPONSE OBJECTS **************** //
// ******************************************************** //

export const zApiSimpleError = z.object({
    error: z.string(),
});

export const zApiFormError = z.object({
    rule: z.string(),
    field: z.string(),
    message: z.string(),
});

export const zApiFormErrors = z.object({
    errors: z.array(zApiFormError),
});

export const zApiErrors = z.object({
    errors: z.array(z.object({ message: z.string() })),
});

const zAdonisError = z.object({
    code: z.string(),
    message: z.string(),
    stack: z.string(),
});

// ******************************************************** //
// ***************** ERROR RESPONSE TYPES ***************** //
// ******************************************************** //

export type ApiSimpleError = z.infer<typeof zApiSimpleError>;

export type ApiFormError = z.infer<typeof zApiFormError>;

export type ApiFormErrors = z.infer<typeof zApiFormErrors>;

export type ApiErrors = z.infer<typeof zApiErrors>;

export type AdonisError = z.infer<typeof zAdonisError>;

// ******************************************************** //
// ************* ERROR TYPE CHECKER FUNCTIONS ************* //
// ******************************************************** //

export const isApiSimpleError = (input: unknown): input is ApiSimpleError =>
    zApiSimpleError.safeParse(input).success;

export const isApiFormErrors = (input: unknown): input is ApiFormErrors =>
    zApiFormErrors.safeParse(input).success;

export const isApiErrors = (input: unknown): input is ApiErrors =>
    zApiErrors.safeParse(input).success;

export const isAdonisError = (input: unknown): input is AdonisError =>
    zAdonisError.safeParse(input).success;
