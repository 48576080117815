import { fetchNotification, fetchNotifications } from '../queries/notifications.queries';

import { useQuery } from '@tanstack/react-query';

export const useNotifications = () => {
    return useQuery(['notifications'], fetchNotifications);
};

export const useNotification = (id: number) => {
    return useQuery(['notification', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchNotification(id);
    });
};
