import { User } from '@gozoki/api-types';
import { gozokiApiWithAuth } from '../gozokiApi';

export const fetchAppUsers = async () => {
    const res = await gozokiApiWithAuth.get<User[]>('/users/index-app');
    return res.data;
};

export const fetchBoUsers = async () => {
    const res = await gozokiApiWithAuth.get<User[]>('/users/index-bo');
    return res.data;
};

export const fetchUser = async (userId: number) => {
    const res = await gozokiApiWithAuth.get<User>(`/users/${userId}`);
    return res.data;
};

export const fetchPaymentDefaultUsers = async () => {
    const res = await gozokiApiWithAuth.get<User[]>('/users/payment-default');
    return res.data;
};

export const fetchStudentUsers = async () => {
    const res = await gozokiApiWithAuth.get<User[]>('/users/index-students');
    return res.data;
};

export const getPushTokenStatus = async () => {
    const res = await gozokiApiWithAuth.get<boolean>(`/me/push-token-status`);
    return res.data;
};
