import '@fontsource/roboto';

import { ThemeColors } from './colors.theme';

export const fonts = {
    roboto: {
        fontFamily: 'Roboto, sans-serif',
    },
    button: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
    },
    userTag: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
    },
    avatar: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: ThemeColors.AVATAR_TEXT_GRAY,
    },
    navButtonInactive: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: ThemeColors.BLACK,
    },
    navButtonActive: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: ThemeColors.GREEN,
    },
    pageTitle: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '48px',
        letterSpacing: '0.15px',
        color: ThemeColors.BLACK,
    },
    pageSubtitle: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        letterSpacing: '0.15px',
        color: ThemeColors.BLACK,
    },
    tabTitle: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
    },
    inputHeader: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: ThemeColors.BLACK,
    },
    inputSubtitle: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.15px',
        color: ThemeColors.BLACK,
    },
    imageButton: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.15px',
        color: ThemeColors.ORANGE,
    },
    informationSubText: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.15px',
        color: ThemeColors.GREY_60,
    },
    loginTitle: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        color: ThemeColors.BLACK,
    },
    stockCardTitle: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '11.72px',
        color: ThemeColors.GREY_VARIANT,
    },
    bigNumber: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 700,
        fontSize: '32px',
        lineHeight: '37.5px',
        color: ThemeColors.BLACK,
    },
} as const;
