export const ThemeColors = {
    WHITE: '#FFFFFF',
    GREY: '#F1F2F4', // For background color : see `index.css` body background-color
    GREEN: '#399947',
    LIGHT_GREEN: '#04BA00',
    BLACK: '#000000',
    RED: '#CD2927',
    ORANGE: '#F39200',

    GREY_600: '#475467',
    GREY_900: '#101828',

    // Borders & text input
    GREY_20: '#00000033',
    GREY_60: '#00000099',
    GREY_VARIANT: '#818181', // Stock card title

    LIGHT_ORANGE: '#F3920026',
    LIGHT_RED: '#CD292726',

    // Chip colors
    ANTI_GASPI_ORANGE: '#F39200',
    NORMAL_GREEN: '#35764A',

    // Other
    AVATAR_BACKGROUND_GRAY: '#DDE0E4',
    AVATAR_TEXT_GRAY: '#8B949E',
} as const;
