import { WorksConcil, WorksConcilPreview } from '@gozoki/api-types';

import { gozokiApiWithAuth } from '../gozokiApi';

// Fetch one worksConcil
export const fetchWorksConcil = async (id: number) => {
    const response = await gozokiApiWithAuth.get<WorksConcil>(`/works-concil/${id}`);
    return response.data;
};

// Fetch all worksConcils
export const fetchWorksConcils = async () => {
    const response = await gozokiApiWithAuth.get<WorksConcilPreview[]>(
        `/works-concils/show-details`
    );
    return response.data;
};
