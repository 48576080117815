import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { FieldValues, Control, Path, useController, PathValue } from 'react-hook-form';

export interface DatePickerRhfProps<T extends FieldValues> extends DatePickerProps<DateTime> {
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form

    // Date boundary controllers
    before?: string;
    after?: string;
}

const DatePickerRhf = <T extends FieldValues>({
    control,
    name,
    after,
    before,
    ...datePickerProps
}: DatePickerRhfProps<T>) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
    });

    return (
        <DatePicker
            value={DateTime.fromISO(value as string)}
            onChange={(e) => {
                if (e === null) {
                    onChange(null as PathValue<T, Path<T>>);
                    return;
                }

                // Check for date boundaries. Do not allow dates before or after the boundaries
                let newDate = e;
                if (before && DateTime.fromISO(before) < e) newDate = DateTime.fromISO(before);
                if (after && DateTime.fromISO(after) > e) newDate = DateTime.fromISO(after);

                onChange(newDate.toISO() as PathValue<T, Path<T>>);
            }}
            slotProps={{
                textField: {
                    error: !!error,
                },
            }}
            format="dd/MM/yyyy"
            sx={{
                '& .MuiInputBase-root': {
                    height: '48px',
                },
                'flex': 1,
            }}
            {...datePickerProps}
        />
    );
};

export default DatePickerRhf;
