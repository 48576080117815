import { DatePicker } from '@mui/x-date-pickers';
import { DateStateProps } from './fourDatePicker';
import { DateTime } from 'luxon';
import { Button, Dialog } from '@mui/material';
import { fonts } from '../theme/fonts.theme';

export interface CustomFourDatePickerProps {
    openDialog: boolean;
    setOpenDialog: (value: boolean) => void;
    dateState: DateStateProps;
}

export const CustomFourDatePicker = ({
    openDialog,
    setOpenDialog,
    dateState,
}: CustomFourDatePickerProps) => {
    const now = new Date();
    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                setOpenDialog(false);
            }}
            sx={fonts.roboto}
        >
            <p style={{ textAlign: 'right' }}>
                <Button onClick={() => setOpenDialog(false)} sx={{ color: 'black', marginTop: 2 }}>
                    X
                </Button>
            </p>
            <div style={{ padding: 20 }}>
                <h2 style={{ textAlign: 'center' }}>Choisir des dates customisées</h2>
                <h3 style={{ paddingTop: 20 }}>Dates de l'analyse</h3>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20 }}>
                    <div>
                        <p>Début</p>
                        <DatePicker
                            format="dd/MM/yyyy"
                            value={DateTime.fromJSDate(dateState.currentDate.start)}
                            onChange={(e) => {
                                dateState.currentDate.setStart(e ? e.toJSDate() : now);
                            }}
                        />
                    </div>
                    <div>
                        <p>Fin</p>
                        <DatePicker
                            format="dd/MM/yyyy"
                            value={DateTime.fromJSDate(dateState.currentDate.end)}
                            onChange={(e) => {
                                dateState.currentDate.setEnd(e ? e.toJSDate() : now);
                            }}
                        />
                    </div>
                </div>
                {dateState.lastDate ? (
                    <>
                        <h3 style={{ paddingTop: 20 }}>Dates de la comparaison</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20 }}>
                            <div>
                                <p>Début</p>
                                <DatePicker
                                    format="dd/MM/yyyy"
                                    value={DateTime.fromJSDate(dateState.lastDate.start)}
                                    onChange={(e) => {
                                        dateState.lastDate?.setStart(e ? e.toJSDate() : now);
                                    }}
                                />
                            </div>
                            <div>
                                <p>Fin</p>
                                <DatePicker
                                    format="dd/MM/yyyy"
                                    value={DateTime.fromJSDate(dateState.lastDate.end)}
                                    onChange={(e) => {
                                        dateState.lastDate?.setEnd(e ? e.toJSDate() : now);
                                    }}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                <div
                    style={{
                        paddingTop: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <Button onClick={() => setOpenDialog(false)}>Valider</Button>
                </div>
            </div>
        </Dialog>
    );
};
