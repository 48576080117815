import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { PASSWORD_REGEX } from '@gozoki/tools';

const zNewPasswordForm = z.object({
    password: z.string().min(8).regex(PASSWORD_REGEX),
});

export type NewPasswordForm = z.infer<typeof zNewPasswordForm>;

// Form hook
export const useNewPasswordForm = () => {
    const form = useForm<NewPasswordForm>({
        // Validation function
        resolver: zodResolver(zNewPasswordForm),
        defaultValues: {
            password: '',
        },
    });

    return form;
};
