import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const zNewLoginForm = z.object({
    email: z
        .string()
        .email({ message: "L'adresse email n'est pas valide" })
        .max(50, { message: "L'adresse email ne doit pas dépasser 50 caractères" }),
    password: z.string().min(8, { message: 'Le mot de passe doit contenir au moins 8 caractères' }),
});

export type NewLoginForm = z.infer<typeof zNewLoginForm>;

// Form hook
export const useNewLoginForm = () => {
    const form = useForm<NewLoginForm>({
        // Validation function
        resolver: zodResolver(zNewLoginForm),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    return form;
};
