import { useCallback, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { CustomFourDatePicker } from './customFourDatePicker';
import { ThemeColors } from '../theme/colors.theme';

export interface DateStateProps {
    timeRanges: {
        all: { label: string; value: number }[];
        current: number;
        set: (value: number) => void;
    };
    currentDate: {
        start: Date;
        setStart: (date: Date) => void;
        end: Date;
        setEnd: (date: Date) => void;
    };
    lastDate?: {
        start: Date;
        setStart: (date: Date) => void;
        end: Date;
        setEnd: (date: Date) => void;
    };
}

interface FourDatePickerProps {
    dateState: DateStateProps;
}

export const FourDatePicker = ({ dateState }: FourDatePickerProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const setTimeRange = useCallback(
        (value: number) => {
            dateState.timeRanges.set(value);
            const weekShift = value === 1 ? 6 : 0;

            const newDateEnd = new Date();
            newDateEnd.setHours(23, 59, 59, 999);
            newDateEnd.setDate(newDateEnd.getDate() - 1);
            dateState.currentDate.setEnd(newDateEnd);

            const newDateStart = new Date();
            newDateStart.setHours(0, 0, 0, 0);
            newDateStart.setDate(newDateStart.getDate() - value);
            dateState.currentDate.setStart(newDateStart);

            if (dateState.lastDate) {
                const newDateStartLast = new Date();
                newDateStartLast.setHours(0, 0, 0, 0);
                newDateStartLast.setDate(newDateStartLast.getDate() - value * 2 - weekShift);
                dateState.lastDate?.setStart(newDateStartLast);

                const newDateEndLast = new Date();
                newDateEndLast.setHours(23, 59, 59, 999);
                newDateEndLast.setDate(newDateEndLast.getDate() - value - 1 - weekShift);
                dateState.lastDate?.setEnd(newDateEndLast);
            }
        },
        [dateState]
    );

    useEffect(() => {
        if (dateState.timeRanges.current === -1) setTimeRange(7);
    }, [dateState.timeRanges, setTimeRange]);

    return (
        <>
            <div style={{ display: 'flex', flex: 3 }}>
                {dateState.timeRanges.all.map((timeRange) => (
                    <Button
                        key={timeRange.value}
                        variant="contained"
                        color={
                            dateState.timeRanges.current === timeRange.value ? 'info' : 'inherit'
                        }
                        onClick={() => {
                            if (timeRange.value > 0) setTimeRange(timeRange.value);
                            else {
                                setOpenDialog(true);
                                dateState.timeRanges.set(timeRange.value);
                            }
                        }}
                        style={{
                            marginRight: '3px',
                            height: 55,
                            backgroundColor:
                                dateState.timeRanges.current === timeRange.value
                                    ? ThemeColors.LIGHT_ORANGE
                                    : ThemeColors.WHITE,
                            color:
                                dateState.timeRanges.current === timeRange.value
                                    ? ThemeColors.ORANGE
                                    : ThemeColors.GREY_900,
                        }}
                    >
                        <p>
                            {`${timeRange.label}`}
                            <br />
                        </p>{' '}
                    </Button>
                ))}
            </div>
            <CustomFourDatePicker
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dateState={dateState}
            />
        </>
    );
};
