import { Button, TextField } from '@mui/material';
import { destroyCategories, useCategories } from '@gozoki/api';
import { useMemo, useState } from 'react';

import { Category } from '@gozoki/api-types';
import { CategoryCell } from './CategoryCell';
import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import { GridColDef } from '@mui/x-data-grid';
import { ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { SubCategoriesCell } from './SubCategoriesCell';
import Thumbnail from '../../components/icons/Thumbnail';
import { includesLowerCase } from '@gozoki/tools';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRights } from '../../utils/hooks';

const getCategoriesColDef = (isSubCategory: boolean): GridColDef[] => {
    const base: GridColDef[] = [
        {
            field: 'image',
            headerName: 'Image',
            width: 150,
            renderCell: (url) => <Thumbnail url={url.value} alt="Catégorie" rounded />,
        },
        { field: 'name', headerName: 'Nom', width: 200 },
    ];

    if (isSubCategory) {
        return [
            ...base,
            {
                field: 'parentCategory',
                headerName: 'Catégorie parente',
                flex: 1,
                renderCell: CategoryCell,
            },
        ];
    }

    return [
        ...base,
        {
            field: 'subCategories',
            headerName: 'Sous-catégories',
            flex: 1,
            renderCell: SubCategoriesCell,
        },
    ];
};

const getSubcategories = (categoryId: number, categories: Category[]) => {
    return categories.filter((category) => category.categoryId === categoryId);
};

export const CategoriesPanel = ({ mode }: { mode: 'category' | 'subcategory' }) => {
    const navigate = useNavigate();
    const isUserAdmin = useRights(['ADMIN']);

    const [categorySearch, setCategorySearch] = useState('');
    const [categoriesSelection, setCategoriesSelection] = useState<number[]>([]);

    const categoriesColDef = useMemo(() => getCategoriesColDef(mode === 'subcategory'), [mode]);

    const categories = useCategories();
    const categoriesMap = useMemo(
        () => new Map((categories.data ?? []).map((category) => [category.id, category])),
        [categories.data]
    );

    // Transform fetched categories into DataGrid format
    const categoriesRows = useMemo(() => {
        if (categories.data === undefined) {
            return [];
        }
        return categories.data
            .filter((category) => {
                if (mode === 'category' && category.isSubCategory) return false;
                if (mode === 'subcategory' && !category.isSubCategory) return false;

                return includesLowerCase(category.name, categorySearch);
            })
            .map((category) => ({
                id: category.id,
                name: category.name,
                image: category.image?.url,
                parentCategory: categoriesMap.get(category.categoryId ?? 0),
                subCategories: getSubcategories(category.id, categories.data),
            }));
    }, [categories.data, categoriesMap, categorySearch, mode]);

    const deleteCategoryMut = useMutation(destroyCategories);

    const handleDeleteCategory = () => {
        const deletionPromise = deleteCategoryMut.mutateAsync(categoriesSelection, {
            onSuccess: () => {
                categories.refetch();
            },
        });
        setCategoriesSelection([]); // Avoid disabled button flickering
        return deletionPromise;
    };

    const navigateAndUpdateCategory = () => {
        const method = mode === 'category' ? 'updateCategory' : 'updateSubCategory';
        navigate(ROUTE_BUILDER[method](categoriesSelection[0]));
    };

    const categoriesDisabled = categoriesSelection.length === 0 || deleteCategoryMut.isLoading;

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                <TextField
                    variant="outlined"
                    label="Recherche"
                    value={categorySearch}
                    onChange={(e) => setCategorySearch(e.target.value)}
                />
                <div style={{ flexGrow: 1 }} />
                <ConfirmResourceDeletionButton
                    handleResourceDeletion={handleDeleteCategory}
                    disabled={!isUserAdmin ? true : categoriesDisabled}
                />
                <Button
                    variant="contained"
                    sx={{ height: '36px' }}
                    disabled={categoriesDisabled}
                    onClick={navigateAndUpdateCategory}
                >
                    {isUserAdmin ? 'Modifier' : 'Voir'}
                </Button>
            </div>
            <SimpleDataGrid
                rowHeight={100} // For image display
                columns={categoriesColDef}
                rows={categoriesRows}
                loading={
                    categories.isLoading || deleteCategoryMut.isLoading || categories.isRefetching
                }
                onRowSelectionModelChange={(newSelection) =>
                    setCategoriesSelection(newSelection as number[])
                }
            />
        </>
    );
};
