import { isAdonisError, isApiErrors, isApiFormErrors, isApiSimpleError } from './errorValidators';

import axios from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
export const getErrorMessage = (error: unknown, handleUnknownError = (_e: unknown) => {}) => {
    if (axios.isAxiosError(error)) {
        const errorData = error.response?.data;

        if (isApiFormErrors(errorData)) {
            return errorData.errors[0].message;
        }

        if (isAdonisError(errorData)) {
            return errorData.message;
        }

        if (isApiSimpleError(errorData)) {
            return errorData.error;
        }

        if (isApiErrors(errorData)) {
            return errorData.errors[0].message;
        }
        // If none of the above checks pass, return the Axios error message
        return errorData.message || 'Unknown Axios error';
    }

    handleUnknownError(error); // Allow to pass a "last chance" callback, like Sentry.captureException

    // Return error as string if all checks failed
    return String(error);
};
