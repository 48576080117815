import plusIconSvg from '../../assets/icons/plus-icon.svg';
import orangePlusIconSvg from '../../assets/icons/plus-icon-orange.svg';

const PlusIcon = ({ orange }: { orange?: boolean }) => {
    return (
        <img
            height={24}
            width={24}
            src={orange ? orangePlusIconSvg : plusIconSvg}
            alt="plus icon"
            style={{ marginRight: '8px' }}
        />
    );
};

export default PlusIcon;
