import { fetchGlobalParams, fetchStudentReduction } from '../queries/globalParams.queries';

import { useQuery } from '@tanstack/react-query';

export const useGlobalParams = () => {
    return useQuery(['globalParams'], fetchGlobalParams);
};

export const useStudentReduction = () => {
    return useQuery(['studentReduction'], fetchStudentReduction);
};
