import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PRODUCTS_ROUTES, ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';

import { NEGOCE_TYPES_OPTIONS } from '@gozoki/api-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { MenuOption } from '../../components/containers/MenuOptions';
import RadioGroupRhf from '../../components/inputs/RadioGroupRhf';
import SelectRhf from '../../components/inputs/SelectRhf';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import Page from '../../components/Page';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { ProductOutletContext } from './ProductsOutletProvider';

const DLCOptions: MenuOption[] = [
    { label: 'jour même', value: '0' },
    { label: 'la veille', value: '1' },
    { label: 'J-2', value: '2' },
    { label: 'J-3', value: '3' },
    { label: 'J-4', value: '4' },
    { label: 'J-5', value: '5' },
];

const yesNoOptions: MenuOption[] = [
    { label: 'Oui', value: 'true' },
    { label: 'Non', value: 'false' },
];

const AddProductPage4 = () => {
    const navigate = useNavigate();

    const { productForm, updateMode, productId, isUpdateModeLoading } =
        useOutletContext<ProductOutletContext>();
    const { control, reset, trigger } = productForm;

    // ***************************************************** //
    //                      HANDLERS                         //
    // ***************************************************** //

    const cancel = () => {
        reset();
        navigate(ROUTES.PRODUCTS);
    };
    const navigateBack = () => navigate(ROUTE_BUILDER.updateProductThirdStep(productId));
    const navigateNext = async () => {
        // Validate this page before going to the next one
        const valid = await trigger(['antiGaspi']);
        if (valid && !updateMode) navigate(PRODUCTS_ROUTES.ADD_PRODUCT_FIFTH_STEP);
        if (valid && updateMode) navigate(ROUTE_BUILDER.updateProductFifthStep(productId));
    };

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode ? 'Modifier un produit' : 'Ajouter un produit'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button variant="contained" color="secondary" onClick={navigateNext}>
                    Suivant
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                <Grid container spacing={4}>
                    <Grid item flex={1}>
                        <Typography mb="8px" style={fonts.inputHeader}>
                            DUN 14
                        </Typography>
                        <TextFieldRhf
                            variant="outlined"
                            control={control}
                            trigger={trigger}
                            name="dun14"
                            placeholder="DUN 14"
                            fullWidth
                        />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mb="8px" style={fonts.inputHeader}>
                            Code copilot
                        </Typography>
                        <TextFieldRhf
                            variant="outlined"
                            control={control}
                            trigger={trigger}
                            name="copilotCode"
                            placeholder="Code copilot"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid item flex={1} mt={2}>
                    <Typography mb="8px" style={fonts.inputHeader}>
                        COMPTABILITÉ (négoce interne ou négoce externe ou sushis)
                    </Typography>
                    <SelectRhf
                        options={NEGOCE_TYPES_OPTIONS}
                        control={control}
                        name="negoceType"
                        label="Type de négoce"
                        disableClearable
                        required
                    />
                </Grid>
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Afficher comme nouveauté ?
                </Typography>
                <RadioGroupRhf options={yesNoOptions} row control={control} name="isNew" />

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Activer ce produit ?
                </Typography>
                <RadioGroupRhf options={yesNoOptions} row control={control} name="isActive" />

                <Grid container spacing={4} sx={{ alignItems: 'flex-end' }}>
                    <Grid item flex={1}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Règle anti-gaspi
                        </Typography>
                        <Typography mb="8px" style={fonts.informationSubText}>
                            Le produit sera automatiquement basculé dans la catégorie Anti-gaspi
                            lorsque sa DLC arrivera à :
                        </Typography>

                        <SelectRhf control={control} options={DLCOptions} name="antiGaspi" />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Réduction anti-gaspi
                        </Typography>
                        <Typography mb="8px" style={fonts.informationSubText}>
                            Pourcentage de réduction appliqué sur les produits anti-gaspi (arrondi
                            au 50 cts inférieurs)
                        </Typography>

                        <TextFieldRhf
                            variant="outlined"
                            control={control}
                            trigger={trigger}
                            name="agPromo"
                            placeholder="Réduction anti-gaspi"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </LoadingPaper>
        </Page>
    );
};

export default AddProductPage4;
