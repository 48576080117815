import { Notification } from '@gozoki/api-types';

import { gozokiApiWithAuth } from '../gozokiApi';

// Fetch one notification
export const fetchNotification = async (id: number) => {
    const response = await gozokiApiWithAuth.get<Notification>(`/notifications/${id}`);
    return response.data;
};

// Fetch all notifications
export const fetchNotifications = async () => {
    const response = await gozokiApiWithAuth.get<Notification[]>(`/notifications`);
    return response.data;
};
