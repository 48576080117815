import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { ThemeColors } from '../../utils/theme/colors.theme';
import AddIcon from '@mui/icons-material/Add';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { useState } from 'react';

export interface FileInputRhfProps<T extends FieldValues> {
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form
    label: string;
    accept?: string; // File types accepted
    defaultName?: string;
}

const FileInputRhf = <T extends FieldValues>({
    label,
    control,
    name,
    accept,
    defaultName,
}: FileInputRhfProps<T>) => {
    const [filename, setFilename] = useState<string | undefined>(undefined);

    const {
        field: { onChange, onBlur: rhfOnBlur },
        fieldState: { error: rhfError },
    } = useController({
        name,
        control,
    });

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="fileInput" color="info">
                {label}
            </InputLabel>
            <OutlinedInput
                readOnly
                color="info"
                label={label}
                onBlur={rhfOnBlur}
                value={filename ?? defaultName ?? ''}
                error={rhfError !== undefined}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton component="label" htmlFor="fileInput">
                            <AddIcon
                                sx={{
                                    color: ThemeColors.BLACK,
                                    height: '24px',
                                    width: '24px',
                                }}
                            />
                            <input
                                id="fileInput"
                                type="file"
                                accept={accept ?? 'image/*'}
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    const { files } = e.target;
                                    if (files !== null) {
                                        // Store the first selected file
                                        onChange(files[0] as PathValue<T, Path<T>>);
                                        setFilename(files[0].name);
                                    }
                                }}
                            />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default FileInputRhf;
