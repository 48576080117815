import type { InputFile } from '@gozoki/api';
import { Grid } from '@mui/material';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import ImageInputSquare from './ImageInputSquare';

export interface TiledImageInputRhfProps<T extends FieldValues> {
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form
}

const TiledImageInputRhf = <T extends FieldValues>({
    control,
    name,
}: TiledImageInputRhfProps<T>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });

    const setImageList = onChange as (newImageList: InputFile[]) => void;
    const imageList = value as InputFile[];
    const uiImageList = imageList.filter((image) => !image.toDelete);

    const onChangeFactory = (index: number) => (blob: Blob | null) => {
        const newImageList = [...imageList];
        const uiImageToUpdate = uiImageList[index] as InputFile | undefined;

        const realIndex = newImageList.findIndex(
            (image) => image.frontendId === uiImageToUpdate?.frontendId
        );

        if (uiImageToUpdate && realIndex === -1) {
            throw new Error('Image not found in image list, should never happen');
        }

        if (blob !== null) {
            newImageList.push({
                blob,
                id: null,
                toDelete: false,
                frontendId: Math.random().toString(),
            });
        } else if (uiImageToUpdate?.id) {
            newImageList[realIndex].toDelete = true;
        } else {
            newImageList.splice(realIndex, 1);
        }

        setImageList(newImageList);
    };

    return (
        <Grid container>
            <Grid item>
                <ImageInputSquare
                    value={uiImageList?.[0]?.blob ?? null}
                    enableUpload={uiImageList.length === 0}
                    onChange={onChangeFactory(0)}
                    size="200px"
                />
            </Grid>
            <div style={{ width: '8px' }} />
            <Grid item>
                <ImageInputSquare
                    value={uiImageList?.[1]?.blob ?? null}
                    enableUpload={uiImageList.length === 1}
                    onChange={onChangeFactory(1)}
                    size="96px"
                />
                <div style={{ height: '8px' }} />
                <ImageInputSquare
                    value={uiImageList?.[2]?.blob ?? null}
                    enableUpload={uiImageList.length === 2}
                    onChange={onChangeFactory(2)}
                    size="96px"
                />
            </Grid>
            <div style={{ width: '8px' }} />
            <Grid item>
                <ImageInputSquare
                    value={uiImageList?.[3]?.blob ?? null}
                    enableUpload={uiImageList.length === 3}
                    onChange={onChangeFactory(3)}
                    size="96px"
                />
                <div style={{ height: '8px' }} />
                <ImageInputSquare
                    value={uiImageList?.[4]?.blob ?? null}
                    enableUpload={uiImageList.length === 4}
                    onChange={onChangeFactory(4)}
                    size="96px"
                />
            </Grid>
            <div style={{ width: '8px' }} />
            <Grid item>
                <ImageInputSquare
                    value={uiImageList?.[5]?.blob ?? null}
                    enableUpload={uiImageList.length === 5}
                    onChange={onChangeFactory(5)}
                    size="96px"
                />
                <div style={{ height: '8px' }} />
                <ImageInputSquare
                    value={uiImageList?.[6]?.blob ?? null}
                    enableUpload={uiImageList.length === 6}
                    onChange={onChangeFactory(6)}
                    size="96px"
                />
            </Grid>
        </Grid>
    );
};

export default TiledImageInputRhf;
