import { PromoCampaign, PromoCampaignBase, UserPromoCode } from '@gozoki/api-types';
import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

// Fetch one promotional campaign
export const fetchPromoCampaign = async (id: number) => {
    const response = await gozokiApi.get<PromoCampaign>(`/promo-campaigns/${id}`);
    return response.data;
};

// Fetch all promotional campaigns
export const fetchPromoCampaigns = async () => {
    const response = await gozokiApi.get<PromoCampaignBase[]>('/promo-campaigns');
    return response.data;
};

export const addPromoCode = async (promoCode: string) => {
    const response = await gozokiApiWithAuth.post('/me/add-promo-code', { promoCode });
    return response.data;
};

export const fetchMyPromoCampaigns = async () => {
    const response = await gozokiApiWithAuth.get<UserPromoCode[]>('/me/promo-codes');
    return response.data;
};

export const activatePromoCode = async (id: number) => {
    const response = await gozokiApiWithAuth.post(`/me/promo-codes/activate/${id}`);
    return response.data;
};

export const desactivatePromoCode = async (id: number) => {
    const response = await gozokiApiWithAuth.post(`/me/promo-codes/desactivate/${id}`);
    return response.data;
};
