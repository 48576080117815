import { Product, StoreStock, UniqueArticle, StoreProductMinimumStock } from '@gozoki/api-types';
import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

// Fetch one product
export const fetchProduct = async (id: number) => {
    const response = await gozokiApi.get<Product>(`/products/${id}`);
    return response.data;
};

// Fetch all products
export const fetchProducts = async () => {
    const response = await gozokiApi.get<Product[]>('/products');
    return response.data;
};

export const fetchMultipleProducts = async (ids: number[]) => {
    const data = { ids };
    const response = await gozokiApiWithAuth.post<Product[]>('/products/show-multiple', data);
    return response.data;
};

export const fetchMinimumStock = async (id: number) => {
    const response = await gozokiApiWithAuth.get<StoreProductMinimumStock[]>(
        `/products/${id}/minimum-stock`
    );
    return response.data;
};

export const fetchMultipleArticles = async (ids: string[]) => {
    const response = await gozokiApiWithAuth.get<UniqueArticle[]>(
        `/products/get-multiple-articles?ids=${ids.join('--')}`
    );
    return response.data;
};

export const fetchProductStock = async (reference: string) => {
    const response = await gozokiApiWithAuth.get<StoreStock[]>(`/products/${reference}/stock`);
    return response.data;
};
