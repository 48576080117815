import { Typography, TypographyProps } from '@mui/material';

export interface TextProps extends TypographyProps {
    vanish?: boolean; // Hide the component but keep its bounding box
}

/** Simple wrapper around Material UI Textfield, with added functionalities */
const Text = (props: TextProps) => {
    const { children, vanish, ...typographyProps } = props;

    return (
        <Typography
            {...typographyProps}
            style={{
                visibility: vanish ? 'hidden' : 'visible',
            }}
        >
            {children}
        </Typography>
    );
};

export default Text;
