import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const zBrandForm = z.object({
    name: z.string().nonempty().max(30),
    image: z.instanceof(File).nullable(),
});

export type BrandForm = z.infer<typeof zBrandForm>;

// Form hook
export const useBrandForm = (defaultValues?: Partial<BrandForm>) => {
    const form = useForm<BrandForm>({
        // Validation function
        resolver: zodResolver(zBrandForm),
        defaultValues: {
            name: '',
            image: null,
            ...defaultValues,
        },
    });

    return form;
};
