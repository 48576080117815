import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const zCategoryForm = z.object({
    name: z.string().nonempty().max(30),
    image: z.instanceof(File).nullable(),
    categoryId: z.number().nullable(),
    description: z.string().nullable(),
    rank: z.string().nullable(),
});

export type CategoryForm = z.infer<typeof zCategoryForm>;

// Form hook
export const useCategoryForm = (defaultValues?: Partial<CategoryForm>) => {
    const form = useForm<CategoryForm>({
        // Validation function
        resolver: zodResolver(zCategoryForm),
        defaultValues: {
            name: '',
            image: null,
            categoryId: null,
            description: '',
            rank: '0',
            ...defaultValues,
        },
    });

    return form;
};
