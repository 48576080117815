export interface SimpleFormProps {
    children: React.ReactNode;
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

// Simple Form wrapper
const SimpleForm = ({ children, onSubmit }: SimpleFormProps) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit?.(event);
    };

    return <form onSubmit={handleSubmit}>{children}</form>;
};

export default SimpleForm;
