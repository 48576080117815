import { Kiosk } from '@gozoki/api-types';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchKiosks, fetchKiosk } from '../queries/kiosks.queries';

export const useKiosks = (): UseQueryResult<Kiosk[], unknown> => {
    return useQuery(['kiosks'], () => {
        return fetchKiosks();
    });
};

export const useKiosk = (id: number): UseQueryResult<Kiosk, unknown> => {
    return useQuery(['kiosk', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchKiosk(id);
    });
};
