import { TextField, TextFieldProps } from '@mui/material';
import {
    Control,
    FieldValues,
    Path,
    PathValue,
    UseFormTrigger,
    useController,
} from 'react-hook-form';

export type TextFieldRhfProps<T extends FieldValues> = TextFieldProps & {
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form
    trigger: UseFormTrigger<T>; // Form trigger validator

    validateRealTime?: boolean;
    vanish?: boolean; // Hide the component (visibility = 'hidden')
};

const TextFieldRhf = <T extends FieldValues>({
    control,
    name,
    trigger,
    validateRealTime,
    error,
    vanish,
    ...textFieldProps
}: TextFieldRhfProps<T>) => {
    const {
        field: { value, onChange, onBlur: rhfOnBlur },
        fieldState: { error: rhfError },
    } = useController({
        name,
        control,
    });

    return (
        <TextField
            style={{ visibility: vanish ? 'hidden' : 'visible' }}
            autoComplete="off"
            {...textFieldProps}
            onChange={(e) => {
                onChange(e as PathValue<T, Path<T>>);
                if (validateRealTime) {
                    trigger(name); // Trigger validation to update the "error" value
                }
            }}
            error={error ?? rhfError !== undefined} // Mix of error override and internal validation
            onBlur={rhfOnBlur}
            value={value ?? ''}
        />
    );
};

export default TextFieldRhf;
