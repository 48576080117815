import { attachRedirectionOnUnauthorized, attachTokenCallback, setApiUrl } from '@gozoki/api';

// ********************************************************* //
//                     UTILITY FUNCTIONS                     //
// ********************************************************* //

// Token getter
export const getToken = async () => {
    return localStorage.getItem('bearerToken');
};

// Token setter
export const setToken = async (token: string | null) => {
    return localStorage.setItem('bearerToken', token ?? '');
};

// ********************************************************* //
//                  INITIALIZE GOZOKI API                    //
// ********************************************************* //
setApiUrl(import.meta.env.VITE_BACKEND_API_ENDPOINT);
attachTokenCallback(getToken);
attachRedirectionOnUnauthorized(() => {
    setToken(null);
    window.location.replace('/login');
});
