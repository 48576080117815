import { CategoryWithCount, ProductWithCount } from '../../pages/dashboard/DashboardPage';

import ExcelJS from 'exceljs';

export const writeProductsCategoriesSold = async (
    productsData: ProductWithCount[],
    categoriesData: CategoryWithCount[],
    productsTotalCount: number,
    totalCa: number,
    label: string
) => {
    // Création du fichier zip pour mettre tous les fichiers dedans ensuite
    const workbook = new ExcelJS.Workbook();
    const productsSheet = workbook.addWorksheet('Ventes par produits');
    const categoriesSheet = workbook.addWorksheet('Ventes par catégories');

    // Ajoutez l'en-tête de la feuille
    productsSheet.columns = [
        { header: 'Libellé', key: 'label', width: 30 },
        { header: 'Reference', key: 'ref', width: 30 },
        { header: 'Nombre de ventes', key: 'count', width: 15 },
        { header: 'Pourcentage', key: 'predent', width: 15 },
        { header: "Chiffre d'affaires", key: 'ca', width: 15 },
        { header: 'Pourcentage du CA', key: 'caPercent', width: 15 },
    ];

    categoriesSheet.columns = [
        { header: 'Libellé', key: 'label', width: 30 },
        { header: 'Nombre de ventes', key: 'count', width: 15 },
        { header: 'Pourcentage', key: 'predent', width: 15 },
        { header: "Chiffre d'affaires", key: 'ca', width: 15 },
        { header: 'Pourcentage du CA', key: 'caPercent', width: 15 },
    ];

    productsData.forEach((product) => {
        productsSheet.addRow({
            label: product.label,
            ref: product.reference,
            count: product.count,
            predent: (product.count / productsTotalCount) * 100,
            ca: product.ca,
            caPercent: (product.ca / totalCa) * 100,
        });
    });

    categoriesData.forEach((category) => {
        categoriesSheet.addRow({
            label: category.name,
            count: category.count,
            predent: (category.count / productsTotalCount) * 100,
            ca: category.ca,
            caPercent: (category.ca / totalCa) * 100,
        });
    });

    const content = await workbook.xlsx.writeBuffer();
    const url = URL.createObjectURL(new Blob([content]));
    const link = document.createElement('a');
    link.href = url;
    link.download = `${label}.xlsx`; // Nom du fichier Excel
    link.click();
    URL.revokeObjectURL(url);
};
