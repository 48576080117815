import { Button, Chip, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import { commentLots, handleBlockLots, useMultipleArticles, useStoreLotsData } from '@gozoki/api';
import { useCallback, useMemo, useState } from 'react';

import EyeIcon from '@mui/icons-material/Visibility';
import { GridColDef } from '@mui/x-data-grid';
import { Lot } from '@gozoki/api-types';
import Page from '../../components/Page';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { fonts } from '../../utils/theme/fonts.theme';
import styles from '../../assets/styles/QualityPage.module.css';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '../../components/communication/Toast';

const LotStatus = ({ lot }: { lot: Lot }) => {
    let title = 'Non bloqué';
    if (lot.blocked) title = 'Bloqué';
    if (!lot.blocked && lot.blockedAt) title = 'Débloqué';
    return <Chip style={{ height: 35 }} label={title} color={lot.blocked ? 'error' : 'success'} />;
};

const qualityColumnDef = (setArticles: ((row: Lot) => void) | undefined) => {
    const gridColumns: GridColDef[] = [
        {
            field: 'lot',
            headerName: 'N° de lot',
            width: 200,
        },
        {
            field: 'ean',
            headerName: 'EAN 13',
            width: 180,
        },
        { field: 'nb', headerName: "Nb d'articles", width: 100 },
        {
            field: 'label',
            headerName: 'Libellé',
            width: 350,
        },
        {
            field: 'dlc',
            headerName: 'DLC',
            valueFormatter: (params) => {
                return new Date(params.value as string).toLocaleDateString('fr-FR');
            },
            width: 180,
        },
        {
            field: 'tracking',
            headerName: 'Infos de traçabilité',
            width: 200,
        },
        {
            field: 'lo',
            headerName: 'Statut',
            renderCell: (params) => {
                return <LotStatus lot={params.row.lotDetail as Lot} />;
            },
            width: 150,
        },
        {
            field: 'lotDetail',
            headerName: '',
            renderCell: (params) => {
                return (
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                            e.preventDefault();
                            if (setArticles) setArticles(params.row.lotDetail as Lot);
                        }}
                        style={{ width: '100%' }}
                    >
                        <EyeIcon />
                        &nbsp;Détails
                    </Button>
                );
            },
            width: 200,
        },
    ];
    return gridColumns;
};

const articlesColumnDef = () => {
    const gridColumns: GridColDef[] = [
        {
            field: 'checkPointArticleRef',
            headerName: 'Référence',
            width: 220,
        },
        {
            field: 'storeLabel',
            headerName: 'Boutique',
            width: 150,
        },
        {
            field: 'warehouseAt',
            headerName: 'Plateforme',
            valueFormatter: (params) => {
                return params.value
                    ? `${new Date(params.value as string).toLocaleDateString('fr-FR')} ${new Date(
                          params.value as string
                      ).toLocaleTimeString('fr-FR')}`
                    : '-';
            },
            width: 160,
        },
        {
            field: 'shippingAt',
            headerName: 'Transport',
            valueFormatter: (params) => {
                return params.value
                    ? `${new Date(params.value as string).toLocaleDateString('fr-FR')} ${new Date(
                          params.value as string
                      ).toLocaleTimeString('fr-FR')}`
                    : '-';
            },
            width: 160,
        },
        {
            field: 'boutiqueAt',
            headerName: 'Boutique',
            valueFormatter: (params) => {
                return params.value
                    ? `${new Date(params.value as string).toLocaleDateString('fr-FR')} ${new Date(
                          params.value as string
                      ).toLocaleTimeString('fr-FR')}`
                    : '-';
            },
            width: 160,
        },
        {
            field: 'soldAt',
            headerName: 'Vendu',
            valueFormatter: (params) => {
                return params.value
                    ? `${new Date(params.value as string).toLocaleDateString('fr-FR')} ${new Date(
                          params.value as string
                      ).toLocaleTimeString('fr-FR')}`
                    : '-';
            },
            width: 160,
        },
        {
            field: 'stolenAt',
            headerName: 'Volé',
            valueFormatter: (params) => {
                return params.value
                    ? `${new Date(params.value as string).toLocaleDateString('fr-FR')} ${new Date(
                          params.value as string
                      ).toLocaleTimeString('fr-FR')}`
                    : '-';
            },
            width: 160,
        },
        {
            field: 'destroyedAt',
            headerName: 'Détruit',
            valueFormatter: (params) => {
                return params.value
                    ? `${new Date(params.value as string).toLocaleDateString('fr-FR')} ${new Date(
                          params.value as string
                      ).toLocaleTimeString('fr-FR')}`
                    : '-';
            },
            width: 160,
        },
    ];
    return gridColumns;
};

const QualityPage = () => {
    const [Toast, showToast] = useToast();

    const [currentTab, setCurrentTab] = useState(0);

    const [lotSearch, setLotSearch] = useState('');
    const [referenceSearch, setReferenceSearch] = useState('');
    const [labelSearch, setLabelSearch] = useState('');
    const [currentStore, setCurrentStore] = useState<number | null>(null);

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState(20);

    const [lotsSelected, setLotsSelected] = useState<number[]>([]);
    const [lotDetailSelected, setLotDetailsSelected] = useState<Lot | null>(null);

    const [comment, setComment] = useState('');

    const {
        data: lots,
        isLoading,
        refetch: refetchLots,
    } = useStoreLotsData(
        page + 1, // x-data-grid starts at 0 but lucid starts at 1
        pageSize,
        lotSearch,
        referenceSearch,
        labelSearch,
        currentStore,
        currentTab === 1
    );

    const { data: articles, isLoading: isLoadingArticles } = useMultipleArticles(
        lotDetailSelected?.articles
    );

    const handleChangeTab = useCallback(
        (_e: React.SyntheticEvent, newValue: number) => {
            setCurrentTab(newValue);
        },
        [setCurrentTab]
    );

    const handleSeeArticles = useCallback((row: Lot) => {
        setCurrentTab(2);
        setLotDetailsSelected(row);
        setLotsSelected([]);
        setComment(row.comment);
    }, []);

    const safeLots: Lot[] = useMemo(() => lots?.data ?? [], [lots]);

    const { mutate: mutateBlockLots } = useMutation(handleBlockLots, {
        onSuccess: () => {
            showToast({ message: 'Les lots ont bien été bloqués', severity: 'success' });
            setCurrentTab(0);
            refetchLots();
        },
        onError: (error: ErrorEvent) => {
            showToast({ message: error.message, severity: 'error' });
        },
    });

    const { mutate: mutateCommentLot } = useMutation(commentLots, {
        onSuccess: () => {
            showToast({ message: 'Le commentaire a bien été ajouté', severity: 'success' });
            refetchLots();
        },
        onError: (error: ErrorEvent) => {
            showToast({ message: error.message, severity: 'error' });
        },
    });

    return (
        <Page>
            <Toast />
            <div style={{ display: 'flex', paddingLeft: 10 }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Traçabilité & Qualité
                </Typography>
            </div>
            <Tabs value={currentTab} onChange={handleChangeTab} style={{ marginTop: 15 }}>
                <Tab label="Rechercher un lot" />
                <Tab label="Lots bloqués" />
                {currentTab === 2 ? <Tab label="Détails lot" /> : null}
            </Tabs>
            {currentTab === 0 || currentTab === 1 ? (
                <Paper
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <div
                        style={{ display: 'flex', alignItems: 'center', padding: '16px', flex: 3 }}
                    >
                        <div style={{ padding: 16 }}>
                            <TextField
                                variant="outlined"
                                label="Recherche par n° de lot"
                                value={lotSearch}
                                onChange={(e) => setLotSearch(e.target.value ?? '')}
                            />
                        </div>
                        <div style={{ padding: 16 }}>
                            <TextField
                                variant="outlined"
                                label="Recherche par EAN 13"
                                value={referenceSearch}
                                onChange={(e) => setReferenceSearch(e.target.value)}
                            />
                        </div>
                        <div style={{ padding: 16 }}>
                            <TextField
                                variant="outlined"
                                label="Recherche par libellé"
                                value={labelSearch}
                                onChange={(e) => setLabelSearch(e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <StoresPicker setUniqueStore={setCurrentStore} />
                        </div>
                    </div>
                </Paper>
            ) : null}
            {currentTab === 2 && lotDetailSelected ? (
                <Paper
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <div className={styles.headerPaper}>
                        {[
                            { title: 'N° de lot', value: lotDetailSelected.lot },
                            { title: 'EAN 13', value: lotDetailSelected.ean },
                            { title: 'Libellé', value: lotDetailSelected.label },
                            { title: 'Origine', value: lotDetailSelected.tracking },
                            {
                                title: 'DLC',
                                value: new Date(lotDetailSelected.dlc).toLocaleDateString('fr-FR'),
                            },
                            { title: "Nb d'articles", value: lotDetailSelected.nb },
                        ].map((item) => (
                            <div style={{ flex: 1 }}>
                                <p style={fonts.inputSubtitle}>{item.title}</p>
                                <div style={{ padding: 6 }} />
                                <p style={fonts.inputHeader}>{item.value}</p>
                            </div>
                        ))}
                    </div>
                </Paper>
            ) : null}
            {currentTab === 2 && lotDetailSelected ? (
                <Paper
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <div className={styles.contentPaper}>
                        {[
                            { title: 'Statut', elem: <LotStatus lot={lotDetailSelected} /> },
                            {
                                title: 'Commentaire',
                                elem: (
                                    <TextField
                                        variant="outlined"
                                        placeholder="Commentaire"
                                        fullWidth
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                ),
                            },
                            {
                                title: 'Bloqué à',
                                elem: lotDetailSelected.blockedAt ? (
                                    <p>
                                        {new Date(lotDetailSelected.blockedAt).toLocaleDateString(
                                            'fr-FR'
                                        )}
                                        {' - '}
                                        {new Date(lotDetailSelected.blockedAt).toLocaleTimeString(
                                            'fr-FR'
                                        )}
                                    </p>
                                ) : (
                                    <p>-</p>
                                ),
                            },
                            {
                                title: 'Débloqué à',
                                elem: lotDetailSelected.unBlockedAt ? (
                                    <p>
                                        {new Date(lotDetailSelected.unBlockedAt).toLocaleDateString(
                                            'fr-FR'
                                        )}
                                        {' - '}
                                        {new Date(lotDetailSelected.unBlockedAt).toLocaleTimeString(
                                            'fr-FR'
                                        )}
                                    </p>
                                ) : (
                                    <p>-</p>
                                ),
                            },
                            {
                                title: 'Bloqué par',
                                elem: lotDetailSelected.blockedBy ? (
                                    <p>{lotDetailSelected.blockedBy}</p>
                                ) : (
                                    <p>-</p>
                                ),
                            },
                        ].map((item) => (
                            <div className={styles.detailsLine}>
                                <div style={{ flex: 1 }} className={styles.detailsLinePart}>
                                    <p style={fonts.inputHeader}>{item.title}</p>
                                </div>
                                <div style={{ flex: 2 }} className={styles.detailsLinePartTwo}>
                                    {item.elem}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.headerPaper} style={{ justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            color="info"
                            style={{ margin: 10 }}
                            onClick={() =>
                                mutateCommentLot({ ids: lotDetailSelected.articles, comment })
                            }
                        >
                            Modifier le commentaire
                        </Button>
                        <Button
                            variant="contained"
                            color={lotDetailSelected.blocked ? 'success' : 'error'}
                            onClick={() => mutateBlockLots(lotDetailSelected.articles)}
                            style={{ margin: 10 }}
                        >
                            {lotDetailSelected.blocked ? 'Débloquer' : 'Bloquer'}
                        </Button>
                    </div>
                </Paper>
            ) : null}

            {currentTab === 2 && articles ? (
                <Paper
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <SimpleDataGrid
                        columns={articlesColumnDef()}
                        rows={articles.map((a) => {
                            return {
                                id: a.checkpointArticleRef,
                                checkPointArticleRef: a.checkpointArticleRef,
                                storeLabel: a.store.label,
                                warehouseAt: a.warehouseAt,
                                shippingAt: a.shippingAt,
                                boutiqueAt: a.boutiqueAt,
                                soldAt: a.state === 'SOLD' ? a.lostAt : null,
                                stolenAt: a.state === 'STOLEN' ? a.lostAt : null,
                                destroyedAt: a.state === 'DESTROYED' ? a.lostAt : null,
                            };
                        })}
                        loading={isLoadingArticles}
                        disableRowSelectionOnClick
                        checkboxSelection={false}
                    />
                </Paper>
            ) : null}

            {currentTab === 0 || currentTab === 1 ? (
                <Paper
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <SimpleDataGrid
                        columns={qualityColumnDef(handleSeeArticles)}
                        rows={safeLots.map((l) => {
                            return {
                                id: `${page}-${l.lot}-${l.ean}-${l.dlc}-${l.comment}-${l.tracking}-${l.blocked}`,
                                lot: l.lot,
                                ean: l.ean,
                                nb: l.nb,
                                label: l.label,
                                dlc: l.dlc,
                                tracking: l.tracking,
                                lotDetail: l,
                            };
                        })}
                        loading={isLoading}
                        onRowSelectionModelChange={(newSelection) =>
                            setLotsSelected(newSelection as number[])
                        }
                        rowSelectionModel={lotsSelected}
                        rowCount={lots?.meta.total ?? 0}
                        pageSizeOptions={[20, 50]}
                        paginationMode="server"
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize, page },
                            },
                        }}
                        onPaginationModelChange={(params) => {
                            setPage(params.page);
                            setPageSize(params.pageSize);
                        }}
                        rowSelection={false}
                        disableRowSelectionOnClick
                        checkboxSelection={false}
                        onRowClick={(row) => {
                            if (row.row.lotDetail) handleSeeArticles(row.row.lotDetail as Lot);
                        }}
                    />
                </Paper>
            ) : null}
        </Page>
    );
};

export default QualityPage;
