import { AuthContext } from './context/auth.context';
import { DailyStockKpi } from '@gozoki/api-types';
import { Right } from '@gozoki/tools';
import { useContext } from 'react';

export const useRights = (rights: Right[]): boolean => {
    const { hasRights } = useContext(AuthContext);

    return hasRights(rights);
};

export const getProductList = (
    currentKpis: DailyStockKpi[] | undefined,
    currentProducts: number[],
    currentProductsRefs: string[],
    mapKey: keyof DailyStockKpi
) => {
    if (!currentKpis) return [];
    if (currentProducts.length > 0) {
        const result: { ref: string; count: number }[] = [];
        currentKpis.forEach((kpi) => {
            Object.values(kpi[mapKey]).forEach(({ ref, count }) => {
                if (currentProductsRefs.includes(ref)) {
                    result.push({ ref, count });
                }
            });
        });
        return result.reduce((acc: { ref: string; count: number }[], { ref, count }) => {
            const index = acc.findIndex((p) => p.ref === ref);
            if (index !== -1) {
                acc[index].count += count;
            } else {
                acc.push({ ref, count });
            }
            return acc;
        }, []);
    }
    return currentKpis
        .reduce((acc: { ref: string; count: number }[], kpi) => {
            return acc.concat(Object.values(kpi[mapKey]));
        }, [])
        .reduce((acc: { ref: string; count: number }[], { ref, count }) => {
            const index = acc.findIndex((p) => p.ref === ref);
            if (index !== -1) {
                acc[index].count += count;
            } else {
                acc.push({ ref, count });
            }
            return acc;
        }, []);
};

export const getProductCount = (
    currentKpis: DailyStockKpi[] | undefined,
    currentProductsRefs: string[],
    mapKey: keyof DailyStockKpi
) => {
    if (!currentKpis) return 0;
    if (currentProductsRefs.length > 0) {
        let totalCount = 0;
        currentKpis?.forEach((kpi) => {
            Object.values(kpi[mapKey]).forEach(({ ref, count }) => {
                if (currentProductsRefs.includes(ref)) {
                    totalCount += count;
                }
            });
        });
        return totalCount;
    }
    let totalCount = 0;
    currentKpis?.forEach((kpi) => {
        Object.values(kpi[mapKey]).forEach(({ count }) => {
            totalCount += count;
        });
    });
    return totalCount;
};
