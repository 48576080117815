import { Autocomplete, SxProps, TextField, Theme } from '@mui/material';
import { useMemo } from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import {
    IndexOptionValue,
    MenuOption,
    OptionValue,
    isIndexOptionValue,
} from '../containers/MenuOptions';

export interface SelectRhfProps<T extends FieldValues> {
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form

    options: MenuOption[];
    sx?: SxProps<Theme>;
    label?: string;
    disableClearable?: boolean;
    required?: boolean;
    size?: 'small' | 'medium';
}

/** Because Autocomplete has a weird API, we pass an array of option labels to the element,
 * and on change we convert the selected label to its value */
const SelectRhf = <T extends FieldValues>(props: SelectRhfProps<T>) => {
    const { control, name, options, sx, label, disableClearable, required, size } = props;

    const {
        field: { onChange, onBlur: rhfOnBlur, value },
    } = useController({
        name,
        control,
    });

    // Memoize helper objects to avoid heavy operations on every change
    const { labelFromValue, valueFromLabel, labels } = useMemo(() => {
        // eslint-disable-next-line no-shadow
        const labelFromValue = options.reduce((result, option) => {
            if (isIndexOptionValue(option.value)) {
                result[option.value] = option.label;
            }
            return result;
        }, {} as Record<IndexOptionValue, string>);

        // eslint-disable-next-line no-shadow
        const valueFromLabel = options.reduce((result, option) => {
            result[option.label] = option.value;
            return result;
        }, {} as Record<string, OptionValue>);

        // eslint-disable-next-line no-shadow
        const labels = options.map((option) => option.label);

        return { labelFromValue, valueFromLabel, labels };
    }, [options]);

    return (
        <Autocomplete
            disableClearable={disableClearable}
            noOptionsText="Aucune option"
            autoHighlight
            sx={sx}
            onBlur={rhfOnBlur}
            onChange={(_e, newValue) => {
                onChange(
                    (newValue !== null ? valueFromLabel[newValue] : null) as PathValue<T, Path<T>>
                );
            }}
            value={labelFromValue[value] || null}
            options={labels}
            renderInput={(params) => (
                <TextField {...params} label={label} required={required} size={size} />
            )}
        />
    );
};

export default SelectRhf;
