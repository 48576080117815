import { Button, IconButton, Typography } from '@mui/material';
import { createBrand, updateBrand, useBrand } from '@gozoki/api';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import FileInputRhf from '../../components/inputs/FileInputRhf';
import LoadingPaper from '../../components/containers/LoadingPaper';
import Page from '../../components/Page';
import { ROUTES } from '../../utils/constants/routes.constants';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { useBrandForm } from '@gozoki/api/src/forms/useBrandForm';
import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRights } from '../../utils/hooks';

const AddBrandPage = () => {
    const navigate = useNavigate();
    const isUserAdmin = useRights(['ADMIN']);

    const { id } = useParams();
    const updateMode = id !== undefined; // Update mode if there is a brand ID in the URL

    // If this is update mode, use isLoading to show a loading indicator before the data arrives
    const { data, isLoading } = useBrand(parseInt(id ?? '', 10));

    const { control, trigger, getValues, setValue } = useBrandForm();

    // Fill the form with initial data once the data is fetched
    useEffect(() => {
        if (data?.name) setValue('name', data?.name);
    }, [setValue, data?.name]);

    const navigateBack = () => navigate(ROUTES.CATEGORIES, { state: { currentTab: 2 } });

    const { mutate: upload, isLoading: isUploading } = useMutation(createBrand, {
        onSuccess: navigateBack,
    });

    const { mutate: update, isLoading: isUpdating } = useMutation(updateBrand, {
        onSuccess: navigateBack,
    });

    const uploadBrand = async () => {
        const valid = await trigger(); // Trigger form validation

        if (valid) {
            upload(getValues());
        }
        // If invalid, the invalid fields will be outlined with red
    };

    const uploadAndUpdateBrand = async () => {
        const valid = await trigger('name'); // No need to check for image, which cannot be deleted

        if (valid) {
            update({
                id: parseInt(id!, 10),
                data: getValues(),
            });
        }
    };

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode
                        ? `${isUserAdmin ? 'Modifier' : 'Voir'} une marque`
                        : 'Ajouter une marque'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={navigateBack}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    disabled={!isUserAdmin ? true : isUpdating || isUploading}
                    onClick={updateMode ? uploadAndUpdateBrand : uploadBrand}
                >
                    {updateMode ? 'Modifier' : 'Ajouter'}
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isLoading && updateMode}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Nom
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    label="Nom de la marque"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="name"
                    fullWidth
                />
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Icône ou image
                </Typography>
                <FileInputRhf
                    control={control}
                    name="image"
                    label="Ajouter une image"
                    defaultName={data?.image?.name}
                />
            </LoadingPaper>
        </Page>
    );
};

export default AddBrandPage;
