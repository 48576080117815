import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { useKiosks } from '@gozoki/api';

export interface KiosksPickerProps {
    setUniqueKiosk?: (value: number) => void;
    selectedKiosk?: number;
    disabled?: boolean;
}

export const KiosksPicker = ({ disabled, setUniqueKiosk, selectedKiosk }: KiosksPickerProps) => {
    const { data: kiosks } = useKiosks();

    const allKiosksIds = useMemo(() => {
        if (kiosks) return kiosks.map((kiosk) => kiosk.id);
        return [];
    }, [kiosks]);

    const realDefaultKiosk = useMemo(() => {
        if (selectedKiosk) return selectedKiosk;
        if (allKiosksIds?.length) return allKiosksIds[0];
        return '';
    }, [allKiosksIds, selectedKiosk]);

    useEffect(() => {
        if (setUniqueKiosk && realDefaultKiosk) setUniqueKiosk(realDefaultKiosk);
    }, [setUniqueKiosk, realDefaultKiosk]);

    const kiosksSelectOption = useMemo(() => {
        const options = kiosks?.map((kiosk) => ({
            label: `${kiosk.store.label} - ${kiosk.label}`,
            value: kiosk.id,
        }));
        return options;
    }, [kiosks]);

    return (
        <div style={{ display: 'flex', flex: 2, margin: 6 }}>
            <FormControl fullWidth>
                <InputLabel>Sélectionner une caisse</InputLabel>
                <Select
                    label="Sélectionner une caisse"
                    value={realDefaultKiosk}
                    disabled={disabled}
                    onChange={(e) => {
                        if (setUniqueKiosk) setUniqueKiosk(e.target.value as number);
                    }}
                >
                    {kiosksSelectOption?.map((store) => (
                        <MenuItem key={store.value} value={store.value}>
                            {store.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
