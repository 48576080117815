import { Button, IconButton, Typography } from '@mui/material';
import { FormEvent, useEffect, useMemo } from 'react';
import { createCategory, updateCategory, useCategories, useCategory } from '@gozoki/api';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import FileInputRhf from '../../components/inputs/FileInputRhf';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { MenuOption } from '../../components/containers/MenuOptions';
import Page from '../../components/Page';
import { ROUTES } from '../../utils/constants/routes.constants';
import SelectRhf from '../../components/inputs/SelectRhf';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { useCategoryForm } from '@gozoki/api/src/forms/useCategoryForm';
import { useMutation } from '@tanstack/react-query';
import { useRights } from '../../utils/hooks';

const AddCategoryPage = () => {
    const navigate = useNavigate();
    const isUserAdmin = useRights(['ADMIN']);

    const subCategoryMode = window.location.pathname.includes('sub_category');

    const { id } = useParams();
    const updateMode = id !== undefined; // Update mode if there is a category ID in the URL

    // If this is update mode, use isLoading to show a loading indicator before the data arrives
    const { data, isLoading } = useCategory(parseInt(id ?? '', 10));

    const { control, trigger, getValues, setValue } = useCategoryForm();

    // Fill the form with initial data once the data is fetched
    useEffect(() => {
        if (data?.name) setValue('name', data?.name);
        if (data?.categoryId) setValue('categoryId', data?.categoryId);
        if (data?.description) setValue('description', data?.description);
        if (data?.rank) setValue('rank', data?.rank.toString());
    }, [setValue, data?.name, data?.categoryId, data?.description, data?.rank]);

    const backTab = subCategoryMode ? 1 : 0;

    const navigateBack = () => navigate(ROUTES.CATEGORIES, { state: { currentTab: backTab } });

    const { mutate: upload, isLoading: isUploading } = useMutation(createCategory, {
        onSuccess: navigateBack,
    });

    const { mutate: update, isLoading: isUpdating } = useMutation(updateCategory, {
        onSuccess: navigateBack,
    });

    const uploadCategory = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const valid = await trigger(); // Trigger form validation

        if (valid) {
            upload(getValues());
        }
        // If invalid, the invalid fields will be outlined with red
    };

    const uploadAndUpdateCategory = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const valid = await trigger('name'); // No need to check for image, which cannot be deleted

        if (valid) {
            update({
                id: parseInt(id!, 10),
                data: getValues(),
            });
        }
    };

    const categories = useCategories();

    const categoriesOptions: MenuOption[] = useMemo(() => {
        const arr = categories.data ?? [];
        return arr
            .filter((c) => !c.isSubCategory && data?.id !== c.id)
            .map((c) => ({ label: c.name, value: c.id }));
    }, [categories.data, data?.id]);

    const categoryType = subCategoryMode ? 'sous-catégorie' : 'catégorie';

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <form onSubmit={updateMode ? uploadAndUpdateCategory : uploadCategory}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={navigateBack}>
                        <ArrowBackIcon
                            sx={{
                                color: ThemeColors.BLACK,
                                height: '24px',
                                width: '24px',
                            }}
                        />
                    </IconButton>
                    <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                        {updateMode
                            ? `${isUserAdmin ? 'Modifier' : 'Voir'} une ${categoryType}`
                            : `Ajouter une ${categoryType}`}
                    </Typography>

                    <Button
                        variant="outlined"
                        sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                        onClick={navigateBack}
                    >
                        Annuler
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!isUserAdmin ? true : isUploading || isUpdating}
                    >
                        {updateMode ? 'Modifier' : 'Ajouter'}
                    </Button>
                </div>
                <LoadingPaper
                    sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                    loading={isLoading && updateMode}
                >
                    <Typography mb="8px" style={fonts.inputHeader}>
                        Nom
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Nom de la catégorie"
                        control={control}
                        trigger={trigger}
                        name="name"
                        fullWidth
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Icône ou image
                    </Typography>
                    <FileInputRhf
                        label="Ajouter une image"
                        control={control}
                        name="image"
                        defaultName={data?.image?.name}
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Description de la catégorie (affichée sur la page dédiée de l'app)
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Description de la catégorie"
                        control={control}
                        trigger={trigger}
                        name="description"
                        fullWidth
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Rang de la catégorie (entre 0 et 10 : défini l'ordre d'affichage, 0 en
                        dernier)
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Rang de la catégorie"
                        control={control}
                        trigger={trigger}
                        name="rank"
                        fullWidth
                        type="number"
                    />
                    {subCategoryMode && (
                        <>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Categorie parente
                            </Typography>
                            <SelectRhf
                                label="Categorie parente"
                                control={control}
                                name="categoryId"
                                options={categoriesOptions}
                                required
                            />
                        </>
                    )}
                </LoadingPaper>
            </form>
        </Page>
    );
};

export default AddCategoryPage;
