import { useContext, useState } from 'react';
import { AuthContext } from '../utils/context/auth.context';
import { Avatar, Button, Menu, MenuItem, Typography } from '@mui/material';
import { fonts } from '../utils/theme/fonts.theme';
import { firstLetter } from '@gozoki/tools';
import * as Sentry from '@sentry/react';

const UserTag = () => {
    const { user, logout } = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
    const [anchorWidth, setAnchorWidth] = useState<number>(0);
    const menuOpen = anchorEl !== undefined;

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorWidth(event.currentTarget.clientWidth);
        setAnchorEl(event.currentTarget);
    };

    // If no user : do not display anything
    if (user === null) {
        return null;
    }

    const handleLogout = () => {
        logout();
        Sentry.setUser(null); // Clear Sentry user context
    };

    return (
        <>
            <Button onClick={handleClick} sx={{ margin: 0 }}>
                <Avatar sx={{ margin: '16px' }}>
                    {`${firstLetter(user.firstName)}${firstLetter(user.lastName)}`}
                </Avatar>
                <Typography style={fonts.userTag} color="text.primary">
                    {`${user.firstName} ${user.lastName.toUpperCase()}`}
                </Typography>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: {
                            width: `${anchorWidth}px`,
                            marginTop: '-8px',
                        },
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
            </Menu>
        </>
    );
};

export default UserTag;
