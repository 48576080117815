import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    SxProps,
    Theme,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import { exportStoresAsCSV } from '@gozoki/api';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useToast } from '../communication/Toast';
import { writeStoreFiles } from '../../utils/excel/writeStoresFiles';

export interface StoreCSVExportButtonProps {
    // Style override
    sx?: SxProps<Theme>;
    selectedStores?: number[];
    onlySelected?: boolean;
}

type ExportType = 'multipleCSV' | 'zip' | 'excel';

const StoreCSVExportButton = ({ sx, selectedStores, onlySelected }: StoreCSVExportButtonProps) => {
    const [open, setOpen] = useState(false);
    const [exportType, setExportType] = useState<ExportType>('multipleCSV');
    const [Toast, showToast] = useToast();

    const { mutate, isLoading } = useMutation(exportStoresAsCSV, {
        onSuccess: async (data) => {
            writeStoreFiles(data, selectedStores, exportType);
        },
        onError: (error) => {
            showToast({
                severity: 'error',
                message: getErrorMessageWithSentry(error),
            });
        },
    });

    const handleClose = (xprType: ExportType | null) => {
        setOpen(false);
        if (xprType === null) {
            return;
        }
        setExportType(xprType);
        mutate();
    };

    const handleOpen = () => {
        if (onlySelected) {
            setExportType('excel');
            mutate();
        } else {
            setOpen(true);
        }
    };

    return (
        <>
            <Toast />
            <Button
                variant="contained"
                sx={sx}
                onClick={() => {
                    handleOpen();
                }}
                disabled={isLoading}
            >
                <DownloadIcon />
                Exporter
            </Button>
            <Dialog
                open={open}
                onClose={() => handleClose(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Choisissez le format d'exportation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Quel format utiliser pour l'export ?
                        <br />
                        - Un seul fichier ZIP contenant un fichier CSV par boutique
                        <br />
                        - Un seul fichier Excel contenant toutes les données (un onglet par
                        boutique)
                        <br />
                        - Un fichier CSV par boutique (tous les fichiers sont téléchargés)
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose('zip')} color="primary">
                        un seul ZIP
                    </Button>
                    <Button onClick={() => handleClose('excel')} color="secondary">
                        un fichier xlsx (excel)
                    </Button>
                    <Button onClick={() => handleClose('multipleCSV')} color="primary" autoFocus>
                        un CSV par boutique
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StoreCSVExportButton;
