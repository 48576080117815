import { DataGrid, DataGridProps, frFR } from '@mui/x-data-grid';

// Wrapper for DataGrid with predefined props
const SimpleDataGrid = ({ sx, ...props }: DataGridProps) => {
    return (
        <DataGrid
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            checkboxSelection
            sx={{
                '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                ...sx,
            }}
            {...props}
        />
    );
};

export default SimpleDataGrid;
