import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { useCategories } from '@gozoki/api';
import { useMemo } from 'react';

export interface CategoryPickerProps {
    setCurrentCategory: (value: number) => void;
}

export const CategoryPicker = ({ setCurrentCategory }: CategoryPickerProps) => {
    const { data: categories } = useCategories();

    const storeSelectOptions = useMemo(() => {
        const options = categories?.map((category) => ({
            label: category.name,
            value: category.id,
        }));
        options?.push({ label: 'Toutes les catégories', value: 0 });
        return options;
    }, [categories]);

    return (
        <div style={{ display: 'flex', flex: 2, margin: 6 }}>
            <FormControl fullWidth>
                <InputLabel>Catégorie</InputLabel>
                <Select
                    label="Boutique"
                    defaultValue={0}
                    onChange={(e) => setCurrentCategory(e.target.value as number)}
                >
                    {storeSelectOptions?.map((store) => (
                        <MenuItem key={store.value} value={store.value}>
                            {store.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
