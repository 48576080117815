import { MethodTokenResponse, SuccessResponse } from '../types/axios.types';
import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

import { NewResetPasswordForm } from '../forms/useResetPasswordForm';
import { NewSignupForm } from '../forms/useNewSignupForm';
import { PaymentResponse } from '@gozoki/api-types';

export const signin = async (data: NewSignupForm) => {
    const response = await gozokiApi.post('/auth/register', data);
    return response.data;
};

/** Ask to send a password reset email */
export const askForResetPassword = async (data: { email: string }) => {
    const response = await gozokiApi.post<SuccessResponse>('/auth/ask-reset-password', data);
    return response.data;
};

export const resetPassword = async (data: NewResetPasswordForm) => {
    const response = await gozokiApi.post('/auth/reset-password', data);
    return response.data;
};

/** add a payment token to our profile */
export const getPayFormToken = async () => {
    const response = await gozokiApiWithAuth.post(`/auth/get-pay-form-token`);
    return response.data;
};

export const getUnBanPayFormToken = async () => {
    const response = await gozokiApiWithAuth.post(`/auth/get-unban-pay-form-token`);
    return response.data;
};

export const getPayFormTokenConecs = async () => {
    const response = await gozokiApiWithAuth.post(`/auth/get-pay-form-token-conecs`);
    return response.data;
};

export const setPayToken = async (data: PaymentResponse) => {
    const response = await gozokiApiWithAuth.post<MethodTokenResponse>(`/auth/set-pay-token`, data);
    return response.data;
};

export const setUnBanPayToken = async (data: PaymentResponse) => {
    const response = await gozokiApiWithAuth.post<MethodTokenResponse>(
        `/auth/set-unban-paytoken`,
        data
    );
    return response.data;
};
