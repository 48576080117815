import { Button, Dialog, Paper, TextField, Typography } from '@mui/material';
import { DashboardCard, ProductCard } from '../../utils/dashboard/dashboardCards';
import { ROUTE_BUILDER, STORES_ROUTES } from '../../utils/constants/routes.constants';
import {
    destroyStores,
    refreshBearcod,
    refreshStocks,
    useProducts,
    useStockKpis,
    useStores,
    useStoresStocks,
} from '@gozoki/api';
import { frenchFormat, includesLowerCase } from '@gozoki/tools';
import { getProductCount, getProductList, useRights } from '../../utils/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CategoryPicker } from '../../utils/dashboard/categoryPicker';
import { FourDatePicker } from '../../utils/dashboard/fourDatePicker';
import { GridColDef } from '@mui/x-data-grid';
import Page from '../../components/Page';
import PlusIcon from '../../components/icons/PlusIcon';
import { Product } from '@gozoki/api-types';
import { ProductPicker } from '../../utils/dashboard/productPicker';
import { ProductWithCount } from '../dashboard/DashboardPage';
import { Refresh } from '@mui/icons-material';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import StoreCSVExportButton from '../../components/buttons/StoreCSVExportButton';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { fonts } from '../../utils/theme/fonts.theme';
import { getStoreTypeLabel } from '../../utils/types/storeTypesNames';
import { timeRanges } from '../../utils/dashboard/timeRanges';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { writeArticlesDetailsFile } from '../../utils/excel/writeStoresFiles';

const promoCampaignColumnDefs: GridColDef[] = [
    {
        field: 'label',
        headerName: 'Nom',
        flex: 1,
    },
    {
        field: 'address',
        headerName: 'Adresse',
        flex: 1,
        renderCell: (field) => field.value.address,
    },
    {
        field: 'checkpointStoreRef',
        headerName: 'Ref',
        flex: 1,
    },
    {
        field: 'storeType',
        headerName: 'Type de boutique',
        flex: 1,
        renderCell: (field) => getStoreTypeLabel(field.value),
    },
];

const dateNow = new Date();

const StoresPage = () => {
    const isStockAdmin = useRights(['STOCK_ADMIN']);
    const [storeSearch, setStoreSearch] = useState('');
    const navigate = useNavigate();

    const [storesSelection, setStoresSelection] = useState<number[]>([]);

    const stores = useStores();
    const { data: products } = useProducts();

    const [currentTimeRange, setCurrentTimeRange] = useState<number>(-1);

    const [currentDateStart, setCurrentDateStart] = useState<Date>(dateNow);
    const [currentDateEnd, setCurrentDateEnd] = useState<Date>(dateNow);

    const [currentStores, setCurrentStores] = useState<number[]>([]);

    const allProductsIds = useMemo(() => {
        if (!products) return [];
        return products.map((p) => p.id);
    }, [products]);

    const [currentProducts, setCurrentProducts] = useState<number[]>(allProductsIds);
    const [currentCategory, setCurrentCategory] = useState<number>(0);

    const currentProductsRefs = useMemo(() => {
        if (!products) return [];
        return products
            .filter((product) => currentProducts.includes(product.id))
            .map((p) => p.reference);
    }, [currentProducts, products]);

    const currentCategoryProducts = useMemo(() => {
        if (!products) return [];
        return products.filter((product) =>
            product.categories.map((c) => c.id).includes(currentCategory)
        );
    }, [currentCategory, products]);

    const handleSetProduct = useCallback(
        (value: number) => {
            if (value !== 0) {
                setCurrentProducts([value]);
                return;
            }
            if (currentCategory === 0) {
                setCurrentProducts(allProductsIds);
            } else {
                setCurrentProducts(currentCategoryProducts.map((p) => p.id));
            }
        },
        [allProductsIds, currentCategory, currentCategoryProducts]
    );

    const handleSetCategory = useCallback(
        (value: number) => {
            setCurrentCategory(value);
            if (value === 0) {
                setCurrentProducts(products?.map((p) => p.id) ?? []);
            } else if (products) {
                setCurrentProducts(
                    products
                        .filter((product) => product.categories.map((c) => c.id).includes(value))
                        .map((p) => p.id)
                );
            }
        },
        [products]
    );

    // ********** FILTER KPIS & STOCKS WITH STORES AND DATES ********** //

    const { data: allKpis } = useStockKpis();
    const { data: stocks, refetch: refetchStocks } = useStoresStocks(currentStores);

    const currentStoresKpis = useMemo(() => {
        return allKpis?.filter((kpi) => currentStores.includes(kpi.storeId));
    }, [allKpis, currentStores]);

    const currentKpis = useMemo(() => {
        return currentStoresKpis?.filter((kpi) => {
            const kpiDate = new Date(kpi.date);
            return (
                kpiDate.getTime() >= currentDateStart.getTime() &&
                kpiDate.getTime() <= currentDateEnd.getTime()
            );
        });
    }, [currentDateEnd, currentDateStart, currentStoresKpis]);
    // ****************** KPIS CALCULATIONS ****************** //

    const stockTotal = useMemo(() => {
        if (!stocks) return 0;
        if (currentProducts.length > 0) {
            return stocks.reduce((acc, stock) => {
                const stockProducts = stock.rows.filter((row) => currentProducts.includes(row.id));
                return acc + stockProducts.reduce((a, product) => a + product.stock, 0);
            }, 0);
        }
        return stocks.reduce((acc, stock) => acc + stock.metadata.articleCount, 0);
    }, [currentProducts, stocks]);

    const stockValue = useMemo(() => {
        if (!stocks) return 0;
        if (currentProducts.length > 0) {
            return stocks.reduce((acc, stock) => {
                const stockProducts = stock.rows.filter((row) => currentProducts.includes(row.id));
                return acc + stockProducts.reduce((a, product) => a + product.totalValue, 0);
            }, 0);
        }
        return stocks.reduce((acc, stock) => acc + stock.metadata.totalStockValue, 0);
    }, [currentProducts, stocks]);

    const stockAgTotal = useMemo(() => {
        if (!stocks) return 0;
        if (currentProducts.length > 0) {
            return stocks.reduce((acc, stock) => {
                const stockProducts = stock.rows.filter((row) => currentProducts.includes(row.id));
                return acc + stockProducts.reduce((a, product) => a + product.agStock, 0);
            }, 0);
        }
        return stocks.reduce((acc, stock) => acc + stock.metadata.antiGaspiArticleCount, 0);
    }, [currentProducts, stocks]);

    const stockExpiredTotal = useMemo(() => {
        if (!stocks) return 0;
        if (currentProducts.length > 0) {
            return stocks.reduce((acc, stock) => {
                const stockProducts = stock.rows.filter((row) => currentProducts.includes(row.id));
                return acc + stockProducts.reduce((a, product) => a + product.expiredStock, 0);
            }, 0);
        }
        return stocks.reduce((acc, stock) => acc + stock.metadata.expiredArticleCount, 0);
    }, [currentProducts, stocks]);

    const productsSoldCount = useMemo(() => {
        return getProductCount(currentKpis, currentProductsRefs, 'articlesSoldMap');
    }, [currentKpis, currentProductsRefs]);

    const productsSoldList = useMemo(() => {
        return getProductList(currentKpis, currentProducts, currentProductsRefs, 'articlesSoldMap');
    }, [currentKpis, currentProducts, currentProductsRefs]);

    const productStolenCount = useMemo(() => {
        return getProductCount(currentKpis, currentProductsRefs, 'articlesStolenMap');
    }, [currentKpis, currentProductsRefs]);

    const productStolenList = useMemo(() => {
        return getProductList(
            currentKpis,
            currentProducts,
            currentProductsRefs,
            'articlesStolenMap'
        );
    }, [currentKpis, currentProducts, currentProductsRefs]);

    const productDefectiveCount = useMemo(() => {
        return getProductCount(currentKpis, currentProductsRefs, 'articlesDefectiveMap');
    }, [currentKpis, currentProductsRefs]);

    const productDefectiveList = useMemo(() => {
        return getProductList(
            currentKpis,
            currentProducts,
            currentProductsRefs,
            'articlesDefectiveMap'
        );
    }, [currentKpis, currentProducts, currentProductsRefs]);

    const productFaultyCount = useMemo(() => {
        return getProductCount(currentKpis, currentProductsRefs, 'articlesFaultyMap');
    }, [currentKpis, currentProductsRefs]);

    const productFaultyList = useMemo(() => {
        return getProductList(
            currentKpis,
            currentProducts,
            currentProductsRefs,
            'articlesFaultyMap'
        );
    }, [currentKpis, currentProducts, currentProductsRefs]);

    const productExpiredCount = useMemo(() => {
        return getProductCount(currentKpis, currentProductsRefs, 'articlesExpiredMap');
    }, [currentKpis, currentProductsRefs]);

    const productExpiredList = useMemo(() => {
        return getProductList(
            currentKpis,
            currentProducts,
            currentProductsRefs,
            'articlesExpiredMap'
        );
    }, [currentKpis, currentProducts, currentProductsRefs]);

    const productDestroyedCount = useMemo(() => {
        return getProductCount(currentKpis, currentProductsRefs, 'articlesDestroyedMap');
    }, [currentKpis, currentProductsRefs]);

    const productDestroyedList = useMemo(() => {
        return getProductList(
            currentKpis,
            currentProducts,
            currentProductsRefs,
            'articlesDestroyedMap'
        );
    }, [currentKpis, currentProducts, currentProductsRefs]);

    const [productsToShow, setProductsToShow] = useState<
        'sold' | 'stolen' | 'destroyed' | 'faulty' | 'expired' | 'defective'
    >('sold');
    const [showDetailsProducts, setShowDetailsProducts] = useState(false);
    const [wantADownload, setWantADownload] = useState(false);

    const productListToShow = useMemo(() => {
        switch (productsToShow) {
            case 'stolen':
                return productStolenList;
            case 'destroyed':
                return productDestroyedList;
            case 'faulty':
                return productFaultyList;
            case 'expired':
                return productExpiredList;
            case 'defective':
                return productDefectiveList;
            default:
                return productsSoldList;
        }
    }, [
        productsToShow,
        productStolenList,
        productDestroyedList,
        productFaultyList,
        productExpiredList,
        productsSoldList,
        productDefectiveList,
    ]);

    const productToShow: Product[] = useMemo(() => {
        return (
            products?.filter((p) => productListToShow.map((pr) => pr.ref).includes(p.reference)) ??
            []
        );
    }, [products, productListToShow]);

    const productsDataToShow: ProductWithCount[] = useMemo(() => {
        return productToShow
            .map((product) => {
                const productCount = productListToShow.filter((p) => p.ref === product.reference)[0]
                    .count;
                return { ...product, count: productCount } as ProductWithCount;
            })
            .sort((a, b) => b.count - a.count);
    }, [productListToShow, productToShow]);

    const productsToShowCount = useMemo(() => {
        return productListToShow.reduce((acc, p) => acc + p.count, 0);
    }, [productListToShow]);

    const handleDownloadShowData = useCallback(() => {
        setWantADownload(true);
    }, []);

    useEffect(() => {
        if (wantADownload) {
            let traduction = 'vendus';
            switch (productsToShow) {
                case 'stolen':
                    traduction = 'volés';
                    break;
                case 'destroyed':
                    traduction = 'détruits';
                    break;
                case 'faulty':
                    traduction = 'blocage qualité';
                    break;
                case 'expired':
                    traduction = 'expirés';
                    break;
                case 'defective':
                    traduction = 'défectueux';
                    break;
                default:
                    break;
            }
            writeArticlesDetailsFile(
                productsDataToShow,
                `details_articles_${traduction}_${dateNow
                    .toLocaleDateString()
                    .replace(' ', '_')}.xlsx`
            );
            setWantADownload(false);
        }
    }, [productListToShow, productsDataToShow, productsSoldList, productsToShow, wantADownload]);

    // ********** QUERY TO DATAGRID TRANSFORMATION *********** //

    const storeRows = useMemo(() => {
        if (stores.data === undefined) {
            return [];
        }
        return stores.data.filter((store) => includesLowerCase(store.label, storeSearch));
    }, [stores.data, storeSearch]);

    // *********************** HANDLERS  ********************* //

    const deleteStoresMut = useMutation(destroyStores);

    const refreshStockMut = useMutation(refreshStocks, {
        onSuccess: () => {
            refetchStocks();
        },
    });
    const refreshBearcodMut = useMutation(refreshBearcod);

    const navigateAndUpdateCampaign = async () => {
        navigate(ROUTE_BUILDER.updateStore(storesSelection[0]));
    };

    const navigateStoreDetails = (id: number) => navigate(ROUTE_BUILDER.storeDetails(id));

    const storesDisabled = storesSelection.length === 0 || deleteStoresMut.isLoading;

    // ***************** CATEGORIES & PRODUCT OPTIONS  **************** //

    const dateState = {
        timeRanges: {
            all: timeRanges,
            current: currentTimeRange,
            set: setCurrentTimeRange,
        },
        currentDate: {
            start: currentDateStart,
            setStart: setCurrentDateStart,
            end: currentDateEnd,
            setEnd: setCurrentDateEnd,
        },
    };

    return (
        <>
            <Dialog
                sx={fonts.roboto}
                open={showDetailsProducts}
                onClose={() => setShowDetailsProducts(false)}
            >
                <div
                    style={{
                        minWidth: 500,
                        minHeight: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        style={fonts.pageSubtitle}
                        sx={{ textAlign: 'center', marginBottom: 2, marginTop: 2 }}
                    >
                        Détails des produits
                    </Typography>

                    <div style={{ maxHeight: 950, overflow: 'auto' }}>
                        {productsDataToShow?.map((product) => (
                            <ProductCard
                                key={product.id}
                                product={product}
                                count={product.count}
                                ca={0}
                                mode="count"
                                maxCount={productsDataToShow[0].count}
                                maxCa={10}
                                totalCount={productsToShowCount}
                                totalCa={1}
                            />
                        ))}
                    </div>
                </div>
            </Dialog>
            <Page sx={fonts.roboto}>
                <div style={{ display: 'flex', paddingLeft: 20 }}>
                    <Typography style={fonts.pageTitle} flexGrow={1}>
                        Réseau Lucie
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', padding: 20 }}>
                    <StoresPicker setCurrentStores={setCurrentStores} />
                    <CategoryPicker setCurrentCategory={handleSetCategory} />
                    <ProductPicker
                        currentProducts={currentProducts}
                        products={currentCategoryProducts}
                        setCurrentProducts={handleSetProduct}
                    />
                </div>

                <Typography sx={{ paddingLeft: 2 }} style={fonts.informationSubText} flexGrow={1}>
                    Informations en temps réel
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DashboardCard
                        label="Stock total"
                        bigValue={`${frenchFormat(stockTotal)}`}
                        orange
                    />
                    <DashboardCard
                        label="Valo totale"
                        bigValue={`${frenchFormat(stockValue)} € HT`}
                        orange
                    />
                    <DashboardCard
                        label="Stock Anti-Gaspi"
                        bigValue={`${frenchFormat(stockAgTotal)}`}
                    />
                    <DashboardCard
                        label="Stock expiré"
                        bigValue={`${frenchFormat(stockExpiredTotal)}`}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 20 }}>
                    <FourDatePicker dateState={dateState} />
                </div>
                <Typography
                    sx={{ paddingLeft: 2, paddingTop: 1 }}
                    style={fonts.informationSubText}
                    flexGrow={1}
                >
                    Informations sur la période sélectionnée
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 20 }}>
                    <DashboardCard
                        label="Produits vendus"
                        bigValue={`${frenchFormat(productsSoldCount)}`}
                        details={() => {
                            setProductsToShow('sold');
                            setShowDetailsProducts(true);
                        }}
                        download={() => {
                            setProductsToShow('sold');
                            handleDownloadShowData();
                        }}
                    />
                    <DashboardCard
                        label="Produits volés"
                        bigValue={`${frenchFormat(productStolenCount)}`}
                        details={() => {
                            setProductsToShow('stolen');
                            setShowDetailsProducts(true);
                        }}
                        download={() => {
                            setProductsToShow('stolen');
                            handleDownloadShowData();
                        }}
                    />
                    <DashboardCard
                        label="Produits détruits"
                        bigValue={`${frenchFormat(productDestroyedCount)}`}
                        details={() => {
                            setProductsToShow('destroyed');
                            setShowDetailsProducts(true);
                        }}
                        download={() => {
                            setProductsToShow('destroyed');
                            handleDownloadShowData();
                        }}
                    />
                    <DashboardCard
                        label="Produits blocage qualité"
                        bigValue={`${frenchFormat(productFaultyCount)}`}
                        details={() => {
                            setProductsToShow('faulty');
                            setShowDetailsProducts(true);
                        }}
                        download={() => {
                            setProductsToShow('faulty');
                            handleDownloadShowData();
                        }}
                    />
                    <DashboardCard
                        label="Produits expirés"
                        bigValue={`${frenchFormat(productExpiredCount)}`}
                        details={() => {
                            setProductsToShow('expired');
                            setShowDetailsProducts(true);
                        }}
                        download={() => {
                            setProductsToShow('expired');
                            handleDownloadShowData();
                        }}
                    />
                    <DashboardCard
                        label="Produits défectueux"
                        bigValue={`${frenchFormat(productDefectiveCount)}`}
                        details={() => {
                            setProductsToShow('defective');
                            setShowDetailsProducts(true);
                        }}
                        download={() => {
                            setProductsToShow('defective');
                            handleDownloadShowData();
                        }}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <Typography style={fonts.pageTitle} flexGrow={1}>
                        Stocks
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ marginRight: '16px' }}
                        onClick={() => navigate(STORES_ROUTES.ADD_STORE)}
                        disabled={!isStockAdmin}
                    >
                        <PlusIcon />
                        Ajouter une boutique
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ marginRight: '16px' }}
                        onClick={() => {
                            refreshStockMut.mutate();
                        }}
                        disabled={refreshStockMut.isLoading || !isStockAdmin}
                    >
                        <Refresh />
                        Rafraîchir les stocks CheckPoint
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ marginRight: '16px' }}
                        onClick={() => {
                            refreshBearcodMut.mutate();
                        }}
                        disabled={refreshBearcodMut.isLoading || !isStockAdmin}
                    >
                        <Refresh />
                        Rafraîchir les données Bearcod
                    </Button>
                </div>
                <Paper
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                        <TextField
                            variant="outlined"
                            label="Recherche"
                            value={storeSearch}
                            onChange={(e) => setStoreSearch(e.target.value)}
                        />
                        <div style={{ flexGrow: 1 }} />

                        <Button
                            variant="contained"
                            sx={{ height: '36px', marginRight: '16px' }}
                            disabled={storesDisabled}
                            onClick={navigateAndUpdateCampaign}
                        >
                            Modifier
                        </Button>
                        <StoreCSVExportButton
                            selectedStores={storesSelection}
                            sx={{ height: '36px' }}
                        />
                    </div>
                    <SimpleDataGrid
                        columns={promoCampaignColumnDefs}
                        rows={storeRows}
                        loading={stores.isLoading}
                        onRowSelectionModelChange={(newSelection) =>
                            setStoresSelection(newSelection as number[])
                        }
                        onRowClick={(row) => navigateStoreDetails(row.row.id)}
                    />
                </Paper>
            </Page>
        </>
    );
};

export default StoresPage;
