import { DEFAULT_LOGGED_IN_ROUTE, ROUTES } from '../utils/constants/routes.constants';

import { AuthContext } from '../utils/context/auth.context';
import { CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';

const RootRedirect = () => {
    const { authenticated, isLoading, hasRights } = useContext(AuthContext);

    if (isLoading) {
        return <CircularProgress />; // TODO :center ?
    }

    if (authenticated) {
        if (hasRights(['READER'])) {
            return <Navigate to={DEFAULT_LOGGED_IN_ROUTE} />;
        }
        if (hasRights(['STOCK_READER'])) {
            return <Navigate to={ROUTES.STORES} />;
        }
        if (hasRights(['ACCOUNTING_READER'])) {
            return <Navigate to={ROUTES.ACCOUNTING} />;
        }
        return <Navigate to={DEFAULT_LOGGED_IN_ROUTE} />;
    }

    // Default : redirect to login
    return <Navigate to={ROUTES.LOGIN} />;
};

export default RootRedirect;
