import { NumberResponse } from '../types/axios.types';
import { WorksConcilForm } from '../forms/useWorksConcilForm';
import { gozokiApiWithAuth } from '../gozokiApi';

// Create a works concil
export const createWorksConcil = async (fields: WorksConcilForm) => {
    const data = { ...fields };
    const response = await gozokiApiWithAuth.post<NumberResponse>('/works-concil', data);
    return response;
};

export const updateWorksConcil = async (id: number, fields: WorksConcilForm) => {
    const data = { ...fields };
    const response = await gozokiApiWithAuth.put<NumberResponse>(`/works-concil/${id}`, data);
    return response;
};

export const deleteWorksConcil = async (id: number) => {
    const response = await gozokiApiWithAuth.delete<NumberResponse>(`/works-concil/${id}`);
    return response;
};

export const downloadWorksConcilHistoriesCsv = async (date: string) => {
    const response = await gozokiApiWithAuth.get(
        `works-consils/get-works-concils-histories/${date}`,
        {
            responseType: 'blob',
        }
    );
    return response;
};
