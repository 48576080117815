import * as Sentry from '@sentry/react';

import { Button, Paper, Typography } from '@mui/material';
import { DEFAULT_LOGGED_IN_ROUTE, ROUTES } from '../utils/constants/routes.constants';
import { askForResetPassword, login, me, useNewLoginForm } from '@gozoki/api';
import { hasOneRight, hasRights } from '@gozoki/tools';
import { useContext, useState } from 'react';

import { AuthContext } from '../utils/context/auth.context';
import Center from '../components/containers/Center';
import SimpleForm from '../components/inputs/SimpleForm';
import TextFieldRhf from '../components/inputs/TextFieldRhf';
import { fonts } from '../utils/theme/fonts.theme';
import { getErrorMessageWithSentry } from '../utils/sentry';
import loginLogo from '../assets/logos/logoSoft.svg';
import { setToken } from '../utils/api';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../components/communication/Toast';

const LoginPage = () => {
    const { refetchUser } = useContext(AuthContext);

    const navigate = useNavigate();

    const { control, getValues, trigger, clearErrors } = useNewLoginForm();
    const [loginError, setLoginError] = useState<string | undefined>(undefined); // Login feedback
    const [Toast, showToast] = useToast();

    const { mutate, isLoading } = useMutation(askForResetPassword, {
        onSuccess: (response) => {
            showToast({
                message: response.message,
                severity: 'success',
            });
        },
        onError: (error) => {
            showToast({
                message: getErrorMessageWithSentry(error),
                severity: 'error',
            });
        },
    });

    const formSubmit = async () => {
        try {
            // 1) Validate the form data (avoid unnecessary web request)
            const valid = await trigger();
            if (!valid) {
                throw new Error('Identifiants invalides');
            }
            // 2) Try fetch the token
            const response = await login({
                email: getValues('email'),
                password: getValues('password'),
            });

            // 3) If success, store the token locally and refetch the user
            await setToken(response.token);
            const { user } = await me();

            // 4) Check if the user is an admin
            if (!hasOneRight(user.adminRights, ['READER', 'STOCK_READER', 'ACCOUNTING_READER'])) {
                await setToken(null); // Reset token to null to avoid automatic login on refresh
                throw new Error(
                    "Ce compte n'a pas accès à l'interface d'administration. Contactez un administrateur."
                );
            }
            // 5) Auth succeeded : globally refetch user, reset the form & navigate to the default page
            refetchUser();
            Sentry.setUser({ email: user.email }); // Set the user email in Sentry context
            if (hasRights(user.adminRights, ['READER'])) {
                navigate(DEFAULT_LOGGED_IN_ROUTE);
            } else if (hasRights(user.adminRights, ['STOCK_READER'])) {
                navigate(ROUTES.STORES);
            } else if (hasRights(user.adminRights, ['ACCOUNTING_READER'])) {
                navigate(ROUTES.ACCOUNTING);
            } else {
                navigate(DEFAULT_LOGGED_IN_ROUTE);
            }
        } catch (e) {
            setLoginError(getErrorMessageWithSentry(e));
        }
    };

    const handleForgotPassword = async () => {
        clearErrors();
        const validEmail = await trigger('email');
        const { email } = getValues();

        if (validEmail) {
            // Ask to send a password reset email
            mutate({ email });
        } else {
            showToast({
                message: `L'email "${email}" n'est pas valide. Entrez votre email`,
                severity: 'error',
            });
        }
    };

    return (
        <Center style={{ height: '100vh' }}>
            <Toast />
            <Paper
                elevation={8}
                sx={{
                    // Size
                    width: '343px',
                    height: '420px',

                    // Content posiitonning
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                    // Padding & margins
                    paddingTop: '24px',
                    paddingRight: '16px',
                    paddingLeft: '16px',
                }}
            >
                <img src={loginLogo} alt="login logo" height="60px" width="95px" />
                <Typography style={fonts.loginTitle} mt="24px" mb="24px">
                    Back Office
                </Typography>
                <SimpleForm onSubmit={formSubmit}>
                    <TextFieldRhf
                        fullWidth
                        variant="outlined"
                        label="Email"
                        margin="normal"
                        color="info"
                        error={loginError ? true : undefined} // Override the internal error display only if an error is present
                        type="email"
                        control={control}
                        trigger={trigger}
                        name="email"
                        sx={{ margin: 0, marginBottom: '16px' }}
                        InputProps={{
                            sx: {
                                height: '56px',
                            },
                        }}
                    />
                    <TextFieldRhf
                        fullWidth
                        variant="outlined"
                        label="Mot de passe"
                        margin="normal"
                        color="info"
                        error={loginError ? true : undefined}
                        helperText={loginError ?? ' '} // If no error, still display something in order to not move the spacing when a true error pops up
                        type="password"
                        control={control}
                        trigger={trigger}
                        name="password"
                        sx={{ margin: 0 }}
                        InputProps={{
                            sx: {
                                height: '56px',
                            },
                        }}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        color="secondary"
                        sx={{ height: '42px' }}
                    >
                        Se connecter
                    </Button>
                </SimpleForm>
                <Button
                    color="secondary"
                    sx={{ textDecoration: 'underline', marginTop: '8px' }}
                    onClick={handleForgotPassword}
                    disabled={isLoading}
                >
                    Mot de passe oublié ?
                </Button>
            </Paper>
        </Center>
    );
};

export default LoginPage;
