import { StoreType } from '@gozoki/api-types';

export const getStoreTypeLabel = (storeType: StoreType) => {
    switch (storeType) {
        case 'BOUTIQUE':
            return 'Boutique';
        case 'SHIPPING':
            return 'Transport';
        case 'WAREHOUSE':
            return 'Entrepot';
        case 'POUBELLE':
            return 'Poubelle';
        case 'INTERNAL_STOCK':
            return 'Stock interne';
        default:
            return 'Store Type inconu !';
    }
};
